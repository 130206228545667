import baseUrl from "@/constants/API";

export const SaleDetailAPI = {
  deleteSaleDetail(data) {
    return axios.delete(baseUrl() + `/saledetails/delete/${data}`);
  },
  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/saledetails/checkdelete`, data);
  },
};
