import baseUrl from "@/constants/API";

export const RestaurantKitchenAPI = {
  fetchRestaurantKitchenOrders(data) {
    return axios.get(baseUrl() + `/restaurant/kitchenorders/`, {
      params: data,
    });
  },

  setRestaurantOrderStatus(data) {
    return axios.post(baseUrl() + `/restaurant/kitchenorders/setstatus`, data);
  },
};
