<template>
  <v-list-group prepend-icon="mdi-translate">
    <template v-slot:activator>
      <v-list-item-title class="">
        {{ $t("language") }}
      </v-list-item-title>
    </template>

    <v-list-item
      active-class="active"
      tag="div"
      v-for="lang in langMenu"
      :key="lang.id"
      @click="setLanguage(lang.name)"
      link
    >
      <v-list-item-title>
        <h3 class="">{{ lang.name }}</h3>
      </v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  props: ["langMenu"],
  methods: {
    setLanguage(e) {
      this.$emit("setLanguage", e);
    },
  },
};
</script>

<style></style>
