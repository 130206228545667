import baseUrl from "@/constants/API";

const fetchCleanningInvoices = (data) => {
  // let page = data?.page ? data.page : data;
  // let invoice_id = data?.invoice_id ? data.invoice_id : null;
  // return axios.get(baseUrl() + `/cleanninginvoice/cleanninginvoices/${invoice_id}?page=${page}`);

  return axios.post(baseUrl() + `/cleanninginvoice/get?page=${data.pageNumber}`, data.data);
};

const createCleanningInvoice = (data) => {
  return axios.post(baseUrl() + `/cleanninginvoice/create`, data);
};

const updateCleanningInvoice = (data) => {
  return axios.post(baseUrl() + `/cleanninginvoice/update/${data.id}`, data.data);
};

const deleteCleanningInvoice = (data) => {
  return axios.delete(baseUrl() + `/cleanninginvoice/delete/${data}`);
};

// const cleanningInvoiceReport = (data) => {
//   return axios.post(baseUrl() + `/cleanninginvoice/multisearch?page=${data.pageNumber}`, data.data);
// };

const startCreatingInvoices = (data) => {
  return axios.post(baseUrl() + `/cleanninginvoice/createcleanningclientsinvoices`, data);
};

const collectInvoice = (id) => {
  return axios.post(baseUrl() + `/cleanninginvoice/collectinvoice/${id}`);
};

const getInvoicesCollection = (data) => {
  return axios.post(
    baseUrl() + `/cleanninginvoice/invoicescollection?page=${data.pageNumber}`,
    data.data
  );
};

const CollectInvoices = (data) => {
  return axios.post(baseUrl() + `/cleanninginvoice/collectinvoices`, data);
};

export {
  fetchCleanningInvoices,
  createCleanningInvoice,
  updateCleanningInvoice,
  deleteCleanningInvoice,
  startCreatingInvoices,
  collectInvoice,
  // cleanningInvoiceReport,
  getInvoicesCollection,
  CollectInvoices,
};
