import { OrderDetailAPI } from "@/helpers/Apis/Orders/OrderDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isOrderDetailsLoaded: true,
};

const getters = {
  isOrderDetailsLoaded(state) {
    return state.isOrderDetailsLoaded;
  },
  getOrderDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsOrderDetailsLoaded: (state, payload) => {
    state.isOrderDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  deletePurchaseOrderDetail: ({ commit }, id) => {
    commit("setIsOrderDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      OrderDetailAPI.deletePurchaseOrderDetail(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsOrderDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsOrderDetailsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
