import baseUrl from "@/constants/API";

export const StoreAPI = {
  fetchStore(data) {
    return axios.get(baseUrl() + `/stores/${data.id}`);
  },
  fetchStores() {
    return axios.get(baseUrl() + "/stores/get");
  },

  createStore(data) {
    return axios.post(baseUrl() + `/stores/create`, data);
  },
  updateStore(data) {
    return axios.post(baseUrl() + `/stores/update/${data.id}`, data.data);
  },
  deleteStore(data) {
    return axios.delete(baseUrl() + `/stores/delete/${data}`);
  },

  getInvoiceStores(employeeId) {
    return axios.get(baseUrl() + `/stores/invoicestores/${employeeId}`);
  },

  fetchUserStores() {
    return axios.post(baseUrl() + "/stores/user");
  },

  fetchBasicStores() {
    return axios.get(baseUrl() + "/stores/basicstores");
  },
};
