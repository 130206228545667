import baseUrl from "@/constants/API";

const fetchDeviceTypes = () => {
  return axios.get(baseUrl() + `/devicetypes/get`);
};

const reloadDeviceTypes = () => {
  return axios.get(baseUrl() + `/devicetypes/reload`);
};

const createDeviceType = (data) => {
  return axios.post(baseUrl() + `/devicetypes/create`, data);
};

const updateDeviceType = (data) => {
  return axios.post(baseUrl() + `/devicetypes/update/${data.id}`, data.data);
};

const deleteDeviceType = (id) => {
  return axios.delete(baseUrl() + `/devicetypes/delete/${id}`);
};

export {
  fetchDeviceTypes,
  reloadDeviceTypes,
  createDeviceType,
  updateDeviceType,
  deleteDeviceType,
};
