import Vuetify from "@/plugins/vuetify";

const state = {
  // darkMode: localStorage.getItem("darkMode") || true,
};

const getters = {
  // getDarkMode(state) {
  //   return state.darkMode;
  // },
};

const mutations = {
  setDarkMode(state, isDark) {
    Vuetify.framework.theme.dark = eval(isDark);
    localStorage.setItem("darkMode", eval(isDark));
    // state.darkMode = eval(isDark);
  },
};

const actions = {
  async loadDefaults({ commit }) {
    let darkMode = await localStorage.getItem("darkMode");
    if (darkMode == null) {
      commit("setDarkMode", false);
      return;
    } else {
      commit("setDarkMode", eval(darkMode));
      return;
    }
  },
};

// Export the module
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
