<template>
  <div
    v-if="(item && item.item && item.item.piece) || item.piece"
    class="d-flex justify-center"
  >
    <span class="d-flex flex-column mr-2">
      <!-- <div> {{$t('unit')}} &nbsp;&nbsp;&nbsp;</div> -->
      <div v-if="item.item">{{ item.item.unit }}</div>
      <div>{{ item.unit }}</div>
    </span>
    <span class="d-flex flex-column ml-2">
      <!-- <div> &nbsp;&nbsp;&nbsp; {{$t('piece')}} </div> -->
      <div v-if="item.item">{{ item.item.piece }}</div>
      <div>{{ item.piece }}</div>
    </span>
  </div>

  <div v-else>
    <div>
      <!-- <div> {{$t('unit')}} &nbsp;&nbsp;&nbsp;</div> -->
      <div v-if="item.item">{{ $t("unit") }}</div>
      {{ item.unit }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
