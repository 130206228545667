import baseUrl from "@/constants/API";

export const PayableAPI = {
  fetchPayables(data) {
    return axios.post(baseUrl() + `/payables/search?page=${data.pageNumber}`, data.data);
  },

  CreateNote(data) {
    return axios.post(baseUrl() + "/payables/create", data);
  },
  fetchPayable(id) {
    return axios.get(baseUrl() + `/payables/single/${id}`);
  },

  deleteNote(id) {
    return axios.delete(baseUrl() + `/payables/delete/${id}`);
  },

  bankCollect(data) {
    return axios.post(baseUrl() + "/payables/bankcollect", data);
  },
  drawerCollect(data) {
    return axios.post(baseUrl() + "/payables/drawercollect", data);
  },

  noteReturned(id) {
    return axios.put(baseUrl() + `/payables/returned/${id}`);
  },

  collectedToAnother(data) {
    return axios.post(baseUrl() + "/payables/collecttoanother", data);
  },

  getInvoiceNotes(data) {
    return axios.get(baseUrl() + `/payables/invoicechecks/${data.id}/${data.invoicetype_id}`);
  },

  getNoteEntries(id) {
    return axios.get(baseUrl() + `/payables/noteentries/${id}`);
  },

  deleteEntry(id) {
    return axios.delete(baseUrl() + `/payables/deleteentry/${id}`);
  },

  createInvoiceNotes(data) {
    return axios.post(baseUrl() + `/payables/createinvoicenotes`, data);
  },
};
