import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "cleanning" */ `@containers/${folderName}/${view}.vue`);
}

const CleanningRoutes = [
  {
    path: "/cleanningclients",
    name: "CleanningClients",
    component: lazyLoad("CleanningInvoice", "CleanningClients"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/cleanninginvoice",
    name: "CleanningInvoice",
    component: lazyLoad("CleanningInvoice", "CleanningInvoice"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/invoicescollection",
    name: "InvoicesCollection",
    component: lazyLoad("CleanningInvoice", "Reports/InvoicesCollection"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/cleanninginvoice/:id(\\d+)",
    name: "SingleCleanningInvoice",
    component: lazyLoad("CleanningInvoice", "CleanningInvoice"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/cleanningclientsreport",
    name: "CleanningClientsReport",
    component: lazyLoad("CleanningInvoice", "Reports/CleanningClientsReport"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/cleanningclientsreceivable",
    name: "CleanningClientsReceivable",
    component: lazyLoad("CleanningInvoice", "Reports/ClientsReceivable"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/salesagentsreport",
    name: "CleanningSalesAgentsReport",
    component: lazyLoad("CleanningInvoice", "Reports/CleanningSales"),
    meta: {
      middleware: [auth],
    },
  },
];

export default CleanningRoutes;
