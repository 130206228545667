import baseUrl from "@/constants/API";

export const BeginningStoreAPI = {
  fetchBeginningStoreDetails(data) {
    return axios.get(
      baseUrl() + `/openstore/storedetails/${data?.data?.store_id}?page=${data.pageNumber}`
    );
  },
  fetchBeginningDetails() {
    return axios.get(baseUrl() + "/openstore/all");
  },
  createBeginningDetail(data) {
    return axios.post(baseUrl() + `/openstore/create`, data);
  },
  updateBeginningDetail(data) {
    return axios.post(baseUrl() + `/openstore/update/${data.id}`, data.data);
  },
  deleteBeginningDetail(data) {
    return axios.delete(baseUrl() + `/openstore/delete/${data}`);
  },

  // Excel Excel Excel Excel Excel Excel
  exportTable(data) {
    return axios.post(baseUrl() + `/openstore/export`, data, {
      responseType: "blob",
    });
  },
  downloadExportFile(data) {
    return axios.post(baseUrl() + `/openstore/export/download`, data, {
      responseType: "blob",
    });
  },
  downloadCreateTemplate() {
    return axios.post(baseUrl() + `/openstore/import/createtemplate`, null, {
      responseType: "blob",
    });
  },
  downloadUpdateTemplate() {
    return axios.post(baseUrl() + `/openstore/import/updatetemplate`, null, {
      responseType: "blob",
    });
  },
  importCreate(data) {
    return axios.post(baseUrl() + `/openstore/import/create`, data);
  },
  importUpdate(data) {
    return axios.post(baseUrl() + `/openstore/import/update`, data);
  },
};
