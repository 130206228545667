import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "restaurant" */ `@containers/${folderName}/${view}.vue`);
}

const RestaurantRoutes = [
  {
    path: "/restaurant/tables",
    name: "RestaurantTables",
    component: lazyLoad("Restaurant", "Tables"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/categories",
    name: "RestaurantCategories",
    component: lazyLoad("Restaurant", "Categories"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/meals",
    name: "RestaurantMeals",
    component: lazyLoad("Restaurant", "Meals"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/orders/search",
    name: "RestaurantOrderSearch",
    component: lazyLoad("Restaurant/Orders", "Orders"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/orders/",
    name: "RestaurantOrder",
    component: lazyLoad("Restaurant/Orders", "Order"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/orders/create",
    name: "CreateRestaurantOrder",
    component: lazyLoad("Restaurant/Orders", "Order"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/orders/:id(\\d+)",
    name: "FetchRestaurantOrder",
    component: lazyLoad("Restaurant/Orders", "Order"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/orders/deleted",
    name: "RestaurantDeletedOrders",
    component: lazyLoad("Restaurant/Orders", "Reports/OrdersDeleted"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/restaurant/orders/item/search",
  //   name: "OrderItemSearch",
  //   component: lazyLoad("Invoice", "Purchases/Reports/PurchaseItemSearch"),
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/restaurant/kitchenscreen",
    name: "RestaurantKitchenScreen",
    component: lazyLoad("Restaurant", "KitchenScreen"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/restaurant/orderprofits",
    name: "RestaurantOrderProfits",
    component: lazyLoad("Restaurant/Orders/Reports/Profits", "OrderProfits"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/mealprofits",
    name: "RestaurantMealProfits",
    component: lazyLoad("Restaurant/Orders/Reports/Profits", "MealProfits"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/addonprofits",
    name: "RestaurantAddonProfits",
    component: lazyLoad("Restaurant/Orders/Reports/Profits", "AddonProfits"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/restaurant/recipetransactions",
    name: "RestaurantRecipeTransactions",
    component: lazyLoad("Restaurant/Orders/Reports/Transactions", "RecipeTransactions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/mealtransactions",
    name: "RestaurantMealTransactions",
    component: lazyLoad("Restaurant/Orders/Reports/Transactions", "MealTransactions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/addontransactions",
    name: "RestaurantAddonTransactions",
    component: lazyLoad("Restaurant/Orders/Reports/Transactions", "AddonTransactions"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/restaurant/reservations",
    name: "RestaurantReservations",
    component: lazyLoad("Restaurant/Reservations", "Reservations"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/reservations/:id(\\d+)",
    name: "RestaurantReservation",
    component: lazyLoad("Restaurant/Reservations", "Reservation"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/restaurant/reservations/create",
    name: "CreateRestaurantReservation",
    component: lazyLoad("Restaurant/Reservations", "Reservation"),
    meta: {
      middleware: [auth],
    },
  },
];

export default RestaurantRoutes;
