import baseUrl from "@/constants/API";

export const MaintenanceAPI = {
  fetchMaintenance(id) {
    return axios.get(baseUrl() + `/maintenance/${id}`);
  },
  fetchMaintenanceReport(data) {
    return axios.post(baseUrl() + `/maintenance/search?page=${data.pageNumber}`, data.data);
  },

  fetchMaintenances(data) {
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/maintenance/maintenances/${invoice_id}?page=${page}`);
  },

  createMaintenance(data) {
    return axios.post(baseUrl() + `/maintenance/create`, data);
  },

  updateMaintenance(data) {
    return axios.post(baseUrl() + `/maintenance/update/${data.id}`, data.data);
  },

  deleteMaintenance(data) {
    return axios.delete(baseUrl() + `/maintenance/delete/${data}`);
  },

  changeReceiveStatus(id) {
    return axios.put(baseUrl() + `/maintenance/receivestatus/${id}`);
  },

  changeMaintenanceStatus(data) {
    return axios.put(baseUrl() + `/maintenance/maintenstatus/${data.maintenance_id}`, data);
  },
  payMaintenance(data) {
    return axios.post(baseUrl() + `/maintenance/pay/${data.id}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/maintenance/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/maintenance/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/maintenance/deleted/clear`, data);
  },
};
