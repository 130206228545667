import baseUrl from "@/constants/API";

export const FreeProductionAPI = {
  fetchFreeProduction(id) {
    return axios.get(baseUrl() + `/production/free/${id}`);
  },
  previousFreeProduction(id) {
    return axios.get(baseUrl() + `/production/free/${id}/previous`);
  },
  nextFreeProduction(id) {
    return axios.get(baseUrl() + `/production/free/${id}/next`);
  },

  getFreeProductions(data) {
    // return axios.get(baseUrl() + `/production/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/production/free/${invoice_id}?page=${page}`);
  },

  createFreeProduction(data) {
    return axios.post(baseUrl() + `/production/free/create`, data);
  },
  updateFreeProduction(data) {
    return axios.post(baseUrl() + `/production/free/update/${data.id}`, data.data);
  },

  deleteProduction(id) {
    return axios.delete(baseUrl() + `/production/delete/${id}`);
  },
};
