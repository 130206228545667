import baseUrl from "@/constants/API";

export const ReservationDetailAPI = {
  deleteReservationDetail(data) {
    return axios.delete(baseUrl() + `/reservationdetails/delete/${data}`);
  },

  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/reservationdetails/checkdelete`, data);
  },
};
