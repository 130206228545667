import baseUrl from "@/constants/API";

export const BeginningHeadAPI = {
  fetchBeginningHead() {
    return axios.get(baseUrl() + "/openinghead/get");
  },

  updateBeginningHead(data) {
    return axios.post(baseUrl() + "/openinghead/update", data);
  },
};
