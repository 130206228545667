import baseUrl from "@/constants/API";

export const StoreLogAPI = {
  fetchStoreLog(data) {
    return axios.get(baseUrl() + `/storelog/${data.item_id}/${data.store_id}`);
  },

  fetchStoreLogs() {
    return axios.get(baseUrl() + "/storelog/all");
  },

  outQtyChecker(data) {
    return axios.post(baseUrl() + `/storelog/outqtycheck`, data);
  },
  inQtyChecker(data) {
    return axios.post(baseUrl() + `/storelog/inqtycheck`, data);
  },

  transferQtyCheck(data) {
    return axios.post(baseUrl() + `/storelog/transferqtycheck`, data);
  },

  reviewStoreLogs() {
    return axios.post(baseUrl() + "/storelog/reviewstorelogs");
  },
  ReviewFifo() {
    return axios.post(baseUrl() + "/storelog/reviewfifo");
  },
};
