import baseUrl from "@/constants/API";

export const PurchaseReturnReportAPI = {
  fetchPurchaseReturnSearch(data) {
    return axios.post(baseUrl() + `/purchasereturn/search?page=${data.pageNumber}`, data.data);
  },

  fetchPurchaseReturnDetailSearch(data) {
    return axios.post(
      baseUrl() + `/purchasereturndetails/search?page=${data.pageNumber}`,
      data.data
    );
  },
};
