<template>
  <v-select
    v-on="$listeners"
    v-bind="{ ...$attrs, ...$props }"
    @input="handleInput"
    :label="$t('selectcolumnstohide')"
    multiple
    :menu-props="menuProps"
    item-text="text"
    outlined
    hide-details
    return-object
    clearable
    dense
    filled
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index < 2">
        <span>{{ item.text }}</span>
      </v-chip>
      <span v-if="index === 2" class="caption">
        (+{{ value.length - 2 }} {{ $t("others") }})
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["value", "menuProps"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
      localStorage.setItem(this.$route.name, JSON.stringify(e));
    },
  },
};
</script>

<style></style>
