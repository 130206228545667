import baseUrl from "@/constants/API";
// Route::post('/multisearch', "Cleanning\ProductionReportController@multiSearch");
const FreeProductionSearch = (data) => {
  return axios.post(baseUrl() + `/production/free/search?page=${data.pageNumber}`, data.data);
};
const ProportionProductionSearch = (data) => {
  return axios.post(baseUrl() + `/production/proportion/search?page=${data.pageNumber}`, data.data);
};

// const ProductionDetailSearch = (data) => {
//   return axios.post(baseUrl() + `/productiondetails/search?page=${data.pageNumber}`, data.data);
// };

export {
  FreeProductionSearch,
  ProportionProductionSearch,
  // ProductionDetailSearch,
};
