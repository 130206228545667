import * as APIs from "@/helpers/Apis/Productions/ProductionProportions/ProductionProportions";

import i18n from "@/plugins/i18n/i18n";

const state = {
  proportionsList: [],

  errors: [],
  isItemsLoaded: true,

  isSelectedItemLoaded: true,
  selectedItem: {},

  currentPage: null,
};

const getters = {
  isItemsLoaded(state) {
    return state.isItemsLoaded;
  },
  getProportionsList(state) {
    return state.proportionsList;
  },
  getItemErrors(state) {
    return state.errors;
  },

  isSelectedItemLoaded(state) {
    return state.isSelectedItemLoaded;
  },
  getSelectedItem(state) {
    return state.selectedItem;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsItemsLoaded: (state, payload) => {
    state.isItemsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setListItems(state, payload) {
    state.proportionsList = payload;
  },

  setIsSelectedItemLoaded(state, payload) {
    state.isSelectedItemLoaded = payload;
  },
  setSelectedItem(state, payload) {
    state.selectedItem = payload;
  },

  setCurrentPage(state, payload) {
    state.proportionsList.meta.current_page = payload.value;
  },
};

const actions = {
  ProportionsList: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getProportionsList(data)
        .then((res) => {
          commit("setListItems", res.data.data);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          reject(error);
        });
    });
  },

  ProportionList: ({ commit }, id) => {
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getProportionList(id)
        .then((res) => {
          // commit("setListItems", [res.data.data]);
          commit("setSelectedItem", res.data.data);
          commit("setIsSelectedItemLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },

  ProportionListBar: ({ commit }, barcode) => {
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getProportionListBar(barcode)
        .then((res) => {
          commit("setListItems", [res.data.data]);
          commit("setSelectedItem", res.data.data);
          commit("setIsSelectedItemLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
