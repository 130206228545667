import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "order" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  /// ------------- Sale Orders -------------
  {
    path: "/saleorders",
    name: "SaleOrders",
    component: lazyLoad("Orders", "SaleOrders/SaleOrders"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/saleorders/:id(\\d+)",
    name: "SingleSaleOrder",
    component: lazyLoad("Orders", "SaleOrders/SaleOrder"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/saleorders/create",
    name: "CreateSaleOrder",
    component: lazyLoad("Orders", "SaleOrders/SaleOrder"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/saleorders/deleted",
    name: "SaleOrdersDeleted",
    component: lazyLoad("Orders", "SaleOrders/Reports/SaleOrdersDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  /// ------------- Purchase Orders -------------
  {
    path: "/purchaseorders",
    name: "PurchaseOrders",
    component: lazyLoad("Orders", "PurchaseOrders/PurchaseOrders"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchaseorders/:id(\\d+)",
    name: "SinglePurchaseOrder",
    component: lazyLoad("Orders", "PurchaseOrders/PurchaseOrder"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchaseorders/create",
    name: "CreatePurchaseOrder",
    component: lazyLoad("Orders", "PurchaseOrders/PurchaseOrder"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchaseorders/deleted",
    name: "PurchaseOrdersDeleted",
    component: lazyLoad("Orders", "PurchaseOrders/Reports/PurchaseOrdersDeleted"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
