import { RestaurantOrderReportAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  ordersData: [],

  errors: [],
  isReportLoaded: true,
  currentPage: null,
};

const getters = {
  getOrdersData(state) {
    return state.ordersData;
  },
  isReportLoaded(state) {
    return state.isReportLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setOrdersData: (state, payload) => {
    state.ordersData = payload;
  },
  setIsReportLoaded: (state, payload) => {
    state.isReportLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },
};

const actions = {
  fetchRestaurantOrderProfits: ({ commit }, data) => {
    commit("setIsReportLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderReportAPI.fetchRestaurantOrderProfits(data)
        .then((res) => {
          commit("setOrdersData", res.data);
          commit("setErrors", []);
          commit("setIsReportLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
