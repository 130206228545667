// not using it but when u use use it like this {{ $n(price, "currency") }}
// {{ $n(toFixedIfNecessary(amount, 2), "groupin") }} // use the , separator only
const numberFormats = {
  EN: {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      useGrouping: true,
    },
    groupin: {
      useGrouping: true,
    },
  },
  AR: {
    // currency: { style: "currency", currency: "EGP", currencyDisplay: "code" },
    currency: { style: "currency", currency: "EGP", useGrouping: true },
    groupin: {
      useGrouping: true,
    },
  },
};
export default numberFormats;
