import baseUrl from "@/constants/API";

const fetchCleanningClients = (data) => {
  let pageNumber = data.pageNumber ?? 0;
  let dataObject = data?.name?.length > 0 ? data.name : null;
  return axios.get(baseUrl() + `/cleanningclients/get/${dataObject}?page=${pageNumber}`);
};

const createCleanningClient = (data) => {
  return axios.post(baseUrl() + `/cleanningclients/create`, data);
};

const updateCleanningClient = (data) => {
  return axios.post(baseUrl() + `/cleanningclients/update/${data.id}`, data.data);
};

const deleteCleanningClient = (data) => {
  return axios.delete(baseUrl() + `/cleanningclients/delete/${data}`);
};

const getCleanningClientsReceivable = (data) => {
  return axios.post(
    baseUrl() + `/cleanningclients/cleanningclientsreceivable?page=${data.pageNumber}`,
    data.data
  );
  // return axios.post(baseUrl() + "/cleanningclients/cleanningclientsreceivable", data);
};

const getCleanningEmployeesReport = (data) => {
  return axios.post(
    baseUrl() + `/cleanningclients/salesagentsreport?page=${data.pageNumber}`,
    data.data
  );
  // return axios.post(baseUrl() + "/cleanningclients/salesagentsreport", data);
};

const getCleanningClientsReport = (data) => {
  return axios.post(
    baseUrl() + `/cleanningclients/cleanningclientsreport?page=${data.pageNumber}`,
    data.data
  );
  // return axios.post(baseUrl() + "/cleanningclients/cleanningclientsreport", data);
};

export {
  fetchCleanningClients,
  createCleanningClient,
  updateCleanningClient,
  deleteCleanningClient,
  getCleanningClientsReceivable,
  getCleanningEmployeesReport,
  getCleanningClientsReport,
};
