import { ClientAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  isExcelLoaded: true,
  errors: [],
};

const getters = {
  isExcelLoaded(state) {
    return state.isExcelLoaded;
  },

  getExcelErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsExcelLoaded: (state, payload) => {
    state.isExcelLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  exportTable: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);

    return new Promise((resolve, reject) => {
      ClientAPI.exportTable(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);
          const contentType = res.headers["content-type"];

          // Handle JSON response (like error messages)
          if (contentType.includes("application/json")) {
            res.data
              .text()
              .then((text) => {
                const message = JSON.parse(text).message;
                iziToast.success({ message, timeout: 5000 });
                return resolve(res.data); // Resolve with JSON data
              })
              .catch(reject); // Reject in case of JSON parsing error
          } else {
            // Handle file Blob (CSV export)
            const blob = new Blob([res.data], { type: contentType });
            const fileName = res.headers["content-disposition"]
              .split("filename=")[1]
              .replace(/['"]/g, "");
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return resolve(res.data); // Resolve with Blob data
          }
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);

          // Check if error response contains a Blob
          if (error.response && error.response.data instanceof Blob) {
            error.response.data
              .text()
              .then((text) => {
                const message = JSON.parse(text).message;
                iziToast.error({ message, timeout: 5000 });
                return reject(message);
              })
              .catch(reject); // Reject if there's a Blob parsing error
          } else {
            // Handle standard errors
            const message = error.response?.data?.message || i18n.t("somethingwrong");
            iziToast.error({ message, timeout: 5000 });
            return reject(error); // Reject with the error object
          }
        });
    });
  },
  downloadExportFile: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.downloadExportFile(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);

          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const fileName = res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]/g, "");
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          return resolve(res.data);
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);

          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((text) => {
              const message = JSON.parse(text).message;
              iziToast.error({ message, timeout: 5000 });
              return reject(message);
            });
          } else {
            const message = error.response?.data?.message || i18n.t("somethingwrong");
            iziToast.error({ message, timeout: 5000 });
            return reject(message);
          }
        });
    });
  },

  downloadCreateTemplate: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.downloadCreateTemplate(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);

          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const fileName = res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]/g, "");
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          return resolve(res.data);
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);

          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((text) => {
              const message = JSON.parse(text).message;
              iziToast.error({ message, timeout: 5000 });
              return reject(message);
            });
          } else {
            const message = error.response?.data?.message || i18n.t("somethingwrong");
            iziToast.error({ message, timeout: 5000 });
            return reject(message);
          }
        });
    });
  },
  downloadUpdateTemplate: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.downloadUpdateTemplate(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);

          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const fileName = res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]/g, "");
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          return resolve(res.data);
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);

          if (error.response && error.response.data instanceof Blob) {
            error.response.data.text().then((text) => {
              const message = JSON.parse(text).message;
              iziToast.error({ message, timeout: 5000 });
              return reject(message);
            });
          } else {
            const message = error.response?.data?.message || i18n.t("somethingwrong");
            iziToast.error({ message, timeout: 5000 });
            return reject(message);
          }
        });
    });
  },

  importCreate: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.importCreate(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);
          resolve(res.data);
          commit("setErrors", []);
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);
          reject(error);
          let nameErrors = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : error.response.data.message;
          nameErrors = Array.isArray(nameErrors) ? nameErrors : [nameErrors];
          commit("setErrors", nameErrors);
        });
    });
  },
  importUpdate: ({ commit }, data) => {
    commit("setIsExcelLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.importUpdate(data)
        .then((res) => {
          commit("setIsExcelLoaded", true);
          resolve(res.data);
          commit("setErrors", []);
        })
        .catch((error) => {
          commit("setIsExcelLoaded", true);
          reject(error);
          let nameErrors = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : error.response.data.message;
          nameErrors = Array.isArray(nameErrors) ? nameErrors : [nameErrors];
          commit("setErrors", nameErrors);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
