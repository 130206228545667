import { PayableAPI } from "@/helpers/Apis/index.js";
import i18n from "@/plugins/i18n/i18n";

const state = {
  payables: [],
  errors: [],
  isPayableLoaded: true,

  payable: {},
  payableEntries: [],

  SearchObject: {
    dates: {
      label: "date",
      value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    },
  },
};

const getters = {
  getPayables(state) {
    return state.payables;
  },
  isPayableLoaded(state) {
    return state.isPayableLoaded;
  },
  getPayable(state) {
    return state.payable;
  },
  getPayableEntries(state) {
    return state.payableEntries;
  },
  getPayableErrors(state) {
    return state.errors;
  },

  getSearchObject(state) {
    return state.SearchObject;
  },
};

const mutations = {
  setIsPayableLoaded: (state, payload) => {
    state.isPayableLoaded = payload;
  },
  setPayables: (state, payload) => {
    state.payables = payload;
  },
  setPayable: (state, payload) => {
    state.payable = payload;
  },
  setPayableEntries: (state, payload) => {
    state.payableEntries = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    // state.payables.meta.current_page = payload.value;
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },

  setSearchObject(state, payload) {
    state.SearchObject = payload;
  },
};

const actions = {
  fetchPayable: ({ commit }, id) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.fetchPayable(id)
        .then((res) => {
          commit("setPayable", res.data);
          commit("setIsPayableLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error.response);
        });
    });
  },
  fetchPayables: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.fetchPayables(data)
        .then((res) => {
          commit("setPayables", res.data);
          commit("setIsPayableLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error.response);
        });
    });
  },

  createPayable: ({ commit, dispatch }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.CreateNote(data)
        .then((res) => {
          commit("setIsPayableLoaded", true);
          commit("setErrors", "");
          // dispatch("fetchPayables");
          resolve();
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  // updatePayable: ({ commit, dispatch }, data) => {
  //   commit("setIsPayableLoaded", false);
  //   const response = PayableAPI.updatePayable(data)
  //     .then((response) => {
  //       commit("setIsPayableLoaded", true);

  //       if (response.status == 200) {
  //         commit("setErrors", "");
  //         dispatch("fetchPayables");
  //       }
  //     })
  //     .catch((error) => {
  //       commit("setIsPayableLoaded", true);

  //       let nameError = error.response.data.errors
  //         ? Object.values(error.response.data.errors).flat()
  //         : [error.response.data.message];
  //       commit("setErrors", nameError);
  //     });
  // },

  deletePayable: ({ commit, dispatch }, id) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.deleteNote(id)
        .then((response) => {
          if (response.status == 200) {
            commit("setErrors", "");
            // dispatch("fetchPayables");
          }
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  payableInvoiceNotes: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PayableAPI.getInvoiceNotes(data)
        .then((response) => {
          commit("setPayableEntries", response.data.data);
          commit("setIsPayableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  payableEntries: ({ commit }, id) => {
    commit("setIsPayableLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PayableAPI.getNoteEntries(id)
        .then((response) => {
          commit("setPayableEntries", response.data.data);
          commit("setIsPayableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  bankCollect: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.bankCollect(data)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },

  noteReturned: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.noteReturned(data)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },

  drawerCollect: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.drawerCollect(data)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },

  collectedToAnother: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.collectedToAnother(data)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },

  deleteEntry: ({ commit }, id) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.deleteEntry(id)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },

  createPayableInvoiceNotes: ({ commit }, data) => {
    commit("setIsPayableLoaded", false);
    return new Promise((resolve, reject) => {
      PayableAPI.createInvoiceNotes(data)
        .then((response) => {
          commit("setIsPayableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsPayableLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
