import baseUrl from "@/constants/API";

export const SaleReturnReportAPI = {
  fetchSaleReturnSearch(data) {
    return axios.post(baseUrl() + `/salereturn/search?page=${data.pageNumber}`, data.data);
  },

  fetchSaleReturnDetailSearch(data) {
    return axios.post(baseUrl() + `/salereturndetails/search?page=${data.pageNumber}`, data.data);
  },
};
