import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "production" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/productions/free",
    name: "ProductionsFree",
    component: lazyLoad("Productions", "FreeProductions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productions/free/:id(\\d+)",
    name: "SingleFreeProduction",
    component: lazyLoad("Productions", "FreeProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productions/free/create",
    name: "CreateFreeProduction",
    component: lazyLoad("Productions", "FreeProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/production-proportions",
    name: "ProductionProportions",
    component: lazyLoad("Productions/ProductionProportions", "ProductionProportions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productions/proportion",
    name: "ProportionProductions",
    component: lazyLoad("Productions", "ProportionProductions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productions/proportion/:id(\\d+)",
    name: "SingleProportionProduction",
    component: lazyLoad("Productions", "ProportionProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productions/proportion/create",
    name: "CreateProportionProduction",
    component: lazyLoad("Productions", "ProportionProduction"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
