import baseUrl from "@/constants/API";

export const ItemPackageAPI = {
  fetchPackages() {
    return axios.get(baseUrl() + `/items/packages/get`);
  },

  fetchPackageItems(id) {
    return axios.get(baseUrl() + `/items/packages/${id}/items`);
  },

  createPackage(data) {
    return axios.post(baseUrl() + `/items/packages/create`, data);
  },

  updatePackage(data) {
    return axios.post(baseUrl() + `/items/packages/update/${data.id}`, data.data);
  },

  deletePackage(id) {
    return axios.delete(baseUrl() + `/items/packages/delete/${id}`);
  },
};
