import baseUrl from "@/constants/API";

const fetchInventoryCount = (data) => {
  return axios.post(baseUrl() + `/inventory/inventorycount?page=${data.pageNumber}`, data.data);
  // return axios.post(baseUrl() + "/inventory/inventorycount", data);
};
const fetchGeneralInventoryCount = (data) => {
  return axios.post(
    baseUrl() + `/inventory/generalinventorycount?page=${data.pageNumber}`,
    data.data
  );
};

const fetchEndInventoryCount = (data) => {
  return axios.post(baseUrl() + "/inventory/endinventorycount", data);
};

export { fetchInventoryCount, fetchGeneralInventoryCount, fetchEndInventoryCount };
