let BASE_URL = process.env.VUE_APP_API;

function baseUrl(db) {
  let selectedDB = db || localStorage.getItem("db") || "null";

  // get the url from the login request
  if (process.env.NODE_ENV == "production") {
    BASE_URL = "https://" + selectedDB + "." + process.env.VUE_APP_PRODUCTION;
  } else {
    BASE_URL = "http://" + selectedDB + "." + process.env.VUE_APP_API;
  }
  return BASE_URL;
}
export default baseUrl;
