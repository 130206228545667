import * as APIs from "@/helpers/Apis/Maintenance/MaintenanceQuestions";
const state = {
  isQuestionsLoaded: true,
};

const getters = {
  isQuestionsLoaded(state) {
    return state.isQuestionsLoaded;
  },
  getMaintenanceErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsMaintenanceQuestionLoaded: (state, payload) => {
    state.isQuestionsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  createMaintenanceQuestion: ({ commit, dispatch }, data) => {
    commit("setIsMaintenanceQuestionLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createMaintenanceQuestion(data)
        .then((res) => {
          commit("setIsMaintenanceQuestionLoaded", true);
          commit("setErrors", "");
          commit("Maintenances/addMaintenanceQuestion", res.data.data, {
            root: true,
          });

          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceQuestionLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  // updateMaintenanceQuestion: ({ commit, dispatch }, data) => {
  //   commit("setIsMaintenanceQuestionLoaded", false);
  //   return new Promise((resolve, reject) => {
  //     APIs.updateMaintenanceQuestion(data)
  //       .then((response) => {
  //         commit("setIsMaintenanceQuestionLoaded", true);

  //         if (response.status == 200) {
  //           commit("setErrors", "");
  //           // dispatch("fetchPayables");
  //         }
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         commit("setIsMaintenanceQuestionLoaded", true);

  //         let nameError = error.response.data.errors
  //           ? Object.values(error.response.data.errors).flat()
  //           : [error.response.data.message];
  //         commit("setErrors", nameError);
  //         reject(error);
  //       });
  //   });
  // },

  deleteMaintenanceQuestion: ({ commit }, id) => {
    commit("setIsMaintenanceQuestionLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteMaintenanceQuestion(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsMaintenanceQuestionLoaded", true);
          commit("Maintenances/removeMaintenanceQuestion", id, {
            root: true,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceQuestionLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteMaintenanceQuestionAnswer: ({ commit }, answer) => {
    commit("setIsMaintenanceQuestionLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteMaintenanceQuestionAnswer(answer.id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsMaintenanceQuestionLoaded", true);
          commit("Maintenances/removeMaintenanceQuestionAnswer", answer, {
            root: true,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceQuestionLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
