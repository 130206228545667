import baseUrl from "@/constants/API";

export const CategoryAPI = {
  fetchCategory(data) {
    return axios.get(baseUrl() + `/categories/single/${data.id}`);
  },
  fetchCategories() {
    return axios.get(baseUrl() + `/categories/get`);
  },
  fetchCategoriesList() {
    return axios.get(baseUrl() + `/categories/catlist`);
  },

  createCategory(data) {
    return axios.post(baseUrl() + `/categories/create`, data);
  },
  updateCategory(data) {
    return axios.post(baseUrl() + `/categories/update/${data.id}`, data.data);
  },
  deleteCategory(id) {
    return axios.delete(baseUrl() + `/categories/delete/${id}`);
  },

  // Excel Excel Excel Excel Excel Excel
  exportTable() {
    return axios.post(baseUrl() + `/categories/export`, null, {
      responseType: "blob",
    });
  },
  downloadExportFile() {
    return axios.post(baseUrl() + `/categories/export/download`, null, {
      responseType: "blob",
    });
  },
  downloadCreateTemplate() {
    return axios.post(baseUrl() + `/categories/import/createtemplate`, null, {
      responseType: "blob",
    });
  },
  downloadUpdateTemplate() {
    return axios.post(baseUrl() + `/categories/import/updatetemplate`, null, {
      responseType: "blob",
    });
  },
  importCreate(data) {
    return axios.post(baseUrl() + `/categories/import/create`, data);
  },
  importUpdate(data) {
    return axios.post(baseUrl() + `/categories/import/update`, data);
  },
};
