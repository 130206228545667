import baseUrl from "@/constants/API";

export const MainAccountAPI = {
  fetchBasicMainAcc() {
    return axios.get(baseUrl() + `/mainaccounts/basicaccs`);
  },
  fetchMainAccs(pageNumber) {
    return axios.get(baseUrl() + `/mainaccounts/get?page=${pageNumber}`);
  },

  fetchUserMainAccs(data) {
    return axios.get(baseUrl() + `/mainaccounts/usermainaccs`, {
      params: data,
    });
  },

  // ${data?.isReport ?? false}
  createMainAcc(data) {
    return axios.post(baseUrl() + `/mainaccounts/create`, data);
  },
  updateMainAcc(data) {
    return axios.post(baseUrl() + `/mainaccounts/update/${data.id}`, data.data);
  },
  deleteMainAcc(data) {
    return axios.delete(baseUrl() + `/mainaccounts/delete/${data}`);
  },

  fetchDrawerActionMainAccs() {
    return axios.get(baseUrl() + `/mainaccounts/draweraccs`);
  },
  fetchNotesMainAccs() {
    return axios.get(baseUrl() + `/mainaccounts/notesaccs`);
  },
};
