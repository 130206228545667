<template>
  <!-- class="mb-4" -->
  <!-- <v-text-field
    v-bind="{ ...$attrs, ...$props }"
    filled
    v-on="$listeners"
    dense
    outlined
    @input="handleInput"
    hide-details="auto"
  ></v-text-field> -->

  <v-text-field
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    hide-details="auto"
    dense
  ></v-text-field>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot::before {
  display: none;
}
::v-deep .v-input__slot::after {
  display: none;
}
</style>
