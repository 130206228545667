import baseUrl from "@/constants/API";

const fetchFinalBalance = (data) => {
  return axios.post(baseUrl() + "/balancereport/finalbalancesheet", data);
};

const fetchLedger = (data) => {
  return axios.post(baseUrl() + "/balancereport/ledger", data);
};

export { fetchFinalBalance, fetchLedger };
