<template>
  <v-container :class="contentClass" fluid fill-height>
    <v-layout justify-center>
      <!-- <v-layout align-center justify-center> -->
      <v-flex xs12 sm12 :md11="hideMD11 ? false : true">
        <slot name="content"> </slot>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["contentClass", "hideMD11"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
