import { CategoryAPI, ItemAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  listItems: [],

  errors: [],
  isItemsListLoaded: true,

  isSelectedItemLoaded: true,
  selectedItem: {},

  currentPage: null,

  categories: [],
  isCategoriesLoaded: true,
};

const getters = {
  isItemsListLoaded(state) {
    return state.isItemsListLoaded;
  },
  getListItems(state) {
    return state.listItems;
  },
  getItemErrors(state) {
    return state.errors;
  },

  isSelectedItemLoaded(state) {
    return state.isSelectedItemLoaded;
  },
  getSelectedItem(state) {
    return state.selectedItem;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },

  getCategories(state) {
    return state.categories;
  },
  isCategoriesLoaded(state) {
    return state.isCategoriesLoaded;
  },
};

const mutations = {
  setIsItemsListLoaded: (state, payload) => {
    state.isItemsListLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setListItems(state, payload) {
    state.listItems = payload;
  },

  setIsSelectedItemLoaded(state, payload) {
    state.isSelectedItemLoaded = payload;
  },
  setSelectedItem(state, payload) {
    state.selectedItem = payload;
  },

  setCurrentPage(state, payload) {
    state.listItems.meta.current_page = payload.value;
  },

  setCategories(state, payload) {
    state.categories = payload;
  },
  setIsCategoriesLoaded(state, payload) {
    state.isCategoriesLoaded = payload;
  },
};

const actions = {
  searchBarcodeName: ({ commit }, barcodeName) => {
    commit("setIsItemsListLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.searchBarcodeName(barcodeName)
        .then((res) => {
          commit("setIsItemsListLoaded", true);
          commit("setListItems", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsItemsListLoaded", true);
          reject(error);
        });
    });
  },

  fetchItemByBarcode: ({ commit }, barcode) => {
    commit("setIsItemsListLoaded", false);
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchItemByBarcode(barcode)
        .then((res) => {
          commit("setIsItemsListLoaded", true);
          commit("setListItems", [res.data.data]);

          commit("setSelectedItem", res.data.data);
          commit("setIsSelectedItemLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsItemsListLoaded", true);
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },

  fetchListItems: ({ commit }, data) => {
    commit("setIsItemsListLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchListItems(data)
        .then((res) => {
          commit("setListItems", res.data);
          commit("setIsItemsListLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsListLoaded", true);
          reject(error);
        });
    });
  },

  fetchListItem: ({ commit }, id) => {
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchListItem(id)
        .then((res) => {
          commit("setSelectedItem", res.data.data);
          commit("setIsSelectedItemLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },

  ItemUpdatePrices: ({ commit }, id) => {
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.getItemUpdatePrices(id)
        .then((res) => {
          commit("setIsSelectedItemLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },

  updateItemPrices: ({ commit }, data) => {
    commit("setIsSelectedItemLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.updateItemPrices(data)
        .then((res) => {
          commit("setIsSelectedItemLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSelectedItemLoaded", true);
          reject(error);
        });
    });
  },

  fetchCategoriesList: ({ commit }) => {
    commit("setIsCategoriesLoaded", false);
    return new Promise((resolve, reject) => {
      CategoryAPI.fetchCategoriesList()
        .then((res) => {
          commit("setCategories", res.data);
          commit("setIsCategoriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCategoriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
