import baseUrl from "@/constants/API";

const deleteProduction = (id) => {
  return axios.delete(baseUrl() + `/production/delete/${id}`);
};

// deleted STUFF
const fetchDeletedInvoices = (data) => {
  return axios.post(baseUrl() + `/production/deleted?page=${data.pageNumber}`, data.data);
};
const fetchDeletedInvoiceDetails = (invoice_id) => {
  return axios.post(baseUrl() + `/production/deleted/${invoice_id}`);
};
const clearDeletedInvoices = (data) => {
  return axios.delete(baseUrl() + `/production/deleted/clear`, data);
};

export {
  deleteProduction,
  // deleted STUFF
  fetchDeletedInvoices,
  fetchDeletedInvoiceDetails,
  clearDeletedInvoices,
};
