import baseUrl from "@/constants/API";

export const DistrictAPI = {
  fetchDistricts() {
    return axios.get(baseUrl() + `/districts/get`);
  },

  getSpecificDistricts(id) {
    return axios.get(baseUrl() + `/districts/${id}`);
  },

  createDistrict(data) {
    return axios.post(baseUrl() + `/districts/create`, data);
  },

  updateDistrict(data) {
    return axios.post(baseUrl() + `/districts/update/${data.id}`, data.data);
  },

  deleteDistrict(id) {
    return axios.delete(baseUrl() + `/districts/delete/${id}`);
  },
};
