<template>
  <v-pagination
    v-model="Page"
    :length="lastPage"
    :total-visible="10"
    :disabled="dataLoaded"
    circle
  ></v-pagination>
</template>

<script>
export default {
  props: ["searchData", "store", "collection", "getListAction", "dataLoaded"],
  computed: {
    Page: {
      get() {
        return this.$store.state[this.store][this.collection]?.meta
          ?.current_page;
      },
      set(value) {
        this.$emit("pageUpdate");
        return this.$store.commit(this.store + "/setCurrentPage", {
          collection: this.collection,
          value: value,
        });
      },
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection]?.meta?.last_page;
      },
    },
  },
  watch: {
    Page(newVal, oldVal) {
      this.paginatePage(newVal);
    },
  },
  methods: {
    paginatePage(pageNumber) {
      if (this.searchData) {
        this.$store.dispatch(this.store + "/" + this.getListAction, {
          data: this.searchData,
          pageNumber: pageNumber,
        });
      } else {
        this.$store.dispatch(this.store + "/" + this.getListAction, pageNumber);
      }
    },
  },
};
</script>

<style></style>
