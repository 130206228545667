import baseUrl from "@/constants/API";

export const OrderDetailAPI = {
  deleteSaleOrderDetail(data) {
    return axios.delete(baseUrl() + `/saleorderdetail/delete/${data}`);
  },

  deletePurchaseOrderDetail(data) {
    return axios.delete(baseUrl() + `/purchaseorderdetail/delete/${data}`);
  },

  // checkDeleteDetail(data) {
  //   return axios.post(baseUrl() + `/reservationdetails/checkdelete`, data);
  // },
};
