import baseUrl from "@/constants/API";

export const TransferAPI = {
  fetchStoreTransfer(id) {
    return axios.get(baseUrl() + `/storetransfer/${id}`);
  },
  previousStoreTransfer(id) {
    return axios.get(baseUrl() + `/storetransfer/${id}/previous`);
  },
  nextStoreTransfer(id) {
    return axios.get(baseUrl() + `/storetransfer/${id}/next`);
  },
  storeTransferSearch(data) {
    return axios.post(baseUrl() + `/storetransfer/search?page=${data.pageNumber}`, data.data);
  },
  createStoreTransfer(data) {
    return axios.post(baseUrl() + `/storetransfer/create`, data);
  },
  updateStoreTransfer(data) {
    return axios.put(baseUrl() + `/storetransfer/update/${data.id}`, data.data);
  },
  deleteStoreTransfer(data) {
    return axios.delete(baseUrl() + `/storetransfer/delete/${data.id}`);
  },

  transferDetails(invoice_id) {
    return axios.post(baseUrl() + `/storetransfer/details/${invoice_id}`);
  },

  storeTransferDeliver(id) {
    return axios.put(baseUrl() + `/storetransfer/deliver/${id}`);
  },
  storeTransferUnDeliver(id) {
    return axios.put(baseUrl() + `/storetransfer/undeliver/${id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/storetransfer/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/storetransfer/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/storetransfer/deleted/clear`, data);
  },
};
