// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { PurchaseReturnAPI } from "@/helpers/Apis/Invoices/PurchasesReturns/PurchaseReturns";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  purchasereturns: {},

  errors: [],
  returnDetailsErrors: [],
  isReturnsLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  isReturnsLoaded(state) {
    return state.isReturnsLoaded;
  },
  getPurchaseReturns(state) {
    return state.purchasereturns;
  },

  getReturnErrors(state) {
    return state.errors;
  },
  getCurrentPageEdit(state) {
    return state.currentPageEdit;
  },

  getInvoiceDetailsErrors(state) {
    return state.returnDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setIsReturnsLoaded: (state, payload) => {
    state.isReturnsLoaded = payload;
  },

  setPurchaseReturns: (state, payload) => {
    state.purchasereturns = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    // state.returns.data[0].invoice_entries_extra = payload;
    state.purchasereturns.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.purchasereturns.invoice_entries_extra = [
      ...state.purchasereturns.invoice_entries_extra,
      ...payload,
    ];
    let expenses = toFixedIfNecessary(
      state.purchasereturns.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.purchasereturns.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.purchasereturns.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.purchasereturns.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.purchasereturns.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchPurchaseReturns: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.fetchPurchaseReturns(id)
        .then((res) => {
          commit("setPurchaseReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.previousPurchaseReturns(id)
        .then((res) => {
          commit("setPurchaseReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/purchasesreturns/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.nextPurchaseReturns(id)
        .then((res) => {
          commit("setPurchaseReturns", res.data.data);
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/purchasesreturns/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReturnsLoaded", true);
          reject(error);
        });
    });
  },

  createPurchaseReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.createPurchaseReturn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchaseReturns", invoice);
          router.push({
            path: `/purchasesreturns/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updatePurchaseReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.updatePurchaseReturn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchaseReturns", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deletePurchaseReturn: ({ commit }, data) => {
    commit("setIsReturnsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.deletePurchaseReturn(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReturnsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.purchasereturns;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data.entries);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", false);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
