import axios from "axios";
import * as APIs from "@/helpers/Apis/User/UserHeaders";
import i18n from "@/plugins/i18n/i18n";

const state = {
  user: null,
  isHeadersLoaded: true,
  errors: [],
};

const getters = {
  // getUser(state) {
  //   return state.user;
  // },
  isHeadersLoaded(state) {
    return state.isHeadersLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  // setUser: (state, payload) => {
  //   localStorage.setItem("user", JSON.stringify(payload));
  //   state.user = payload;
  // },
  setIsHeadersLoaded: (state, payload) => {
    state.isHeadersLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  saveHeaders: ({ commit }, data) => {
    commit("setIsHeadersLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.saveHeaders(data)
        .then((res) => {
          // commit("setUser", res.data.data);

          commit("setIsHeadersLoaded", true);
          commit("setErrors", "");
          let associates = res.data.data;
          commit("User/setAssociates", associates, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsHeadersLoaded", true);
          // commit logout
          // commit("setLogout");
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
