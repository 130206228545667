import baseUrl from "@/constants/API";

export const StoreOutAPI = {
  fetchStoreOut(id) {
    return axios.get(baseUrl() + `/storeout/${id}`);
  },
  previousStoreOut(id) {
    return axios.get(baseUrl() + `/storeout/${id}/previous`);
  },
  nextStoreOut(id) {
    return axios.get(baseUrl() + `/storeout/${id}/next`);
  },
  fetchSearchStoreOuts(data) {
    return axios.post(baseUrl() + `/storeout/search?page=${data.pageNumber}`, data.data);
  },

  createStoreOut(data) {
    return axios.post(baseUrl() + `/storeout/create`, data);
  },
  updateStoreOut(data) {
    return axios.post(baseUrl() + `/storeout/update/${data.id}`, data.data);
  },
  deleteStoreOut(id) {
    return axios.delete(baseUrl() + `/storeout/delete/${id}`);
  },
  searchStoreOut(data) {
    return axios.post(baseUrl() + `/storeout/search/${data}`);
  },

  storeOutDetails(invoice_id) {
    return axios.post(baseUrl() + `/storeout/details/${invoice_id}`);
  },

  convertToSale(data) {
    return axios.post(baseUrl() + `/storeout/converttosale/${data.storeOutId}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/storeout/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/storeout/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/storeout/deleted/clear`, data);
  },
};
