import baseUrl from "@/constants/API";

const fetchMaintenanceClients = (data) => {
  return axios.post(baseUrl() + `/maintenanceclients/get?page=${data.pageNumber}`, data.data);
};

const createMaintenanceClient = (data) => {
  return axios.post(baseUrl() + `/maintenanceclients/create`, data);
};

const updateMaintenanceClient = (data) => {
  return axios.post(baseUrl() + `/maintenanceclients/update/${data.id}`, data.data);
};

const deleteMaintenanceClient = (data) => {
  return axios.delete(baseUrl() + `/maintenanceclients/delete/${data}`);
};

const getClientBalance = (id) => {
  return axios.get(baseUrl() + `/maintenanceclients/balance/${id}`);
};

export {
  fetchMaintenanceClients,
  createMaintenanceClient,
  updateMaintenanceClient,
  deleteMaintenanceClient,
  getClientBalance,
};
