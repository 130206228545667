import baseUrl from "@/constants/API";

export const DrawerAPI = {
  fetchDrawer(data) {
    return axios.get(baseUrl() + `/drawers/${data.id}`);
  },
  fetchDrawers() {
    return axios.get(baseUrl() + "/drawers/get");
  },

  createDrawer(data) {
    return axios.post(baseUrl() + `/drawers/create`, data);
  },
  updateDrawer(data) {
    return axios.post(baseUrl() + `/drawers/update/${data.id}`, data.data);
  },
  deleteDrawer(data) {
    return axios.delete(baseUrl() + `/drawers/delete/${data}`);
  },

  fetchBranchDrawers(data) {
    return axios.get(baseUrl() + `/drawers/branchdrawers/${data}`);
  },

  fetchUserDrawers() {
    return axios.get(baseUrl() + "/drawers/user");
  },

  fetchBasicDrawers() {
    return axios.get(baseUrl() + "/drawers/basicdrawers");
  },
};
