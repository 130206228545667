import baseUrl from "@/constants/API";

export const SaleProfitAPI = {
  fetchInvoiceProfit(id) {
    return axios.post(baseUrl() + "/profits/invoice", id);
  },

  fetchInvoicesProfit(data) {
    return axios.post(baseUrl() + `/profits/invoices?page=${data.pageNumber}`, data.data);
  },

  fetchItemsProfits(data) {
    return axios.post(baseUrl() + `/profits/items?page=${data.pageNumber}`, data.data);
  },
};
