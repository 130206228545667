<template>
  <!-- class="mb-4" -->
  <v-text-field
    v-on="$listeners"
    v-bind="{ ...$attrs, ...$props }"
    :filled="filled || filled == false ? filled : true"
    dense
    outlined
    @input="handleInput"
    hide-details="auto"
    @focus="$event.target.select()"
  >
    <!-- this checks if there is a prepend icon then puts it if exists -->
    <template v-if="$slots.prepend" v-slot:prepend>
      <slot name="prepend"></slot>
    </template>

    <template #append>
      <slot name="append"></slot>
    </template>

    <template #append-outer>
      <slot name="append-outer"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: ["value", "filled"],
  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss">
.theme--light .v-text-field--filled .v-input__control {
  background-color: white !important;
}
</style>
