import { connection } from "@/helpers/IndexDB/jsstore_con";

export class CategoryService {
  constructor() {
    this.tableName = "CashierCategories";
  }

  getCategories() {
    return connection.select({
      from: this.tableName,
    });
  }

  setCategories(items) {
    return connection.insert({
      into: this.tableName,
      values: items,
      // return: true,
    });
  }

  clearCategories() {
    return connection.clear(this.tableName);
  }
}
