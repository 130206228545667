import baseUrl from "@/constants/API";

export const PurchaseDetailAPI = {
  deletePurchaseDetail(data) {
    return axios.delete(baseUrl() + `/purchasedetails/delete/${data}`);
  },

  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/purchasedetails/checkdelete`, data);
  },
};
