import baseUrl from "@/constants/API";

const fetchNotifications = () => {
  return axios.get(baseUrl() + "/notifications/get");
};

const deleteNotification = (id) => {
  return axios.delete(baseUrl() + `/notifications/delete/${id}`);
};
const deleteNotifications = () => {
  return axios.get(baseUrl() + `/notifications/delete/all`);
};
export { fetchNotifications, deleteNotification, deleteNotifications };
