import { EntryAPI } from "@/helpers/Apis/Entries/Entries";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  entry: null,

  errors: [],
  entryDetailsErrors: [],
  isEntriesLoaded: true,
};

const getters = {
  getEntry(state) {
    return state.entry;
  },
  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },

  getEntryErrors(state) {
    return state.errors;
  },

  getEntryDetailsErrors(state) {
    return state.entryDetailsErrors;
  },
};

const mutations = {
  setEntry: (state, payload) => {
    state.entry = payload;
  },
  setEntries: (state, payload) => {
    state.entries = payload;
  },
  setIsEntriesLoaded: (state, payload) => {
    state.isEntriesLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchEntry: ({ commit }, id) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsEntriesLoaded", false);
    return new Promise((resolve, reject) => {
      EntryAPI.fetchEntry(id)
        .then((res) => {
          commit("setEntry", res.data.data);
          commit("App/setAppLoader", false, { root: true });
          commit("setIsEntriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsEntriesLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      EntryAPI.previousEntry(id)
        .then((res) => {
          commit("setEntry", res.data.data);
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/entries/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsEntriesLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      EntryAPI.nextEntry(id)
        .then((res) => {
          commit("setEntry", res.data.data);
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/entries/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsEntriesLoaded", true);
          reject(error);
        });
    });
  },

  createEntry: ({ commit }, data) => {
    commit("setIsEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      EntryAPI.createEntry(data)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          let entry = res.data.data;
          commit("setEntry", entry);
          router.push({
            path: `/entries/${entry.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateEntry: ({ commit }, data) => {
    commit("setIsEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      EntryAPI.updateEntry(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setEntry", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteEntry: ({ commit }, data) => {
    commit("setIsEntriesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      EntryAPI.deleteEntry(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
