import baseUrl from "@/constants/API";

export const CashierAPI = {
  fetchItems() {
    return axios.get(baseUrl() + "/items/cashieritems");
  },

  fetchCashier(id) {
    return axios.get(baseUrl() + `/cashier/${id}`);
  },
  previousCashier(id) {
    return axios.get(baseUrl() + `/cashier/${id}/previous`);
  },
  nextCashier(id) {
    return axios.get(baseUrl() + `/cashier/${id}/next`);
  },
  fetchSearchCashiers(data) {
    return axios.post(baseUrl() + `/cashier/search?page=${data.pageNumber}`, data.data);
  },

  createCashier(data) {
    return axios.post(baseUrl() + `/cashier/create`, data);
  },

  updateCashier(data) {
    return axios.post(baseUrl() + `/cashier/update/${data.id}`, data.data);
  },
  deleteCashier(id) {
    return axios.delete(baseUrl() + `/cashier/delete/${id}`);
  },

  cashierDetails(invoice_id) {
    return axios.post(baseUrl() + `/cashier/details/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/cashier/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/cashier/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/cashier/deleted/clear`, data);
  },
};
