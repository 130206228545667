import { DatabaseSettingAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  databaseSettings: {},
  isSettingsLoaded: true,
};

const getters = {
  databaseSettings(state) {
    return state.databaseSettings;
  },
  isSettingsLoaded(state) {
    return state.isSettingsLoaded;
  },
};

const mutations = {
  setDBSettings: (state, payload) => {
    state.databaseSettings = payload;
  },
  setIsSettingsLoaded: (state, payload) => {
    state.isSettingsLoaded = payload;
  },
};

const actions = {
  getDBSettings: ({ commit }) => {
    commit("setIsSettingsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseSettingAPI.getDatabaseSettings()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);
          commit("setDBSettings", res.data.settings);
          commit("User/setDBSettings", res.data.settings, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);

          reject(error);
        });
    });
  },

  saveDBSettings: ({ commit }, data) => {
    commit("setIsSettingsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseSettingAPI.updateDatabaseSettings(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);

          let user = JSON.parse(localStorage.getItem("user"));
          user.database_settings = res.data.data;
          localStorage.setItem("user", JSON.stringify(user));
          commit("setDBSettings", res.data.data);
          commit("User/setDBSettings", res.data.data, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);
          reject(error);
        });
    });
  },

  clearCache: ({ commit }) => {
    commit("setIsSettingsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseSettingAPI.clearCache()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSettingsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
