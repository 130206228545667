import { SaleReturnReportAPI } from "@/helpers/Apis/Invoices/SalesReturns/Reports/SaleReturn";
import i18n from "@/plugins/i18n/i18n";

const state = {
  detailData: [],
  invoiceData: [],
  errors: [],
  isReportDataLoaded: true,
  currentPage: null,

  SearchObject: {
    dates: {
      label: "date",
      value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    },
  },
};

const getters = {
  getDetailData(state) {
    return state.detailData;
  },
  getReportData(state) {
    return state.invoiceData;
  },
  isReportDataLoaded(state) {
    return state.isReportDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },

  getSearchObject(state) {
    return state.SearchObject;
  },
};

const mutations = {
  setDetailData: (state, payload) => {
    state.detailData = payload;
  },
  setInvoiceData: (state, payload) => {
    state.invoiceData = payload;
  },
  setIsReportDataLoaded: (state, payload) => {
    state.isReportDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },

  setSearchObject(state, payload) {
    state.SearchObject = payload;
  },
};

const actions = {
  fetchSaleReturnDetailSearch: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleReturnReportAPI.fetchSaleReturnDetailSearch(data)
        .then((res) => {
          commit("setDetailData", res.data);
          commit("setErrors", []);
          commit("setIsReportDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportDataLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchSaleReturnSearch: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleReturnReportAPI.fetchSaleReturnSearch(data)
        .then((res) => {
          commit("setInvoiceData", res.data);
          commit("setErrors", []);
          commit("setIsReportDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportDataLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
