import baseUrl from "@/constants/API";

export const RestaurantCategoryAPI = {
  fetchCategories() {
    return axios.get(baseUrl() + `/restaurant/categories/get`);
  },

  fetchCachedCategories() {
    return axios.get(baseUrl() + `/restaurant/categories/getcache`);
  },

  createCategory(data) {
    return axios.post(baseUrl() + `/restaurant/categories/create`, data);
  },
  updateCategory(data) {
    return axios.post(baseUrl() + `/restaurant/categories/update/${data.id}`, data.data);
  },

  deleteCategory(id) {
    return axios.delete(baseUrl() + `/restaurant/categories/delete/${id}`);
  },
  clearCache() {
    return axios.post(baseUrl() + `/restaurant/categories/clearcache`);
  },
};
