import * as APIs from "@/helpers/Apis/Clients/ClientOptions";
import i18n from "@/plugins/i18n/i18n";

const state = {
  clients: [],
  errors: [],
  isDataLoaded: true,

  currentPage: null,
};

const getters = {
  getClients(state) {
    return state.clients;
  },
  isDataLoaded(state) {
    return state.isDataLoaded;
  },
  getClientErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setClients: (state, payload) => {
    state.clients = payload;
  },
  setIsDataLoaded: (state, payload) => {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.clients.meta.current_page = payload.value;
  },
};

const actions = {
  fetchSearchClients: ({ commit }, data) => {
    commit("setIsDataLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchSearchClients(data)
        .then((res) => {
          commit("setClients", res.data);
          commit("setIsDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDataLoaded", true);
          reject(error);
        });
    });
  },
  salesAgentsTransfer: ({ commit }, data) => {
    commit("setIsDataLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.salesAgentsTransfer(data)
        .then((res) => {
          // commit("setClients", res.data);
          commit("setIsDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDataLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
