import auth from "@/router/middleware/auth";
// import guest from "@/router/middleware/guest";
const Home = () => import("@views/Home.vue");

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "general" */ `@containers/${folderName}/${view}.vue`);
}

const GeneralRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/database/settings",
    name: "DatabaseSettings",
    component: lazyLoad("Database", "Settings"),
    // component: () => import(/* webpackChunkName: "general" */ `@containers/DBSettings/DBSettings.vue`),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/database/systemtools",
    name: "SystemTools",
    component: lazyLoad("Database", "SystemTools"),
    meta: {
      middleware: [auth],
    },
  },

  // { path: '*', component: NotFound }
];

export default GeneralRoutes;
