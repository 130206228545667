import baseUrl from "@/constants/API";

export const ItemExpireAPI = {
  ReviewExpireDates() {
    return axios.get(baseUrl() + `/items/expires/review`);
  },

  fetchExpiresTransactionsFiltered(data) {
    return axios.post(
      baseUrl() + `/items/expires/transactionsfilter?page=${data.pageNumber}`,
      data.data
    );
    // return axios.post(baseUrl() + "/items/expires/transactionsfilter", data);
  },
  fetchExpiresQtyFiltered(data) {
    return axios.post(baseUrl() + `/items/expires/qtyfilter?page=${data.pageNumber}`, data.data);
  },

  // Expire Fetches Expire Fetches Expire Fetches Expire Fetches
  fetchAvailableExpires(data) {
    return axios.post(baseUrl() + `/items/expires/availables`, data);
  },

  fetchAvailableExpiresRelated(data) {
    return axios.post(baseUrl() + "/items/expires/availablesrelated", data);
  },
};
