import baseUrl from "@/constants/API";

export const MaintenanceDetailAPI = {
  createMaintenanceItem(data) {
    return axios.post(baseUrl() + `/maintenancedetails/create`, data);
  },
  updateMaintenanceItem(data) {
    return axios.post(baseUrl() + `/maintenancedetails/update/${data.id}`, data.data);
  },

  deleteMaintenanceDetail(id) {
    return axios.delete(baseUrl() + `/maintenancedetails/delete/${id}`);
  },
};
