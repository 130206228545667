import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () =>
    import(/* webpackChunkName: "maintenance" */ `@containers/${folderName}/${view}.vue`);
}

const MaintenanceRoutes = [
  {
    path: "/maintenanceclients",
    name: "MaintenanceClients",
    component: lazyLoad("Maintenance", "MaintenanceClients"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/maintenances",
    name: "Maintenance",
    component: lazyLoad("Maintenance", "Maintenances"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/maintenances/:id(\\d+)",
    name: "SingleMaintenance",
    component: lazyLoad("Maintenance", "Maintenance"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/maintenances/create",
    name: "CreateMaintenance",
    component: lazyLoad("Maintenance", "Maintenance"),
    meta: {
      middleware: [auth],
    },
  },
  //////////// Maintenances Reports
  {
    path: "/maintenances/deleted",
    name: "MaintenancesDeleted",
    component: lazyLoad("Maintenance", "Reports/MaintenancesDeleted"),
    meta: {
      middleware: [auth],
    },
  },
];

export default MaintenanceRoutes;
