import baseUrl from "@/constants/API";

export const ClientAPI = {
  fetchClient(data) {
    return axios.get(baseUrl() + `/clients/${data.id}`, {
      params: data.data,
    });
  },
  fetchClients(data) {
    return axios.post(baseUrl() + `/clients/get?page=${data.pageNumber}`, data.data);
  },

  createClient(data) {
    return axios.post(baseUrl() + `/clients/create`, data);
  },
  updateClient(data) {
    return axios.post(baseUrl() + `/clients/update/${data.id}`, data.data);
  },
  deleteClient(data) {
    return axios.delete(baseUrl() + `/clients/delete/${data}`);
  },
  searchClients(data) {
    return axios.get(baseUrl() + `/clients/search/${data.name}`);
  },

  getClientsReceivable(data) {
    return axios.post(baseUrl() + `/clients/clientsreceivable?page=${data.pageNumber}`, data.data);
  },

  getClientBalance(id) {
    return axios.get(baseUrl() + `/clients/balance/${id}`);
  },

  // Excel Excel Excel Excel Excel Excel
  exportTable(data) {
    return axios.post(baseUrl() + `/clients/export`, data, {
      responseType: "blob",
    });
  },
  downloadExportFile(data) {
    return axios.post(baseUrl() + `/clients/export/download`, data, {
      responseType: "blob",
    });
  },
  downloadCreateTemplate() {
    return axios.post(baseUrl() + `/clients/import/createtemplate`, null, {
      responseType: "blob",
    });
  },
  downloadUpdateTemplate() {
    return axios.post(baseUrl() + `/clients/import/updatetemplate`, null, {
      responseType: "blob",
    });
  },
  importCreate(data) {
    return axios.post(baseUrl() + `/clients/import/create`, data);
  },
  importUpdate(data) {
    return axios.post(baseUrl() + `/clients/import/update`, data);
  },
};
