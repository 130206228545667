import * as APIs from "@/helpers/Apis/Maintenance/DeviceTypes";
import i18n from "@/plugins/i18n/i18n";

const state = {
  devicetypes: [],

  isDeviceTypeLoaded: true,
  errors: [],
};

const getters = {
  isDeviceTypeLoaded(state) {
    return state.isDeviceTypeLoaded;
  },
  getDeviceTypes(state) {
    return state.devicetypes;
  },
  getDeviceTypesErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDeviceTypeLoaded: (state, payload) => {
    state.isDeviceTypeLoaded = payload;
  },
  setDeviceTypes: (state, payload) => {
    state.devicetypes = payload;
  },
  addDevice: (state, payload) => {
    state.devicetypes.push(payload);
  },
  deleteDevice: (state, payload) => {
    let index = state.devicetypes.findIndex((object) => {
      return object.id === payload;
    });
    state.devicetypes.splice(index, 1);
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchDeviceTypes: ({ commit }) => {
    commit("setIsDeviceTypeLoaded", false);
    APIs.fetchDeviceTypes()
      .then((res) => {
        commit("setDeviceTypes", res.data);
        commit("setIsDeviceTypeLoaded", true);
      })
      .catch((error) => {
        commit("setIsDeviceTypeLoaded", true);
      });
  },
  reloadDeviceTypes: ({ commit }) => {
    commit("setIsDeviceTypeLoaded", false);
    APIs.reloadDeviceTypes()
      .then((res) => {
        commit("setDeviceTypes", res.data);
        commit("setIsDeviceTypeLoaded", true);
      })
      .catch((error) => {
        commit("setIsDeviceTypeLoaded", true);
      });
  },

  createDeviceType: ({ commit }, data) => {
    commit("setIsDeviceTypeLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createDeviceType(data)
        .then((res) => {
          commit("setIsDeviceTypeLoaded", true);
          commit("setErrors", "");
          commit("addDevice", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDeviceTypeLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateDeviceType: ({ commit }, data) => {
    commit("setIsDeviceTypeLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateDeviceType(data)
        .then((res) => {
          commit("setIsDeviceTypeLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDeviceTypeLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteDeviceType: ({ commit }, id) => {
    commit("setIsDeviceTypeLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteDeviceType(id)
        .then((response) => {
          commit("setErrors", "");
          commit("deleteDevice", id);
          commit("setIsDeviceTypeLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsDeviceTypeLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
