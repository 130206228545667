import { UserControlAPI, SubAccountAPI, MainAccountAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  mainAccounts: [],
  isMainAccountsLoaded: true,

  subAccounts: [],
  isSubAccountsLoaded: true,

  closedMainAccounts: [],
  closedSubAccounts: [],
  isClosedAccountsLoaded: true,

  errors: [],
};

const getters = {
  getMainAccounts(state) {
    return state.mainAccounts;
  },
  isMainAccountsLoaded(state) {
    return state.isMainAccountsLoaded;
  },
  getSubAccounts(state) {
    return state.subAccounts;
  },
  isSubAccountsLoaded(state) {
    return state.isSubAccountsLoaded;
  },

  getClosedMainAccounts(state) {
    return state.closedMainAccounts;
  },
  getClosedSubAccounts(state) {
    return state.closedSubAccounts;
  },
  isClosedAccountsLoaded(state) {
    return state.isClosedAccountsLoaded;
  },

  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setMainAccounts: (state, payload) => {
    state.mainAccounts = payload;
  },
  setIsMainAccountsLoaded: (state, payload) => {
    state.isMainAccountsLoaded = payload;
  },
  setSubAccounts: (state, payload) => {
    state.subAccounts = payload;
  },
  setIsSubAccountsLoaded: (state, payload) => {
    state.isSubAccountsLoaded = payload;
  },

  setClosedMainAccounts: (state, payload) => {
    state.closedMainAccounts = payload;
  },
  setClosedSubAccounts: (state, payload) => {
    state.closedSubAccounts = payload;
  },
  setIsClosedAccountsLoaded: (state, payload) => {
    state.isClosedAccountsLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchBasicMainAcc: ({ commit }, data) => {
    commit("setIsMainAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchBasicMainAcc(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setMainAccounts", res.data);
          commit("setIsMainAccountsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccountsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchBasicSubAcc: ({ commit }, data) => {
    commit("setIsSubAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchBasicSubAcc(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setSubAccounts", res.data.data);
          commit("setIsSubAccountsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccountsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  UserClosedAccounts: ({ commit }, data) => {
    commit("setIsClosedAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.userClosedAccounts(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setClosedMainAccounts", res.data[0]);
          commit("setClosedSubAccounts", res.data[1]);
          commit("setIsClosedAccountsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClosedAccountsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  saveClosedAccounts: ({ commit }, data) => {
    commit("setIsClosedAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.saveUserClosedAccounts(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setClosedMainAccounts", data.closed_main_accounts);
          commit("setClosedSubAccounts", data.closed_sub_accounts);
          commit("setIsClosedAccountsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClosedAccountsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
