import baseUrl from "@/constants/API";

export const CityAPI = {
  fetchCities() {
    return axios.get(baseUrl() + `/cities/get`);
  },

  createCity(data) {
    return axios.post(baseUrl() + `/cities/create`, data);
  },

  updateCity(data) {
    return axios.post(baseUrl() + `/cities/update/${data.id}`, data.data);
  },

  deleteCity(id) {
    return axios.delete(baseUrl() + `/cities/delete/${id}`);
  },
};
