import { connection } from "@/helpers/IndexDB/jsstore_con";
let todayDate = new Date();
todayDate = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getDate();

export class HeadService {
  constructor() {
    this.tableName = "CashierHeads";
  }

  getHeads() {
    return connection.select({
      from: this.tableName,
    });
  }

  addHead(head) {
    head.date = todayDate;
    return connection.insert({
      into: this.tableName,
      values: [head],
      return: true,
    });
  }

  getHeadById(id) {
    return connection.select({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  deleteHead(id) {
    return connection.remove({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  clearHeads() {
    return connection.clear(this.tableName);
  }

  updateHeadById(head) {
    return connection.update({
      in: this.tableName,
      set: {
        date: todayDate,
        time: head.time,
        remaining: head.remaining,
        paid: head.paid,
        total_before: head.total + head.discount_value,
        total: head.total,
        discount_value: head.discount_value,
        discount_ratio: head.discount_ratio,
        tax_value: head.tax_value,
        tax_ratio: head.tax_ratio,
        checked_out: head.checked_out,
      },
      where: {
        id: head.id,
      },
    });
  }
}
