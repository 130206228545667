function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

function ErrorsHandler(response) {
  switch (response) {
    case 400: // bad request
      return false;
    case 401: // unauthorized
      return false;
    case 403: // forbidden
      return false;
    case 404: // Not Found
      return false;
    case 408: // Request Timeout
      return false;
    case 429: // Too Many Requests
      return false;
    case 500: // Internal Server Error
      return false;
    case 502: // Bad Gateway
      return false;
    case 503: // Service Unavailable
      return false;
    case 504: // Gateway Timeout
      return false;
    case 505: // HTTP Version Not Supported
      return false;
    default:
      return true;
  }
}

export { toFixedIfNecessary, ErrorsHandler };
