import baseUrl from "@/constants/API";

const fetchClassifications = () => {
  return axios.get(baseUrl() + "/itemclassifications/all");
};
const fetchClassification = (id) => {
  return axios.get(baseUrl() + `/itemclassifications/single/${id}`);
};
const createClassification = (data) => {
  return axios.post(baseUrl() + `/itemclassifications/create`, data);
};
const updateClassification = (data) => {
  return axios.post(baseUrl() + `/itemclassifications/update/${data.id}`, data.data);
};
const deleteClassification = (id) => {
  return axios.delete(baseUrl() + `/itemclassifications/delete/${id}`);
};
const fetchSearchClassifications = (data) => {
  return axios.post(baseUrl() + `/itemclassifications/search/${data}`);
};

export {
  fetchClassifications,
  fetchClassification,
  createClassification,
  updateClassification,
  deleteClassification,
  fetchSearchClassifications,
};
