import baseUrl from "@/constants/API";

export const BranchAPI = {
  fetchBranch(data) {
    return axios.get(baseUrl() + `/branches/${data.id}`);
  },
  fetchBranches() {
    return axios.get(baseUrl() + "/branches/get");
  },

  createBranch(data) {
    return axios.post(baseUrl() + `/branches/create`, data);
  },
  updateBranch(data) {
    return axios.post(baseUrl() + `/branches/update/${data.id}`, data.data);
  },
  deleteBranch(data) {
    return axios.delete(baseUrl() + `/branches/delete/${data}`);
  },

  fetchUserBranches() {
    return axios.post(baseUrl() + "/branches/user");
  },

  fetchBasicBranches() {
    return axios.get(baseUrl() + "/branches/basicbranches");
  },
};
