import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "purchase" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/purchases",
    name: "Purchases",
    component: lazyLoad("Invoice/Purchases", "Purchases"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchases/:id(\\d+)",
    name: "SinglePurchase",
    component: lazyLoad("Invoice/Purchases", "Purchase"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchases/create",
    name: "CreatePurchase",
    component: lazyLoad("Invoice/Purchases", "Purchase"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/purchasesreturns",
    name: "PurchasesReturns",
    component: lazyLoad("Invoice/PurchasesReturns", "PurchasesReturns"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchasesreturns/:id(\\d+)",
    name: "SinglePurchaseReturn",
    component: lazyLoad("Invoice/PurchasesReturns", "PurchaseReturns"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchasesreturns/create",
    name: "CreatePurchaseReturn",
    component: lazyLoad("Invoice/PurchasesReturns", "PurchaseReturns"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Purchases Reports
  {
    path: "/purchases/itemsearch",
    name: "PurchaseItemSearch",
    component: lazyLoad("Invoice", "Purchases/Reports/PurchaseItemSearch"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchases/deleted",
    name: "PurchasesDeleted",
    component: lazyLoad("Invoice", "Purchases/Reports/PurchasesDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Purchases Returns Reports
  {
    path: "/purchasesreturns/itemsearch",
    name: "PurchaseReturnItemSearch",
    component: lazyLoad("Invoice", "PurchasesReturns/Reports/PurchaseReturnItemSearch"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/purchasesreturns/deleted",
    name: "PurchasesReturnsDeleted",
    component: lazyLoad("Invoice", "PurchasesReturns/Reports/PurchasesReturnsDeleted"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
