import baseUrl from "@/constants/API";

export const ReceivableAPI = {
  fetchReceivables(data) {
    return axios.post(baseUrl() + `/receivables/search?page=${data.pageNumber}`, data.data);
  },

  CreateNote(data) {
    return axios.post(baseUrl() + "/receivables/create", data);
  },
  fetchReceivable(id) {
    return axios.get(baseUrl() + `/receivables/single/${id}`);
  },

  deleteNote(id) {
    return axios.delete(baseUrl() + `/receivables/delete/${id}`);
  },

  bankCollect(data) {
    return axios.post(baseUrl() + "/receivables/bankcollect", data);
  },
  drawerCollect(data) {
    return axios.post(baseUrl() + "/receivables/drawercollect", data);
  },

  noteReturned(id) {
    return axios.put(baseUrl() + `/receivables/returned/${id}`);
  },

  collectedToAnother(data) {
    return axios.post(baseUrl() + "/receivables/collecttoanother", data);
  },

  getInvoiceNotes(data) {
    return axios.get(baseUrl() + `/receivables/invoicechecks/${data.id}/${data.invoicetype_id}`);
  },

  getNoteEntries(id) {
    return axios.get(baseUrl() + `/receivables/noteentries/${id}`);
  },

  deleteEntry(id) {
    return axios.delete(baseUrl() + `/receivables/deleteentry/${id}`);
  },

  createInvoiceNotes(data) {
    return axios.post(baseUrl() + `/receivables/createinvoicenotes`, data);
  },
};
