import i18n from "@/plugins/i18n/i18n";
import { DetailService } from "@/helpers/IndexDB/CashierDetails";
import { HeadService } from "@/helpers/IndexDB/CashierHeads";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";

let todayDate = new Date();
todayDate = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getDate();

const state = {
  cashierHeads: [],
  cashierDetails: [],
  invoiceIndex: 0,

  isAdminPassChecked: false,
};

const getters = {
  getCashierHeads(state) {
    return state.cashierHeads;
  },
  getCashierDetails(state) {
    return state.cashierDetails;
  },

  getCurrentHead(state) {
    return state.cashierHeads[state.invoiceIndex];
  },
  getCurrentDetails(state) {
    let cashierDetails = state.cashierDetails.filter((elem) => {
      return elem.invoice_id == state.cashierHeads[state.invoiceIndex].id;
    });

    return cashierDetails;
  },
  getCurrentIndex(state) {
    return state.invoiceIndex;
  },
  getIsAdminPassChecked(state) {
    return state.isAdminPassChecked;
  },
};

const mutations = {
  setCashierHeads: (state, payload) => {
    state.cashierHeads = payload;
  },

  setCashierDetails: (state, payload) => {
    state.cashierDetails = payload;
  },

  setCurrentIndex(state, payload) {
    if (state.cashierHeads.length == 0) {
      iziToast.error({
        message: i18n.t("noinvoice"),
        type: "error",
      });
    } else if (state.cashierHeads[payload]) {
      state.invoiceIndex = payload;
    } else {
      state.invoiceIndex = 0;
    }
  },

  setIsAdminPassChecked(state, payload) {
    state.isAdminPassChecked = payload;
  },
};

const actions = {
  async refreshData({ getters, commit }, type) {
    let details = await new DetailService().getDetails();
    let heads = await new HeadService().getHeads();
    commit("setCashierHeads", heads);
    commit("setCashierDetails", details);

    // let databaseSettings = JSON.parse(localStorage.getItem("user"))?.database_settings;
    // +databaseSettings["db:cashierdefaulttax"] ??
    if (heads.length == 0) {
      await new HeadService().addHead({
        remaining: 0,
        paid: 0,
        total: 0,
        discount_value: 0,
        discount_ratio: 0,

        tax_value: 0,
        tax_ratio: 0,
      });

      let details = await new DetailService().getDetails();
      let heads = await new HeadService().getHeads();
      commit("setCashierHeads", heads);
      commit("setCashierDetails", details);

      commit("setCurrentIndex", getters.getCashierHeads.length - 1);
    }

    // if (type == "firstLoad") {
    //   commit("setCurrentIndex", getters.getCashierHeads.length - 1);
    // }
  },

  async clearData({ getters, commit }) {
    await new HeadService().clearHeads();
    await new DetailService().clearDetails();

    // let databaseSettings = JSON.parse(localStorage.getItem("user"))?.database_settings;
    // +databaseSettings["db:cashierdefaulttax"] ??
    await new HeadService().addHead({
      remaining: 0,
      paid: 0,
      total: 0,
      discount_value: 0,
      discount_ratio: 0,
      tax_value: 0,
      tax_ratio: 0,
    });

    let details = await new DetailService().getDetails();
    let heads = await new HeadService().getHeads();
    commit("setCashierHeads", heads);
    commit("setCashierDetails", details);
    commit("setCurrentIndex", 0);
  },

  async addHead({ getters, commit, dispatch }) {
    let lastHead = getters.getCashierHeads[getters.getCashierHeads.length - 1];
    // let details = getters.getCashierDetails.filter((elem) => {
    //   return elem.invoice_id == lastHead.id;
    // });
    // let databaseSettings = JSON.parse(localStorage.getItem("user"))?.database_settings;
    // +databaseSettings["db:cashierdefaulttax"] ?? 0
    if (lastHead.checked_out) {
      await new HeadService().addHead({
        remaining: 0,
        paid: 0,
        total: 0,
        discount_value: 0,
        discount_ratio: 0,
        tax_value: 0,
        tax_ratio: 0,
      });
      await dispatch("refreshData");
      commit("setCurrentIndex", getters.getCashierHeads.length - 1);
    } else {
      commit("setCurrentIndex", getters.getCashierHeads.length - 1);
    }
  },

  // async addOrUpdateDetail({ state, getters, dispatch }, payload) {
  //   try {
  //     const result = getters.getCurrentHead;

  //     // Admin check
  //     if (!state.isAdminPassChecked && result.checked_out) {
  //       iziToast.error({
  //         message: i18n.t("invoicecheckedout"),
  //         type: "error",
  //       });
  //       return;
  //     }

  //     const item = payload.item || payload; // Use item from payload or payload directly for addItem
  //     const itemPrice = toFixedIfNecessary(+item.consumer / +item.howmanyinbig, 2);
  //     const itemPriceAfter = toFixedIfNecessary(+payload.price / +item.howmanyinbig, 2);
  //     const ogDiscV = +payload.discount || 0; // Original discount per unit/piece
  //     const ogTaxV = +payload.tax || 0; // Original tax per unit/piece
  //     const detailService = new DetailService();

  //     // Fetch the detail for this invoice and item
  //     let detail = await detailService.getDetailByInvoiceIdItem({
  //       invoice_id: result.id,
  //       item_id: item.id,
  //     });

  //     if (detail.length > 0) {
  //       // Existing detail found, update the quantity
  //       let existingDetail = detail[0];

  //       if (item.howmanyinbig == 1) {
  //         existingDetail.unit += 1;
  //         existingDetail.total_before = toFixedIfNecessary(existingDetail.unit * itemPrice, 2);
  //         existingDetail.total = toFixedIfNecessary(existingDetail.unit * itemPriceAfter, 2);
  //         existingDetail.discount_value = toFixedIfNecessary(
  //           existingDetail.og_discV * existingDetail.unit,
  //           2
  //         );
  //         existingDetail.tax_value = toFixedIfNecessary(
  //           existingDetail.og_taxV * existingDetail.unit,
  //           2
  //         );
  //       } else {
  //         existingDetail.piece += 1;
  //         existingDetail.total_before = toFixedIfNecessary(existingDetail.piece * itemPrice, 2);
  //         existingDetail.total = toFixedIfNecessary(existingDetail.piece * itemPriceAfter, 2);
  //         existingDetail.discount_value = toFixedIfNecessary(
  //           existingDetail.og_discV * existingDetail.piece,
  //           2
  //         );
  //         existingDetail.tax_value = toFixedIfNecessary(
  //           existingDetail.og_taxV * existingDetail.piece,
  //           2
  //         );
  //       }

  //       await detailService.updateDetailById(existingDetail);
  //     } else {
  //       // New detail, add it
  //       const quantity = item.howmanyinbig === 1 ? 1 : item.howmanyinbig;

  //       const total_before = toFixedIfNecessary(quantity * itemPrice, 2);
  //       const total = toFixedIfNecessary(quantity * itemPriceAfter, 2);
  //       const discount_value = toFixedIfNecessary(ogDiscV * quantity, 2);
  //       const tax_value = toFixedIfNecessary(ogTaxV * quantity, 2);

  //       await detailService.addDetail({
  //         invoice_id: result.id,
  //         item_id: item.id,
  //         item_name: item.name,
  //         howmanyinbig: item.howmanyinbig,
  //         barcode: item.barcode,
  //         unit: item.howmanyinbig === 1 ? 1 : 0,
  //         piece: item.howmanyinbig !== 1 ? quantity : 0,
  //         total_before,
  //         total,
  //         discount_value,
  //         tax_value,
  //         price: itemPrice,
  //         priceAfter: itemPriceAfter,
  //         og_discV: toFixedIfNecessary(ogDiscV, 2), // Original discount per unit/piece
  //         og_taxV: toFixedIfNecessary(ogTaxV, 2), // Original tax per unit/piece
  //       });
  //     }

  //     dispatch("refreshData");
  //   } catch (error) {
  //     console.error("Error adding/updating detail:", error);
  //   }
  // },

  async addDetail({ state, getters, dispatch }, payload) {
    let result = getters.getCurrentHead;
    if (!state.isAdminPassChecked && result.checked_out) {
      return iziToast.error({
        message: i18n.t("invoicecheckedout"),
        type: "error",
      });
    }

    let item = payload.item;
    let itemPrice = toFixedIfNecessary(+item.consumer / +item.howmanyinbig, 2);
    let itemPriceAfter = toFixedIfNecessary(+payload.price / +item.howmanyinbig, 2);
    let itemDiscount = +payload.discount || 0;
    let itemTax = +payload.tax || 0;

    let invoice_id = getters.getCurrentHead.id;
    let item_id = item.id;

    let detail = await new DetailService().getDetailByInvoiceIdItem({
      invoice_id: invoice_id,
      item_id: item_id,
    });

    let qty;
    if (detail.length > 0) {
      if (item.howmanyinbig == 1) {
        detail[0].unit = detail[0].unit + 1;
        detail[0].total_before = toFixedIfNecessary(detail[0].unit * itemPrice, 2);
        detail[0].total = toFixedIfNecessary(detail[0].unit * itemPriceAfter, 2);

        detail[0].discount_value = toFixedIfNecessary(detail[0].og_discV * detail[0].unit, 2);
        detail[0].tax_value = toFixedIfNecessary(detail[0].og_taxV * detail[0].unit, 2);
        await new DetailService().updateDetailById(detail[0]);
      } else {
        qty = item.piece_barcode == payload.barcode ? 1 : item.howmanyinbig;
        detail[0].piece = detail[0].piece + qty;
        detail[0].total_before = toFixedIfNecessary(detail[0].piece * itemPrice, 2);
        detail[0].total = toFixedIfNecessary(detail[0].piece * itemPriceAfter, 2);

        detail[0].discount_value = +detail[0].og_discV * +detail[0].piece;
        detail[0].tax_value = +detail[0].og_taxV * +detail[0].piece;
        await new DetailService().updateDetailById(detail[0]);
      }
    } else {
      if (item.howmanyinbig != 1) {
        qty = item.piece_barcode == payload.barcode ? 1 : item.howmanyinbig;
      } else {
        qty = 0;
      }
      let total = 1 * itemPrice;
      let discountValue = +total * (+itemDiscount / 100);
      let totalAfterDiscount = total - discountValue;
      let taxValue = +totalAfterDiscount * (+itemTax / 100);
      let netTotal = 1 * itemPriceAfter;

      await new DetailService().addDetail({
        invoice_id: invoice_id,
        item_id: item_id,
        item_name: item.name,
        howmanyinbig: item.howmanyinbig,
        barcode: item.barcode,
        unit: item.howmanyinbig == 1 ? 1 : 0,
        piece: qty,

        og_discV: toFixedIfNecessary(discountValue, 2),
        og_taxV: toFixedIfNecessary(taxValue, 2),
        discount_value: toFixedIfNecessary(discountValue, 2),
        discount_ratio: toFixedIfNecessary(itemDiscount, 2),
        tax_value: toFixedIfNecessary(taxValue, 2),
        tax_ratio: toFixedIfNecessary(itemTax, 2),

        price: itemPrice,
        priceAfter: itemPriceAfter,
        total_before: toFixedIfNecessary(total, 2),
        total: toFixedIfNecessary(netTotal, 2),
      });
    }
    dispatch("refreshData");
  },

  async addItem({ state, getters, dispatch }, payload) {
    let result = getters.getCurrentHead;
    if (!state.isAdminPassChecked && result.checked_out) {
      return iziToast.error({
        message: i18n.t("invoicecheckedout"),
        type: "error",
      });
    }

    let itemPrice = payload.price;
    let itemPriceAfter = +payload.priceAfter;
    if (payload.howmanyinbig == 1) {
      payload.unit = payload.unit + 1;
      payload.total_before = toFixedIfNecessary(payload.unit * itemPrice, 2);
      payload.total = toFixedIfNecessary(payload.unit * itemPriceAfter, 2);

      payload.discount_value = toFixedIfNecessary(payload.og_discV * payload.unit, 2);
      payload.tax_value = toFixedIfNecessary(payload.og_taxV * payload.unit, 2);
    } else {
      payload.piece = payload.piece + 1;
      payload.total_before = toFixedIfNecessary(payload.piece * itemPrice, 2);
      payload.total = toFixedIfNecessary(payload.piece * itemPriceAfter, 2);

      payload.discount_value = toFixedIfNecessary(payload.og_discV * payload.piece, 2);
      payload.tax_value = toFixedIfNecessary(payload.og_taxV * payload.piece, 2);
    }
    await new DetailService().updateDetailById(payload);
    dispatch("refreshData");
  },

  async removeItem({ state, getters, dispatch }, payload) {
    let result = getters.getCurrentHead;
    if (!state.isAdminPassChecked && result.checked_out) {
      return iziToast.error({
        message: i18n.t("invoicecheckedout"),
        type: "error",
      });
    }

    let itemPrice = payload.price;
    let itemPriceAfter = +payload.priceAfter;

    if (payload.howmanyinbig == 1) {
      let unit = payload.unit - 1;
      if (unit > 0) {
        payload.unit = payload.unit - 1;
        payload.total_before = toFixedIfNecessary(payload.unit * itemPrice, 2);
        payload.total = toFixedIfNecessary(payload.unit * itemPriceAfter, 2);

        payload.discount_value = toFixedIfNecessary(payload.og_discV * payload.unit, 2);
        payload.tax_value = toFixedIfNecessary(payload.og_taxV * payload.unit, 2);
        await new DetailService().updateDetailById(payload);
        dispatch("refreshData");
      } else {
        dispatch("deleteDetail", payload);
      }
    } else {
      let piece = payload.piece - 1;
      if (piece > 0) {
        payload.piece = payload.piece - 1;
        payload.total_before = toFixedIfNecessary(payload.piece * itemPrice, 2);
        payload.total = toFixedIfNecessary(payload.piece * itemPriceAfter, 2);

        payload.discount_value = toFixedIfNecessary(payload.og_discV * payload.piece, 2);
        payload.tax_value = toFixedIfNecessary(payload.og_taxV * payload.piece, 2);
        await new DetailService().updateDetailById(payload);
        dispatch("refreshData");
      } else {
        dispatch("deleteDetail", payload);
      }
    }
  },

  async deleteHead({ state, getters, commit, dispatch }, id) {
    if (getters.getCashierHeads.length != 1) {
      if (!state.isAdminPassChecked) {
        return iziToast.error({
          message: i18n.t("nopermission"),
          type: "error",
        });
      }

      commit("setCurrentIndex", state.invoiceIndex - 1);
      let headDetails = await new DetailService().getDetailByInvoiceId(id);
      headDetails.forEach((element) => {
        new DetailService().removeDetail(element.id);
      });

      await new HeadService().deleteHead(id);
      dispatch("refreshData");
    } else {
      iziToast.error({
        message: i18n.t("errors.cantdelete"),
        type: "error",
      });
    }
  },

  async deleteDetail({ state, getters, dispatch }, detail) {
    let result = getters.getCurrentHead;
    if (!state.isAdminPassChecked && result.checked_out) {
      return iziToast.error({
        message: i18n.t("invoicecheckedout"),
        type: "error",
      });
    }

    await new DetailService().removeDetail(detail.id);
    dispatch("refreshData");
  },

  async updateHead({ getters, commit, dispatch }, head) {
    await new HeadService().updateHeadById(head);
    dispatch("refreshData");
  },

  async checkout({ getters, commit, dispatch }, head) {
    await new HeadService().updateHeadById(head);

    let lastHead = getters.getCashierHeads[getters.getCashierHeads.length - 1];
    // let databaseSettings = JSON.parse(localStorage.getItem("user"))?.database_settings;
    // +databaseSettings["db:cashierdefaulttax"] ??
    if (lastHead.checked_out) {
      await new HeadService().addHead({
        remaining: 0,
        paid: 0,
        total: 0,
        discount_value: 0,
        discount_ratio: 0,
        tax_value: 0,
        tax_ratio: 0,
      });

      let details = await new DetailService().getDetails();
      let heads = await new HeadService().getHeads();
      commit("setCashierHeads", heads);
      commit("setCashierDetails", details);
      // commit("setCurrentIndex", getters.getCashierHeads.length - 1);
    }
    commit("setCurrentIndex", getters.getCashierHeads.length - 1);
  },

  async updateDetail({ getters, dispatch }, detail) {
    await new DetailService().updateDetailById(detail);
    dispatch("refreshData");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
