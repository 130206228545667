import baseUrl from "@/constants/API";

export const BeginningEntryAPI = {
  fetchBeginningMainAccs(branchId) {
    return axios.get(baseUrl() + `/openbalance/mainaccs/${branchId}`);
  },

  fetchMainAccsEntries(id) {
    return axios.get(baseUrl() + `/openbalance/mainaccentries/${id}`);
  },

  fetchBalanceEntries() {
    return axios.get(baseUrl() + "/openbalance/all");
  },
  fetchMainAccEntries(data) {
    return axios.post(baseUrl() + `/openbalance/mainaccounts?page=${data.pageNumber}`, data.data);
  },

  createEntryDetail(data) {
    return axios.post(baseUrl() + `/openbalance/create`, data);
  },

  updateEntryDetail(data) {
    return axios.post(baseUrl() + `/openbalance/update/${data.id}`, data.data);
  },

  createUpdateManyDetails(data) {
    return axios.post(baseUrl() + `/openbalance/createupdatemany`, data);
  },

  deleteEntryDetail(data) {
    return axios.delete(baseUrl() + `/openbalance/delete/${data}`);
  },

  // Excel Excel Excel Excel Excel Excel
  exportTable(data) {
    return axios.post(baseUrl() + `/openbalance/export`, data, {
      responseType: "blob",
    });
  },
  downloadExportFile(data) {
    return axios.post(baseUrl() + `/openbalance/export/download`, data, {
      responseType: "blob",
    });
  },
  downloadCreateTemplate() {
    return axios.post(baseUrl() + `/openbalance/import/createtemplate`, null, {
      responseType: "blob",
    });
  },
  downloadUpdateTemplate() {
    return axios.post(baseUrl() + `/openbalance/import/updatetemplate`, null, {
      responseType: "blob",
    });
  },
  importCreate(data) {
    return axios.post(baseUrl() + `/openbalance/import/create`, data);
  },
  importUpdate(data) {
    return axios.post(baseUrl() + `/openbalance/import/update`, data);
  },
};
