import baseUrl from "@/constants/API";

const productionDetails = (invoice_id) => {
  return axios.post(baseUrl() + `/productiondetails/${invoice_id}`);
};

const deleteProductionDetail = (data) => {
  return axios.delete(baseUrl() + `/productiondetails/delete/${data}`);
};

const checkDeleteDetail = (data) => {
  return axios.post(baseUrl() + `/productiondetails/checkdelete`, data);
};

export { productionDetails, deleteProductionDetail, checkDeleteDetail };
