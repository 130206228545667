import baseUrl from "@/constants/API";

export const EntryDetailAPI = {
  deleteEntryDetail(data) {
    return axios.delete(baseUrl() + `/entrydetail/delete/${data}`);
  },

  invoiceEntries(data) {
    return axios.post(baseUrl() + `/entrydetail/invoiceentries`, data);
  },

  createCustomEntry(data) {
    return axios.post(baseUrl() + `/entrydetail/customentry`, data);
  },

  createCustomEntryBoth(data) {
    return axios.post(baseUrl() + `/entrydetail/customentryboth`, data);
  },

  createProdEntry(data) {
    return axios.post(baseUrl() + `/entrydetail/customprodentry`, data);
  },
  deleteProdEntry(id) {
    return axios.delete(baseUrl() + `/entrydetail/deleteprodentry/${id}`);
  },

  loadCustomEntry(data) {
    return axios.get(
      baseUrl() + `/entrydetail/loadcustomentry/${data.invoice_id}/${data.invoicetype_id}`
    );
  },
};
