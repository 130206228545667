import baseUrl from "@/constants/API";

export const DatabaseDefaultAPI = {
  getDBDefaults() {
    return axios.get(baseUrl() + `/databasedefault/get`);
  },
  saveDBDefaults(data) {
    return axios.post(baseUrl() + `/databasedefault/save`, data);
  },
  resetDBDefaults() {
    return axios.post(baseUrl() + `/databasedefault/reset`);
  },

  getJobs() {
    return axios.get(baseUrl() + `/databasedefault/getjobs`);
  },
  fetchMainAccs() {
    return axios.get(baseUrl() + `/databasedefault/getmainaccs`);
  },
  fetchSubAccs() {
    return axios.get(baseUrl() + `/databasedefault/getsubaccs`);
  },
};
