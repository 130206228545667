<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    offset-y
    :disabled="usePermission ? !allowChangeDate : false"
  >
    <!-- :nudge-right="40" -->
    <template v-slot:activator="{ on, attrs }">
      <!-- @input="$v.date.$touch()" -->
      <BasicTextField
        :value="formatDate(value)"
        :label="label ? label : $t('date')"
        :readonly="true"
        v-bind="attrs"
        v-on="on"
        :error-messages="dateErrors"
        :clearable="clearable"
        @click:clear="updateValue(null)"
        :disabled="usePermission ? !allowChangeDate : false"
        hide-details="auto"
        :class="customClass"
      />
    </template>
    <!-- scrollable -->
    <v-date-picker
      no-title
      :value="value"
      @input="updateValue"
      :max="usePermission ? maxAllowedDate : null"
      :min="beginDate"
      :locale="getLanguage ? 'en' : 'ar'"
      :dir="getLanguage ? 'ltr' : 'rtl'"
      :first-day-of-week="6"
      :disabled="usePermission ? !allowChangeDate : false"
      hide-details="auto"
    >
      <v-btn
        v-if="usePermission ? allowChangeDate : true"
        text
        @click="setBeginingDate"
      >
        {{ beginDate }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn text @click="setToday">
        {{ $t("today") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
    dateErrors: {
      type: Array,
      default: () => [],
    },
    beginDate: {
      type: [String, Date],
      default: () => null,
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    usePermission: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      menu: false,
      // localValue: this.value,
    };
  },
  watch: {
    // value(newValue) {
    //   console.log(newValue);
    //   // this.localValue = newValue;
    // },
  },
  computed: {
    // allowChangeDate() {
    //   return !this.usePermission || this.permissionChecker("admin")
    //     ? true
    //     : !this.permissionChecker("general-disallowchangingtodaydate");
    // },
    // maxAllowedDate() {
    //   return this.usePermission &&
    //     !this.permissionChecker("admin") &&
    //     this.permissionChecker("general-disallowmovementaftertoday")
    //     ? this.today()
    //     : null;
    // },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
      this.menu = false;
    },
    setToday() {
      // const today = new Date();
      this.updateValue(this.today());
    },
    setBeginingDate() {
      this.updateValue(this.beginDate);
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${day}-${month}-${year}`;
    // },
    // formatDate(date) {
    //   if (!date) return "";
    //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    //   return new Intl.DateTimeFormat("en-GB", options).format(new Date(date));
    // },
  },
};
</script>

<style lang="scss">
// .theme--light .v-text-field--filled .v-input__control {
//   background-color: white !important;
// }
</style>
