import baseUrl from "@/constants/API";

export const TransferDetailAPI = {
  deleteTransferDetails(data) {
    return axios.delete(baseUrl() + `/transferdetails/delete/${data}`);
  },

  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/transferdetails/checkdelete`, data);
  },
};
