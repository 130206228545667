import * as APIs from "@/helpers/Apis/Charts/SaleCharts";
import i18n from "@/plugins/i18n/i18n";

const state = {
  dayChart: {
    labels: [],
    data: [],
  },
  monthChart: {
    labels: [],
    data: [],
  },

  isDayChartLoaded: true,
  isMonthChartLoaded: true,
  errors: [],
};

const getters = {
  isDayChartLoaded(state) {
    return state.isDayChartLoaded;
  },
  isMonthChartLoaded(state) {
    return state.isMonthChartLoaded;
  },
  getChartDayData(state) {
    return state.dayChart;
  },
  getChartMonthData(state) {
    return state.monthChart;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDayChartLoaded: (state, payload) => {
    state.isDayChartLoaded = payload;
  },
  setIsMonthChartLoaded: (state, payload) => {
    state.isMonthChartLoaded = payload;
  },
  setDayChart: (state, payload) => {
    let array = payload;
    const dates = array.map((obj) => obj.sale_date);
    const totals = array.map((obj) => obj.total_sales);
    state.dayChart.labels = dates;
    state.dayChart.data = totals;
  },
  setMonthChart: (state, payload) => {
    let array = payload;
    const dates = array.map((obj) => obj.sale_month);
    const totals = array.map((obj) => obj.total_sales);
    state.monthChart.labels = dates;
    state.monthChart.data = totals;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  salesTotalsPerDay: ({ commit }) => {
    commit("setIsDayChartLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.salesTotalsPerDay()
        .then((res) => {
          commit("setDayChart", res.data);
          commit("setIsDayChartLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDayChartLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
  salesTotalsPerMonth: ({ commit }) => {
    commit("setIsMonthChartLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.salesTotalsPerMonth()
        .then((res) => {
          commit("setMonthChart", res.data);
          commit("setIsMonthChartLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMonthChartLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
