import * as APIs from "@/helpers/Apis/Productions/ProductionProportions/ProductionProportions";
import i18n from "@/plugins/i18n/i18n";

const state = {
  productionproportions: [],
  errors: [],
  isProductionProportionsLoaded: true,

  currentPage: null,
};

const getters = {
  isProductionProportionsLoaded(state) {
    return state.isProductionProportionsLoaded;
  },
  getProductionProportions(state) {
    return state.productionproportions;
  },
  getProductionProportionErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsProductionProportionsLoaded: (state, payload) => {
    state.isProductionProportionsLoaded = payload;
  },
  setProductionProportions: (state, payload) => {
    state.productionproportions = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  AddProductProportion: (state, payload) => {
    state.productionproportions.data.push(payload);
  },
  UpdateProductProportion: (state, payload) => {
    let productionProportionIndex = state.productionproportions.data.findIndex(
      (o) => o.id === payload.id
    );
    state.productionproportions.data[productionProportionIndex] = payload;
    state.productionproportions.data = JSON.parse(JSON.stringify(state.productionproportions.data));
  },
  deleteProductionProportion(state, payload) {
    let productionProportionIndex = state.productionproportions.data.findIndex(
      (o) => o.id === payload
    );
    if (productionProportionIndex !== -1) {
      state.productionproportions.data.splice(productionProportionIndex, 1);
    }
  },

  setCurrentPage(state, payload) {
    state.productionproportions.meta.current_page = payload.value;
  },
};

const actions = {
  ProductionProportions: ({ commit }, data) => {
    commit("setIsProductionProportionsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.ProductionProportions(data)
        .then((res) => {
          commit("setProductionProportions", res.data);
          commit("setIsProductionProportionsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createProductionProportion: ({ commit }, data) => {
    commit("setIsProductionProportionsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createProductionProportion(data)
        .then((res) => {
          commit("setIsProductionProportionsLoaded", true);
          commit("setErrors", "");

          commit("AddProductProportion", res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsProductionProportionsLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateProductionProportion: ({ commit }, data) => {
    commit("setIsProductionProportionsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateProductionProportion(data)
        .then((res) => {
          commit("setIsProductionProportionsLoaded", true);
          commit("setErrors", "");
          commit("UpdateProductProportion", res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsProductionProportionsLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteProductionProportion: ({ commit }, id) => {
    commit("setIsProductionProportionsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteProductionProportion(id)
        .then((res) => {
          commit("setIsProductionProportionsLoaded", true);
          commit("setErrors", "");

          commit("deleteProductionProportion", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionProportionsLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
