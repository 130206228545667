import iziToast from "izitoast"; // https://github.com/dolce/iziToast
import "izitoast/dist/css/iziToast.min.css";
import store from "../store";
let lang = store.getters["App/getLanguage"];

iziToast.settings({
  timeout: 10000,
  // position: lang ? "topLeft" : "topRight",
  position: "topCenter",
  transitionIn: "fadeInUp",
  transitionOut: "fadeOut",
  transitionInMobile: "fadeInRight",
  transitionOutMobile: "fadeOutLeft",
  // zindex: 999,
  // drag: false,
  // theme: "light", // dark
  // displayMode: "once",
  // displayMode: "replace", // to replace everytime the error comes
  // balloon: true,
});
window.iziToast = iziToast;
