import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  entrydetails: [],
  isEntryDetailsLoaded: true,
  errors: [],
};

const getters = {
  getEntryDetails(state) {
    return state.entrydetails;
  },

  isEntryDetailsLoaded(state) {
    return state.isEntryDetailsLoaded;
  },
  getEntryDetailsErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setEntryDetails: (state, payload) => {
    state.entrydetails = payload;
  },

  setIsEntryDetailsLoaded: (state, payload) => {
    state.isEntryDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  invoiceEntries: ({ commit }, data) => {
    commit("setIsEntryDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      EntryDetailAPI.invoiceEntries(data)
        .then((res) => {
          // commit("seInvoiceEntries", res.data.data);
          // seInvoiceEntriesExtra
          commit("setIsEntryDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntryDetailsLoaded", true);
          reject(error);
        });
    });
  },

  deleteEntryDetail: ({ commit }, id) => {
    commit("setIsEntryDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      EntryDetailAPI.deleteEntryDetail(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntryDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntryDetailsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
