import { StoreOutDetailAPI } from "@/helpers/Apis/Invoices/StoreInOut/StoreOutDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isStoreOutDetailsLoaded: true,
};

const getters = {
  isStoreOutDetailsLoaded(state) {
    return state.isStoreOutDetailsLoaded;
  },
  getStoreOutDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsStoreOutDetailsLoaded: (state, payload) => {
    state.isStoreOutDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  deleteStoreOutDetail: ({ commit }, id) => {
    commit("setIsStoreOutDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      StoreOutDetailAPI.deleteStoreOutDetail(id)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
          }
          commit("setIsStoreOutDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreOutDetailsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
