import * as APIs from "@/helpers/Apis/Maintenance/MaintenanceClients";
const state = {
  maintenanceclients: [],
  errors: [],
  isMaintenanceClientLoaded: true,
};

const getters = {
  getMaintenanceClients(state) {
    return state.maintenanceclients;
  },
  isMaintenanceClientLoaded(state) {
    return state.isMaintenanceClientLoaded;
  },
  getMaintenanceErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsMaintenanceClientLoaded: (state, payload) => {
    state.isMaintenanceClientLoaded = payload;
  },
  setMaintenanceClients: (state, payload) => {
    state.maintenanceclients = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.maintenanceclients.meta.current_page = payload.value;
  },
  setNewUpdateClient(state, payload) {
    let clientIndex = state.maintenanceclients.data.findIndex((o) => o.id == payload.id);
    if (clientIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.maintenanceclients.data.splice(clientIndex, 1, { ...payload });
    } else {
      state.maintenanceclients.data.unshift(payload);
      state.maintenanceclients.total_count += 1;
    }

    let ClientsLength = state.maintenanceclients.data.length;
    if (ClientsLength > state.maintenanceclients.meta.per_page) {
      state.maintenanceclients.data.splice(ClientsLength - 1, 1);
    }
  },
  deleteClient(state, payload) {
    let clientIndex = state.maintenanceclients.data.findIndex((o) => o.id === payload);
    if (clientIndex !== -1) {
      state.maintenanceclients.data.splice(clientIndex, 1);
    }
  },
};

const actions = {
  fetchMaintenanceClients: ({ commit }, data) => {
    commit("setIsMaintenanceClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchMaintenanceClients(data)
        .then((res) => {
          commit("setMaintenanceClients", res.data);
          commit("setIsMaintenanceClientLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceClientLoaded", true);
          reject(error);
        });
    });
  },

  createMaintenanceClient: ({ commit }, data) => {
    commit("setIsMaintenanceClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createMaintenanceClient(data)
        .then((res) => {
          commit("setIsMaintenanceClientLoaded", true);
          if (!data.fastCreate) {
            commit("setNewUpdateClient", res.data.data);
          }
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceClientLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateMaintenanceClient: ({ commit }, data) => {
    commit("setIsMaintenanceClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateMaintenanceClient(data)
        .then((res) => {
          commit("setIsMaintenanceClientLoaded", true);
          commit("setNewUpdateClient", res.data.data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceClientLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteMaintenanceClient: ({ commit }, id) => {
    commit("setIsMaintenanceClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteMaintenanceClient(id)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteClient", id);
          commit("setIsMaintenanceClientLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceClientLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  getClientBalance: ({ commit }, id) => {
    commit("setIsMaintenanceClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getClientBalance(id)
        .then((res) => {
          commit("setIsMaintenanceClientLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsMaintenanceClientLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
