import baseUrl from "@/constants/API";

export const ReservationAPI = {
  fetchReservation(id) {
    return axios.get(baseUrl() + `/reservation/${id}`);
  },
  previousReservation(id) {
    return axios.get(baseUrl() + `/reservation/${id}/previous`);
  },
  nextReservation(id) {
    return axios.get(baseUrl() + `/reservation/${id}/next`);
  },
  createReservation(data) {
    return axios.post(baseUrl() + `/reservation/create`, data);
  },
  updateReservation(data) {
    return axios.post(baseUrl() + `/reservation/update/${data.id}`, data.data);
  },
  deleteReservation(id) {
    return axios.delete(baseUrl() + `/reservation/delete/${id}`);
  },

  fetchReservationDetails(invoice_id) {
    return axios.post(baseUrl() + `/reservation/details/${invoice_id}`);
  },

  convertToSale(data) {
    return axios.post(baseUrl() + `/reservation/converttosale/${data.reservationId}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/reservation/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/reservation/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/reservation/deleted/clear`, data);
  },
};
