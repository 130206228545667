import baseUrl from "@/constants/API";

export const StoreInAPI = {
  fetchStoreIn(id) {
    return axios.get(baseUrl() + `/storein/${id}`);
  },
  previousStoreIn(id) {
    return axios.get(baseUrl() + `/storein/${id}/previous`);
  },
  nextStoreIn(id) {
    return axios.get(baseUrl() + `/storein/${id}/next`);
  },
  fetchSearchStoreIns(data) {
    return axios.post(baseUrl() + `/storein/search?page=${data.pageNumber}`, data.data);
  },
  createStoreIn(data) {
    return axios.post(baseUrl() + `/storein/create`, data);
  },
  updateStoreIn(data) {
    return axios.post(baseUrl() + `/storein/update/${data.id}`, data.data);
  },
  deleteStoreIn(id) {
    return axios.delete(baseUrl() + `/storein/delete/${id}`);
  },
  searchStoreIn(data) {
    return axios.post(baseUrl() + `/storein/search/${data}`);
  },

  storeInDetails(invoice_id) {
    return axios.post(baseUrl() + `/storein/details/${invoice_id}`);
  },

  convertToPurchase(data) {
    return axios.post(baseUrl() + `/storein/converttopurchase/${data.storeInId}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/storein/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/storein/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/storein/deleted/clear`, data);
  },
};
