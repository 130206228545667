// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { ReservationAPI } from "@/helpers/Apis/Reservations/Reservations";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  reservation: {},

  errors: [],
  reservationDetailsErrors: [],
  isReservationsLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getReservation(state) {
    return state.reservation;
  },
  isReservationsLoaded(state) {
    return state.isReservationsLoaded;
  },
  getReservationErrors(state) {
    return state.errors;
  },
  getReservationDetailsErrors(state) {
    return state.reservationDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setReservation: (state, payload) => {
    state.reservation = payload;
  },

  setIsReservationsLoaded: (state, payload) => {
    state.isReservationsLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.reservation.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.reservation.invoice_entries_extra = [
      ...state.reservation.invoice_entries_extra,
      ...payload,
    ];
    let expenses = toFixedIfNecessary(
      state.reservation.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.reservation.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.reservation.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.reservation.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.reservation.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchReservation: ({ commit }, id) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.fetchReservation(id)
        .then((res) => {
          commit("setReservation", res.data.data);
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReservationsLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.previousReservation(id)
        .then((res) => {
          commit("setReservation", res.data.data);
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/reservations/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReservationsLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.nextReservation(id)
        .then((res) => {
          commit("setReservation", res.data.data);
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/reservations/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsReservationsLoaded", true);
          reject(error);
        });
    });
  },

  createReservation: ({ commit }, data) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.createReservation(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setReservation", invoice);
          router.push({
            path: `/reservations/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateReservation: ({ commit }, data) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.updateReservation(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setReservation", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteReservation: ({ commit }, data) => {
    commit("setIsReservationsLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReservationAPI.deleteReservation(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationsLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  convertToSale: ({ commit }, data) => {
    commit("setIsReservationsLoaded", false);
    return new Promise((resolve, reject) => {
      ReservationAPI.convertToSale(data)
        .then((res) => {
          commit("setIsReservationsLoaded", true);
          commit("setErrors", "");

          router.push({
            path: `/sales/${res.data.data}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.reservation;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data.entries);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
