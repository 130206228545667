import baseUrl from "@/constants/API";

export const RestaurantOrderReportAPI = {
  fetchRestaurantOrderSearch(data) {
    return axios.post(baseUrl() + `/restaurant/orders/get?page=${data.pageNumber}`, data.data);
  },

  fetchRestaurantOrderDetailSearch(data) {
    return axios.post(baseUrl() + `/restaurant/orders/get?page=${data.pageNumber}`, data.data);
  },

  fetchRestaurantOrderProfits(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/orderprofits?page=${data.pageNumber}`,
      data.data
    );
  },
  fetchRestaurantMealProfits(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/mealprofits?page=${data.pageNumber}`,
      data.data
    );
  },
  fetchRestaurantAddonProfits(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/addonprofits?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchRestaurantMealTransactions(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/mealtransactions?page=${data.pageNumber}`,
      data.data
    );
  },
  fetchRestaurantRecipeTransactions(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/recipetransactions?page=${data.pageNumber}`,
      data.data
    );
  },
  fetchRestaurantAddonTransactions(data) {
    return axios.post(
      baseUrl() + `/restaurant/orders/addontransactions?page=${data.pageNumber}`,
      data.data
    );
  },
};
