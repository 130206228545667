import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { RestaurantKitchenAPI } from "@/helpers/Apis/index";
import { Statuses } from "@/helpers/Constants/Restaurant";
import router from "@/router";

const state = {
  kitchenOrders: [],

  errors: [],
  isKitchenOrdersLoaded: true,
  channelName: null,
};

const getters = {
  isKitchenOrdersLoaded(state) {
    return state.isKitchenOrdersLoaded;
  },
  getKitchenOrders(state) {
    return state.kitchenOrders;
  },

  getKitchenOrderErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsKitchenOrdersLoaded: (state, payload) => {
    state.isKitchenOrdersLoaded = payload;
  },
  setKitchenOrders: (state, payload) => {
    state.kitchenOrders = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  updateOrderStatus: (state, payload) => {
    let restaurantOrder = state.kitchenOrders.find((o) => o.id == payload.order_id);
    restaurantOrder.status = payload.status_id;
    restaurantOrder.status_name = payload.status_name;

    if (
      payload.status_id == Statuses["Completed"].id ||
      payload.status_id == Statuses["Canceled"].id
    ) {
      let restaurantOrderIndex = state.kitchenOrders.findIndex((object) => {
        return object.id == payload.order_id;
      });
      state.kitchenOrders.splice(restaurantOrderIndex, 1);
    }
  },

  updateOrder: (state, payload) => {
    if (payload.status == Statuses["Completed"].id || payload.status == Statuses["Canceled"].id) {
      let restaurantOrderIndex = state.kitchenOrders.findIndex((object) => {
        return object.id == payload.id;
      });
      if (restaurantOrderIndex >= 0) {
        state.kitchenOrders.splice(restaurantOrderIndex, 1);
      }
      return;
    }

    let restaurantOrderIndex = state.kitchenOrders.findIndex((o) => o.id == payload.id);
    if (restaurantOrderIndex >= 0) {
      // restaurantOrder = payload;
      state.kitchenOrders.splice(restaurantOrderIndex, 1, { ...payload });
    } else {
      state.kitchenOrders.push(payload);
    }
  },
  deleteOrder: (state, payload) => {
    let restaurantOrderIndex = state.kitchenOrders.findIndex((object) => {
      return object.id == payload.id;
    });
    state.kitchenOrders.splice(restaurantOrderIndex, 1);
    return;
  },

  setChannelName: (state, payload) => {
    state.channelName = payload;
  },
};

const actions = {
  fetchRestaurantKitchenOrders: ({ commit, dispatch }, data) => {
    commit("setIsKitchenOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantKitchenAPI.fetchRestaurantKitchenOrders(data)
        .then((res) => {
          commit("setKitchenOrders", res.data.data);
          commit("setIsKitchenOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          dispatch("registerKitchenChannel", data);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsKitchenOrdersLoaded", true);
          reject(error);
        });
    });
  },
  setRestaurantOrderStatus: ({ commit }, data) => {
    commit("setIsKitchenOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantKitchenAPI.setRestaurantOrderStatus(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsKitchenOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          resolve(res);
        })
        .catch((error) => {
          commit("setIsKitchenOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  registerKitchenChannel: ({ rootGetters, commit, dispatch }, data) => {
    let Authenticated = rootGetters["User/getAuthentication"];
    let database = rootGetters["Company/getDatabase"];

    if (Authenticated) {
      dispatch("stopListeningToKitchenChannel");
      let channelName = database + "-" + data.branch_id + "-" + "kitchenorder";
      commit("setChannelName", channelName);

      Echo.channel(channelName).listen("KitchenMessage", (e) => {
        if (e.message == "orderDelete") {
          commit("deleteOrder", e.kitchen_order);
        } else {
          commit("updateOrder", e.kitchen_order);
        }
      });
    }
  },

  stopListeningToKitchenChannel: ({ state }) => {
    if (state.channelName) {
      Echo.leave(state.channelName);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
