import auth from "@/router/middleware/auth";
import admin from "@/router/middleware/admin";
import guest from "@/router/middleware/guest";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "user" */ `@containers/${folderName}/${view}.vue`);
}

const UserRoutes = [
  {
    path: "/login",
    name: "Login",
    component: lazyLoad("User", "Login"),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/userscontroller",
    name: "UsersController",
    component: lazyLoad("User", "UsersController"),
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/changedatabase",
    name: "ChangeDatabase",
    component: lazyLoad("User", "ChangeDatabase"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/userprofile",
    name: "UserProfile",
    component: lazyLoad("User", "UserProfile"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: '/a5/forgot',
  //   name: 'forgot',
  //   component: Forgot,
  //   beforeEnter(to, from, next) {
  //     if (store.getters['User/getAuthentication']) {
  //       next('/');
  //     } else {
  //       next();
  //     }
  //   }

  // },
  // {
  //   path: '/a5/reset/:token',
  //   name: 'reset',
  //   component: Reset,
  //   beforeEnter(to, from, next) {
  //     if (store.getters['User/getAuthentication']) {
  //       next('/');
  //     } else {
  //       next();
  //     }
  //   }
  // },
];

export default UserRoutes;
