import { CategoryAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  categories: [],
  errors: [],
  isCategoriesLoaded: false,
};

const getters = {
  isCategoriesLoaded(state) {
    return state.isCategoriesLoaded;
  },
  getCategories(state) {
    return state.categories;
  },
  getCategoryErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsCategoriesLoaded: (state, payload) => {
    state.isCategoriesLoaded = payload;
  },
  setCategories: (state, payload) => {
    state.categories = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setNewUpdateCategory(state, payload) {
    let categoryIndex = state.categories.findIndex((o) => o.id == payload.id);
    if (categoryIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.categories.splice(categoryIndex, 1, { ...payload });
    } else {
      state.categories.unshift(payload);
    }
  },
  deleteCategory(state, payload) {
    let categoryIndex = state.categories.findIndex((o) => o.id === payload);
    if (categoryIndex !== -1) {
      state.categories.splice(categoryIndex, 1);
    }
  },
};

const actions = {
  fetchCategories: ({ commit }) => {
    commit("setIsCategoriesLoaded", false);
    return new Promise((resolve, reject) => {
      CategoryAPI.fetchCategories()
        .then((res) => {
          commit("setCategories", res.data.data);
          commit("setIsCategoriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCategoriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createCategory: ({ commit }, data) => {
    commit("setIsCategoriesLoaded", false);
    return new Promise((resolve, reject) => {
      CategoryAPI.createCategory(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateCategory", res.data.data);
          commit("setIsCategoriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCategoriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateCategory: ({ commit }, data) => {
    commit("setIsCategoriesLoaded", false);
    return new Promise((resolve, reject) => {
      CategoryAPI.updateCategory(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateCategory", res.data.data);
          commit("setIsCategoriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCategoriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteCategory: ({ commit }, id) => {
    commit("setIsCategoriesLoaded", false);
    return new Promise((resolve, reject) => {
      CategoryAPI.deleteCategory(id)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteCategory", id);
          commit("setIsCategoriesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCategoriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
