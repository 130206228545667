import baseUrl from "@/constants/API";

const createMaintenanceQuestion = (data) => {
  return axios.post(baseUrl() + `/maintenancequestions/create`, data);
};

const updateMaintenanceQuestion = (data) => {
  return axios.put(baseUrl() + `/maintenancequestions/update/${data.id}`, data.data);
};

const deleteMaintenanceQuestion = (id) => {
  return axios.delete(baseUrl() + `/maintenancequestions/delete/${id}`);
};

const deleteMaintenanceQuestionAnswer = (id) => {
  return axios.delete(baseUrl() + `/maintenancequestionanswers/delete/${id}`);
};

export {
  createMaintenanceQuestion,
  updateMaintenanceQuestion,
  deleteMaintenanceQuestion,
  deleteMaintenanceQuestionAnswer,
};
