import { StoreLogAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  itemStores: [],
  isLogDataLoaded: true,
};

const getters = {
  isLogDataLoaded(state) {
    return state.isLogDataLoaded;
  },
  getItemStores(state) {
    return state.itemStores;
  },
};

const mutations = {
  setIsLogDataLoaded: (state, payload) => {
    state.isLogDataLoaded = payload;
  },
  setItemStore: (state, payload) => {
    state.itemStores = payload;
  },
};

const actions = {
  fetchStoreLog: ({ commit }, data) => {
    commit("setIsLogDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreLogAPI.fetchStoreLog(data)
        .then((res) => {
          commit("setIsLogDataLoaded", true);
          commit("setItemStore", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsLogDataLoaded", true);
          reject(error);
        });
    });
  },

  OutQtyChecker: ({ commit }, data) => {
    commit("setIsLogDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreLogAPI.outQtyChecker(data)
        .then((res) => {
          commit("setIsLogDataLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsLogDataLoaded", true);
          reject(error);
        });
    });
  },
  InQtyChecker: ({ commit }, data) => {
    commit("setIsLogDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreLogAPI.inQtyChecker(data)
        .then((res) => {
          commit("setIsLogDataLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsLogDataLoaded", true);
          reject(error);
        });
    });
  },

  TransferQtyCheck: ({ commit }, data) => {
    commit("setIsLogDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreLogAPI.transferQtyCheck(data)
        .then((res) => {
          commit("setIsLogDataLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsLogDataLoaded", true);
          reject(error);
        });
    });
  },

  reviewStoreLogs: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsLogDataLoaded", false);
    StoreLogAPI.reviewStoreLogs(data)
      .then((res) => {
        commit("App/setAppLoader", false, { root: true });
        commit("setIsLogDataLoaded", true);
      })
      .catch((error) => {
        commit("App/setAppLoader", false, { root: true });
        commit("setIsLogDataLoaded", true);

        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors).flat()
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
  ReviewFifo: ({ commit }) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsLogDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreLogAPI.ReviewFifo()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsLogDataLoaded", true);
          resolve();
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsLogDataLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
