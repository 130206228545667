import baseUrl from "@/constants/API";

export const OrderReportAPI = {
  SaleOrderSearch(data) {
    return axios.post(baseUrl() + `/saleorder/search?page=${data.pageNumber}`, data.data);
  },

  PurchaseOrderSearch(data) {
    return axios.post(baseUrl() + `/purchaseorder/search?page=${data.pageNumber}`, data.data);
  },
};
