import { NoteAccountAPI } from "@/helpers/Apis/index.js";
import i18n from "@/plugins/i18n/i18n";

const state = {
  customermainaccounts: [],
  payablenoteaccounts: [],
  receivablenoteaccounts: [],
  bankaccounts: [],

  isBankAccountsLoaded: true,
  isCustomerMainAccountsLoaded: true,
  isPayableNoteAccountsLoaded: true,
  isReceivableNoteAccountsLoaded: true,
};

const getters = {
  getCustomerMainAccounts(state) {
    return state.customermainaccounts;
  },
  getPayableNoteAccounts(state) {
    return state.payablenoteaccounts;
  },
  getReceivableNoteAccounts(state) {
    return state.receivablenoteaccounts;
  },
  getBankAccounts(state) {
    return state.bankaccounts;
  },

  getIsBankAccountsLoaded(state) {
    return state.isBankAccountsLoaded;
  },
  getIsCustomerMainAccountsLoaded(state) {
    return state.isCustomerMainAccountsLoaded;
  },
  getIsPayableNoteAccountsLoaded(state) {
    return state.isPayableNoteAccountsLoaded;
  },
  getIsReceivableNoteAccountsLoaded(state) {
    return state.isReceivableNoteAccountsLoaded;
  },
};

const mutations = {
  setCustomerMainAccounts: (state, payload) => {
    state.customermainaccounts = payload;
  },
  setPayableNoteAccounts: (state, payload) => {
    state.payablenoteaccounts = payload;
  },
  setReceivableNoteAccounts: (state, payload) => {
    state.receivablenoteaccounts = payload;
  },
  setBankAccounts: (state, payload) => {
    state.bankaccounts = payload;
  },

  setIsBankAccountsLoaded: (state, payload) => {
    state.isBankAccountsLoaded = payload;
  },
  setIsCustomerMainAccountsLoaded: (state, payload) => {
    state.isCustomerMainAccountsLoaded = payload;
  },
  setIsPayableNoteAccountsLoaded: (state, payload) => {
    state.isPayableNoteAccountsLoaded = payload;
  },
  setIsReceivableNoteAccountsLoaded: (state, payload) => {
    state.isReceivableNoteAccountsLoaded = payload;
  },
};

const actions = {
  fetchAllReceivableAcc: ({ state, commit }) => {
    // if (
    //   state.bankaccounts.length <= 0 ||
    //   state.receivablenoteaccounts <= 0 ||
    //   state.customermainaccounts <= 0
    // ) {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchAllReceivableAcc()
        .then((res) => {
          commit("setBankAccounts", Object.values(res.data.bank_accounts));
          commit("setCustomerMainAccounts", Object.values(res.data.customer_accounts));
          commit("setReceivableNoteAccounts", Object.values(res.data.note_accounts));
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          reject(error.response);
        });
    });
    // }
  },
  fetchAllPayableAcc: ({ state, commit }) => {
    // if (
    //   state.bankaccounts.length <= 0 ||
    //   state.payablenoteaccounts <= 0 ||
    //   state.customermainaccounts <= 0
    // ) {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchAllPayableAcc()
        .then((res) => {
          commit("setBankAccounts", Object.values(res.data.bank_accounts));
          commit("setCustomerMainAccounts", Object.values(res.data.customer_accounts));
          commit("setPayableNoteAccounts", Object.values(res.data.note_accounts));
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          reject(error.response);
        });
    });
    // }
  },

  fetchBankAccs: ({ commit }) => {
    commit("setIsBankAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchBankAccs()
        .then((res) => {
          commit("setBankAccounts", res.data);
          commit("setIsBankAccountsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBankAccountsLoaded", true);
          reject(error.response);
        });
    });
  },
  fetchCustomerMainAccs: ({ commit }) => {
    commit("setIsCustomerMainAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchCustomerMainAccs()
        .then((res) => {
          commit("setCustomerMainAccounts", res.data);
          commit("setIsCustomerMainAccountsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsCustomerMainAccountsLoaded", true);
          reject(error.response);
        });
    });
  },
  fetchPayableNoteAccounts: ({ commit }, data) => {
    commit("setIsPayableNoteAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchPayableNoteAccounts(data)
        .then((res) => {
          commit("setPayableNoteAccounts", res.data);
          commit("setIsPayableNoteAccountsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsPayableNoteAccountsLoaded", true);
          reject(error.response);
        });
    });
  },
  fetchReceivableNoteAccs: ({ commit }, data) => {
    commit("setIsReceivableNoteAccountsLoaded", false);
    return new Promise((resolve, reject) => {
      NoteAccountAPI.fetchReceivableNoteAccs(data)
        .then((res) => {
          commit("setReceivableNoteAccounts", res.data);
          commit("setIsReceivableNoteAccountsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsReceivableNoteAccountsLoaded", true);
          reject(error.response);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
