import { ItemExpireAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  expires: [],
  isDataLoaded: true,
  errors: [],
};

const getters = {
  getExpirations(state) {
    return state.expiration;
  },
  getIsExpiresLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setExpires: (state, payload) => {
    state.expiration = payload;
  },
  setIsExpireLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  ReviewExpireDates: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsExpireLoaded", false);
    return new Promise((resolve, reject) => {
      ItemExpireAPI.ReviewExpireDates(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsExpireLoaded", true);
          commit("setErrors", []);
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsExpireLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
