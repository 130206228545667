import Vue from "vue";
import ClientSupplierList from "@/global-components/ClientLists/ClientSupplierList.vue";

import ItemQty from "@/global-components/itemQty";
import ToolTip from "@/global-components/ToolTip.vue";
import DialogComponent from "@/global-components/DialogComponent.vue";
import Layout from "@/global-components/Layout.vue";

// Text Buttons and Btns
import BasicBtn from "@/global-components/Btns/BasicBtn.vue";
import BasicTextField from "@/global-components/Btns/BasicTextField.vue";
import MiniTextField from "@/global-components/Btns/MiniTextField.vue";
import textBox from "@/global-components/Btns/textBox.vue";

Vue.component("Layout", Layout);
Vue.component("DialogComponent", DialogComponent);
Vue.component("BasicTextField", BasicTextField);
Vue.component("MiniTextField", MiniTextField);
Vue.component("BasicBtn", BasicBtn);
Vue.component("textBox", textBox);
Vue.component("ClientSupplierList", ClientSupplierList);
Vue.component("ItemQty", ItemQty);
Vue.component("ToolTip", ToolTip);

import DatePicker from "@/global-components/Btns/DatePicker.vue";
Vue.component("DatePicker", DatePicker);

import SelectHeaders from "@/global-components/SelectHeaders/SelectHeaders.vue";
Vue.component("SelectHeaders", SelectHeaders);

// Select Btns
import GlobalAutoComplete from "@/global-components/SelectBtns/AutoCompleteBtn.vue";
import VSelectBtn from "@/global-components/SelectBtns/VSelectBtn.vue";
import AutoCompleteInfinite from "@/global-components/SelectBtns/AutoCompleteInfinite.vue";
Vue.component("GlobalAutoComplete", GlobalAutoComplete);
Vue.component("VSelectBtn", VSelectBtn);
Vue.component("AutoCompleteInfinite", AutoCompleteInfinite);

import DataTable from "@/global-components/DataTables/DataTable.vue";
Vue.component("DataTable", DataTable);
