<template>
  <v-speed-dial
    v-if="filteredLinks.length > 0"
    class="d-print-none"
    v-model="speedDial"
    :bottom="true"
    :right="getLanguage"
    :left="!getLanguage"
    direction="top"
    :open-on-hover="false"
    fixed
    transition="slide-y-reverse-transition"
  >
    <!-- :location="!getLanguage ? 'left bottom ' : 'right bottom'" -->
    <template v-slot:activator>
      <v-btn class="white--text" v-model="speedDial" color="blue darken-2" fab>
        <v-icon v-if="speedDial"> mdi-close </v-icon>
        <v-icon v-else> mdi-account-circle </v-icon>
      </v-btn>
    </template>
    <!-- <v-btn v-for="data in filteredLinks" :key="data.name" :to="data.path">
      {{ data.name }}
    </v-btn> -->

    <v-tooltip
      v-for="data in filteredLinks"
      :key="data.name"
      :left="getLanguage"
      :right="!getLanguage"
      color="toolTipBgColor"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="white--text"
          :to="data.path"
          fab
          small
          color="#3949AB"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ data.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t(data.name) }}</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
export default {
  props: [],
  data: () => ({
    speedDial: false,
    menu: false,
  }),
  computed: {
    links() {
      return [
        {
          path: "/sales",
          icon: "mdi-book-minus-multiple",
          name: "sale",
          rules: this.permissionChecker("sale-view"),
        },
        {
          path: "/purchases",
          icon: "mdi-book-plus-multiple",
          name: "purchase",
          rules: this.permissionChecker("purchase-view"),
        },
        {
          path: "/entriesreport",
          icon: "mdi-file-search",
          name: "entriesreport",
          rules: this.permissionChecker("entry-searchdetail"),
        },
        {
          path: "/draweraction",
          icon: "mdi-bank-transfer",
          name: "draweraction",
          rules:
            this.permissionChecker("draweraction-deposit") ||
            this.permissionChecker("draweraction-withdraw") ||
            this.permissionChecker("draweraction-cashtransfer"),
        },
        {
          path: "/storelog",
          icon: "mdi-store-search",
          name: "itemstock",
          rules: this.permissionChecker("store-viewqty"),
        },
      ];
    },

    filteredLinks() {
      if (this.getUser.quick_links) {
        return this.getUser.quick_links;
      }

      return this.links.filter((link) => {
        return link.rules == true;
      });
    },
  },
};
</script>

<style scoped></style>
