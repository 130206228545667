import { EmployeeAPI } from "@/helpers/Apis/index";
// import i18n from "@/plugins/i18n/i18n";

const state = {
  employees: [],
  isEmployeesLoaded: true,
  errors: [],

  salesAgents: [],
  isSalesAgentsLoaded: true,

  couriers: [],
  isCouriersLoaded: true,

  clientEmployees: [],

  currentPage: null,
};

const getters = {
  getEmployees(state) {
    return state.employees;
  },
  isEmployeesLoaded(state) {
    return state.isEmployeesLoaded;
  },
  getEmployeeErrors(state) {
    return state.errors;
  },

  getSalesAgents(state) {
    return state.salesAgents;
  },
  isSalesAgentsLoaded(state) {
    return state.isSalesAgentsLoaded;
  },

  getCouriers(state) {
    return state.couriers;
  },
  isCouriersLoaded(state) {
    return state.isCouriersLoaded;
  },

  getClientEmployees(state) {
    return state.clientEmployees;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsEmployeesLoaded: (state, payload) => {
    state.isEmployeesLoaded = payload;
  },

  setSalesAgents: (state, payload) => {
    state.salesAgents = payload;
  },
  setIsSalesAgentsLoaded: (state, payload) => {
    state.isSalesAgentsLoaded = payload;
  },

  setCouriers: (state, payload) => {
    state.couriers = payload;
  },
  setIsCouriersLoaded: (state, payload) => {
    state.isCouriersLoaded = payload;
  },

  setEmployees: (state, payload) => {
    state.employees = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.employees.meta.current_page = payload.value;
  },

  setClientEmployees: (state, payload) => {
    state.clientEmployees = payload;
  },

  setNewUpdateEmployee(state, payload) {
    let employeeIndex = state.employees.data.findIndex((o) => o.id === payload.id);
    if (employeeIndex !== -1) {
      // state.employees.data[employeeIndex] = payload;
      state.employees.data.splice(employeeIndex, 1, { ...payload });
    } else {
      state.employees.data.unshift(payload);
    }

    ////
    let user = JSON.parse(localStorage.getItem("user"));
    let storedJobs = user?.accounts?.database_defaults
      .filter((o) => o.name === "employee_courier_job" || o.name === "employee_sales_job")
      .map((job) => job.account_id);

    if (!storedJobs.includes(payload.employee_job_id)) {
      return;
    }

    const userBranchExists =
      user.branches.findIndex((branch) => branch.id === payload.branch_id) >= 0;
    const shouldRemoveEmployee = payload.hidden || !userBranchExists;

    const userEmployeeIndex = user.employees.findIndex((emp) => emp.id === payload.id);

    if (shouldRemoveEmployee) {
      if (userEmployeeIndex !== -1) {
        user.employees.splice(userEmployeeIndex, 1); // Remove Employee
      }
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      const employeeObject = {
        id: payload.id,
        employee_job_id: payload.employee_job_id,
        name: payload.name,
        branch_id: payload.branch_id,
        store: payload.store,
      };

      if (userEmployeeIndex !== -1) {
        user.employees[userEmployeeIndex] = employeeObject;
      } else {
        user.employees.push(employeeObject);
      }
      localStorage.setItem("user", JSON.stringify(user)); // Update local storage
    }
  },
  deleteEmployee(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    let userEmployeeIndex = user.employees.findIndex((o) => o.id === payload);
    if (userEmployeeIndex !== -1) {
      user.employees.splice(userEmployeeIndex, 1); // Remove Employee
    }
    localStorage.setItem("user", JSON.stringify(user));

    //////
    let employeeIndex = state.employees.data.findIndex((o) => o.id === payload);
    if (employeeIndex !== -1) {
      state.employees.data.splice(employeeIndex, 1);
    }
  },
};

const actions = {
  fetchEmployees: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchEmployees(data)
        .then((res) => {
          commit("setEmployees", res.data);
          commit("setIsEmployeesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },
  fetchBasicEmployees: ({ commit }, branchId) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchBasicEmployees(branchId)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicSalesAgents: ({ commit }, branchId) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchBasicSalesAgents(branchId)
        .then((res) => {
          commit("setSalesAgents", res.data);
          commit("setIsEmployeesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicCouriers: ({ commit }, branchId) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchBasicCouriers(branchId)
        .then((res) => {
          commit("setCouriers", res.data);
          commit("setIsEmployeesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },

  fetchEmployeesWithEntries: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchEmployeesWithEntries(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },

  createEmployee: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.createEmployee(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateEmployee", res.data.data);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          console.log(error);

          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateEmployee: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.updateEmployee(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateEmployee", res.data.data);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteEmployee: ({ commit }, id) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.deleteEmployee(id)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("deleteEmployee", id);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchEmployeeLoans: ({ commit }, id) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchEmployeeLoans(id)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  giveSalary: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.giveSalary(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  giveLoan: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.giveLoan(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  clientEmployees: ({ commit }, account) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.clientEmployees(account)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          commit("setClientEmployees", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  clientEmployee: ({ commit }, account) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.clientEmployee(account)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
