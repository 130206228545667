import baseUrl from "@/constants/API";

export const CustomerListAPI = {
  fetchClientsList(data) {
    return axios.post(baseUrl() + `/customerlist/clients?page=${data.pageNumber}`, data.data);
  },

  fetchSuppliersList(data) {
    return axios.post(baseUrl() + `/customerlist/suppliers?page=${data.pageNumber}`, data.data);
  },

  fetchEmployeesList(data) {
    return axios.post(baseUrl() + `/customerlist/employees?page=${data.pageNumber}`, data.data);
  },

  fetchMaintenanceClientsList(data) {
    return axios.post(
      baseUrl() + `/customerlist/maintenanceclients?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchCleanningClientsList(data) {
    return axios.post(
      baseUrl() + `/customerlist/cleanningclients?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchCustomerBalance(data) {
    return axios.get(baseUrl() + `/customerlist/balance/${data.part_id}/${data.part_type_id}`);
  },

  fetchClient(data) {
    return axios.get(baseUrl() + `/customerlist/client/${data.id}`, {
      params: data.data,
    });
  },
  fetchSupplier(data) {
    return axios.get(baseUrl() + `/customerlist/supplier/${data.id}`, {
      params: data.data,
    });
  },
  fetchEmployee(data) {
    return axios.get(baseUrl() + `/customerlist/employee/${data.id}`, {
      params: data.data,
    });
  },
  fetchMaintenance(data) {
    return axios.get(baseUrl() + `/customerlist/maintenance/${data.id}`, {
      params: data.data,
    });
  },
  fetchCleanning(data) {
    return axios.get(baseUrl() + `/customerlist/cleanning/${data.id}`, {
      params: data.data,
    });
  },
};
