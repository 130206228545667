<template>
  <v-btn v-on="$listeners" v-bind="{ ...$attrs, ...$props }" small>
    <span>
      <slot name="iconSlot"></slot>
      {{ name }}
    </span>
  </v-btn>
</template>

<script>
export default {
  props: ["name"],
  methods: {},
};
</script>

<style></style>
