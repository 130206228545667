import baseUrl from "@/constants/API";

const fetchClassifications = () => {
  return axios.get(baseUrl() + "/clientclassifications/all");
};
const fetchClassification = (id) => {
  return axios.get(baseUrl() + `/clientclassifications/single/${id}`);
};
const createClassification = (data) => {
  return axios.post(baseUrl() + `/clientclassifications/create`, data);
};
const updateClassification = (data) => {
  return axios.post(baseUrl() + `/clientclassifications/update/${data.id}`, data.data);
};
const deleteClassification = (id) => {
  return axios.delete(baseUrl() + `/clientclassifications/delete/${id}`);
};
const fetchSearchClassifications = (data) => {
  return axios.post(baseUrl() + `/clientclassifications/search/${data}`);
};

export {
  fetchClassifications,
  fetchClassification,
  createClassification,
  updateClassification,
  deleteClassification,
  fetchSearchClassifications,
};
