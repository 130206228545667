const CompanyPermissions = {
  sale_management: "sale_management",
  purchase_management: "purchase_management",
  maintenance_management: "maintenance_management",
  cleanning_management: "cleanning_management",
  production_management: "production_management",
  restaurant_management: "restaurant_management",
};

const level1 = [];
const level2 = [
  ...level1,
  "storeinout_management",
  "notes_management",
  "store_transfer_management",
];
const level3 = [
  ...level2,
  "order_management", // saleorder purchaseorder
  "reservation_management",
  "advanced_excel_management",
];

const LevelPermissions = {
  1: [],
  2: level2,
  3: level3,
};

export { CompanyPermissions, LevelPermissions };
