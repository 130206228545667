import baseUrl from "@/constants/API";

export const FixedAssetAPI = {
  fetchFixedAssets(data) {
    return axios.post(baseUrl() + `/fixedassets/get?page=${data.pageNumber}`, data.data);
  },

  fetchFixedAsset(id) {
    return axios.get(baseUrl() + `/fixedassets/${id}`);
  },

  createFixedAsset(data) {
    return axios.post(baseUrl() + `/fixedassets/create`, data);
  },
  updateFixedAsset(data) {
    return axios.post(baseUrl() + `/fixedassets/update/${data.id}`, data.data);
  },
  deleteFixedAsset(data) {
    return axios.delete(baseUrl() + `/fixedassets/delete/${data}`);
  },

  fetchDepreciations(data) {
    return axios.post(baseUrl() + `/fixedassets/getdepreciation`, data);
  },
  saveDepreciations(data) {
    return axios.post(baseUrl() + `/fixedassets/savedepreciation`, data);
  },
};
