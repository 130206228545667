import { CashierAPI } from "@/helpers/Apis/Invoices/Cashier/Cashier";
import { ItemService } from "@/helpers/IndexDB/CashierItems";
import { CategoryService } from "@/helpers/IndexDB/CashierCategories";

const state = {
  categories: [],

  items: [],
  isItemsLoaded: false,
};

const getters = {
  getItems(state) {
    return state.items;
  },
  getCategories(state) {
    return state.categories;
  },

  isCashierItemsLoaded(state) {
    return state.isItemsLoaded;
  },
};

const mutations = {
  setCashierItems: (state, payload) => {
    state.items = payload;
  },
  setCashierCategories: (state, payload) => {
    state.categories = payload;
  },
  clearCashierCategories: (state) => {
    state.categories = [];
  },

  setIsCashierItemsLoaded: (state, payload) => {
    state.isItemsLoaded = payload;
  },
};

const actions = {
  fetchItems: async ({ dispatch, commit }) => {
    commit("setIsCashierItemsLoaded", false);
    // Fetch items from ItemService
    let items = await new ItemService().getItems();
    if (items.length > 0) {
      commit("setCashierItems", items);
      await dispatch("updateCategoriesFromItems", items);
      commit("setIsCashierItemsLoaded", true);
      return;
    }
    // Fetch items from API
    CashierAPI.fetchItems()
      .then(async (res) => {
        const items = res.data;
        commit("setCashierItems", items);
        await dispatch("updateCategoriesFromItems", items);
        commit("setIsCashierItemsLoaded", true);
        dispatch("setItems", items);
      })
      .catch((error) => {
        commit("setIsCashierItemsLoaded", true);
      });
  },

  reloadItems: async ({ dispatch, commit }) => {
    commit("setIsCashierItemsLoaded", false);

    try {
      const res = await CashierAPI.fetchItems();
      const items = res.data;
      commit("setCashierItems", items);
      await dispatch("updateCategoriesFromItems", items);
      commit("setIsCashierItemsLoaded", true);
      dispatch("setItems", items);
    } catch (error) {
      commit("setIsCashierItemsLoaded", true);
    }
  },

  async refreshItems({ commit, dispatch }) {
    let items = await new ItemService().getItems();
    commit("setCashierItems", items);
    await dispatch("updateCategoriesFromItems", items);
  },

  async clearData({ commit, dispatch }) {
    // await new ItemService().clearItems();
    // let items = await new ItemService().getItems();
    // commit("setCashierItems", items);

    await new ItemService().clearItems();
    await new CategoryService().clearCategories();
    commit("clearCashierCategories");
    dispatch("refreshItems");
  },

  async addItem({ dispatch }, item) {
    await new ItemService().addItem(item);
    dispatch("refreshItems");
  },

  async setItems({ dispatch }, items) {
    dispatch("clearData");
    await new ItemService().setItems(items);
    dispatch("refreshItems");
  },

  async deleteItem({ dispatch }, id) {
    await new ItemService().deleteItem(id);
    dispatch("refreshItems");
  },

  async updateItem({ dispatch }, item) {
    await new ItemService().updateItemById(item);
    dispatch("refreshItems");
  },

  async updateCategoriesFromItems({ commit }, items) {
    try {
      // Clear existing categories in state and IndexedDB
      commit("clearCashierCategories");
      await new CategoryService().clearCategories();

      // Extract unique categories from items
      const categoriesMap = new Map();
      items.forEach((item) => {
        if (item.category && item.category.id) {
          categoriesMap.set(item.category.id, item.category);
        }
      });

      const uniqueCategories = Array.from(categoriesMap.values());

      // Store unique categories in IndexedDB and commit them to the state
      await new CategoryService().setCategories(uniqueCategories);
      commit("setCashierCategories", uniqueCategories);
    } catch (error) {
      console.error("Error storing categories in IndexedDB:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
