import { DefaultAccsAPI } from "@/helpers/Apis/DefaultAcc";
import i18n from "@/plugins/i18n/i18n";

const state = {
  defaultaccs: [],

  errors: [],
  isDefaultAccsLoaded: false,

  showsin: [],
  isShowsInLoaded: false,

  acctypes: [],
  isAccTypesLoaded: false,
};

const getters = {
  isDefaultAccsLoaded(state) {
    return state.isDefaultAccsLoaded;
  },
  getDefaultAccs(state) {
    return state.defaultaccs;
  },
  getDefaultAccErrors(state) {
    return state.errors;
  },
  ////
  isShowsInLoaded(state) {
    return state.isShowsInLoaded;
  },
  getShowsIn(state) {
    return state.showsin;
  },
  ////
  isAccTypesLoaded(state) {
    return state.isAccTypesLoaded;
  },
  getAccTypes(state) {
    return state.acctypes;
  },
};

const mutations = {
  setIsDefaultAccsLoaded: (state, payload) => {
    state.isDefaultAccsLoaded = payload;
  },
  setDefaultAccs: (state, payload) => {
    state.defaultaccs = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  ////
  setIsShowsInLoaded: (state, payload) => {
    state.isShowsInLoaded = payload;
  },
  setShowsIn: (state, payload) => {
    state.showsin = payload;
  },
  ////
  setIsAccTypesLoaded: (state, payload) => {
    state.isAccTypesLoaded = payload;
  },
  setAccTypes: (state, payload) => {
    state.acctypes = payload;
  },
};

const actions = {
  fetchDefaultAccs: ({ commit }) => {
    commit("setIsDefaultAccsLoaded", false);
    return new Promise((resolve, reject) => {
      DefaultAccsAPI.fetchDefaultAccs()
        .then((res) => {
          commit("setDefaultAccs", res.data);
          commit("setIsDefaultAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDefaultAccsLoaded", true);
          reject(error);
        });
    });
  },

  fetchShowsIn: ({ commit }) => {
    commit("setIsShowsInLoaded", false);
    return new Promise((resolve, reject) => {
      DefaultAccsAPI.fetchShowsIn()
        .then((res) => {
          commit("setShowsIn", res.data);
          commit("setIsShowsInLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsShowsInLoaded", true);
          reject(error);
        });
    });
  },

  fetchAccTypes: ({ commit }) => {
    commit("setIsAccTypesLoaded", false);
    return new Promise((resolve, reject) => {
      DefaultAccsAPI.fetchAccTypes()
        .then((res) => {
          commit("setAccTypes", res.data);
          commit("setIsAccTypesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsAccTypesLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
