import baseUrl from "@/constants/API";

export const StateAPI = {
  fetchStatesBasic() {
    return axios.get(baseUrl() + `/states/statesbasic`);
  },

  fetchStates() {
    return axios.get(baseUrl() + `/states/get`);
  },

  createState(data) {
    return axios.post(baseUrl() + `/states/create`, data);
  },

  updateState(data) {
    return axios.post(baseUrl() + `/states/update/${data.id}`, data.data);
  },

  deleteState(id) {
    return axios.delete(baseUrl() + `/states/delete/${id}`);
  },
};
