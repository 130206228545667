// Importing requirement
import store from "@/store";
import i18n from "@/plugins/i18n/i18n";

/**
 * Redirect to home page if not logged in and trying to access a restricted page.
 *
 * @param next
 * @param router
 */
export default function auth({ next, router }) {
  if (!store.getters["User/getAuthentication"]) {
    console.log("Auth Middleware: Auth Failed.");
    iziToast.error({
      message: i18n.t("required.login"),
      type: "error",
    });
    next({ name: "Login", replace: true });
    return;
  }
  //  else if (store.getters["User/getAuthentication"]) {
  //   next({ name: "Login", replace: true });
  //   this.$store.commit("User/logout");
  //   return;
  // }

  next();
}
