<template>
  <v-autocomplete
    :item-text="item_text ? item_text : 'name'"
    :item-value="item_value ? item_value : 'id'"
    :items="data"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    dense
    outlined
    filled
    :loading="dataLoading"
    hide-details="auto"
  >
    <template #append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index < 2" class="v-autocomplete__selection-text">
        {{ item_text ? item[item_text] : item.name }}
        <span v-if="index < 2 && Array.isArray(value) && value.length > 1"
          >, &nbsp;
        </span>
      </span>
      <span v-else-if="index === 2"> ( +{{ value.length - 2 }} )</span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: ["value", "data", "dataLoading", "item_text", "item_value"],
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.theme--light .v-text-field--filled .v-input__control .v-input__slot {
  background-color: white !important;
}

.v-autocomplete__selection-text {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-input__append-outer {
  margin: 0 !important;
  padding: 0 !important;
}
.v-input__prepend-inner {
  margin: 0 !important;
  padding: 0 !important;
  // margin-inline-start:-10px!important;
}
</style>
