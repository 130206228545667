import baseUrl from "@/constants/API";

export const DatabaseSettingAPI = {
  getDatabaseSettings() {
    return axios.get(baseUrl() + `/database/getsettings`);
  },

  updateDatabaseSettings(data) {
    return axios.post(baseUrl() + `/database/update`, data);
  },
  clearCache() {
    return axios.post(baseUrl() + "/database/clearcache");
  },
};
