import baseUrl from "@/constants/API";

const fetchSearchClients = (data) => {
  return axios.post(baseUrl() + `/clientoptions/get?page=${data.pageNumber}`, data.data);
};
const salesAgentsTransfer = (data) => {
  return axios.post(baseUrl() + `/clientoptions/submittransfers`, data);
};

export { fetchSearchClients, salesAgentsTransfer };
