import { MainAccountAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  mainaccs: [],
  mainAccSelected: [],
  errors: [],
  isMainAccsLoaded: true,

  userMainAccs: [],
  basicMainAccs: [],
};

const getters = {
  isMainAccsLoaded(state) {
    return state.isMainAccsLoaded;
  },
  getMainAccs(state) {
    return state.mainaccs;
  },

  getUserMainAccs(state) {
    return state.userMainAccs;
  },

  getBasicMainAccs(state) {
    return state.basicMainAccs;
  },
  getMainAccErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsMainAccsLoaded: (state, payload) => {
    state.isMainAccsLoaded = payload;
  },
  setMainAccs: (state, payload) => {
    state.mainaccs = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.mainaccs.meta.current_page = payload;
  },

  setUserMainAccs: (state, payload) => {
    state.userMainAccs = payload;
  },

  setBasicMainAccs: (state, payload) => {
    state.basicMainAccs = payload;
  },

  setNewUpdateMainAcc(state, payload) {
    let mainAccIndex = state.mainaccs.findIndex((o) => o.id == payload.id);
    if (mainAccIndex !== -1) {
      state.mainaccs.splice(mainAccIndex, 1, { ...payload });
    } else {
      state.mainaccs.unshift(payload);
      // state.mainaccs.total_count += 1;
    }
  },
  deleteMainAcc(state, payload) {
    let mainAccIndex = state.mainaccs.findIndex((o) => o.id === payload);
    if (mainAccIndex !== -1) {
      state.mainaccs.splice(mainAccIndex, 1);
    }
  },
};

const actions = {
  fetchMainAccs: ({ commit }, pageNumber) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchMainAccs(pageNumber)
        .then((res) => {
          commit("setMainAccs", res.data.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicMainAcc: ({ commit }, data) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchBasicMainAcc(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setBasicMainAccs", res.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchUserMainAccs: ({ commit }, data) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchUserMainAccs(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setUserMainAccs", res.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchDrawerActionMainAccs: ({ commit }) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchDrawerActionMainAccs()
        .then((res) => {
          commit("setMainAccs", res.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          reject(error);
        });
    });
  },

  createMainAcc: ({ commit }, data) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.createMainAcc(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateMainAcc", res.data.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateMainAcc: ({ commit }, data) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.updateMainAcc(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateMainAcc", res.data.data);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteMainAcc: ({ commit }, id) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.deleteMainAcc(id)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteClient", id);
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchNotesMainAccs: ({ commit }, data) => {
    commit("setIsMainAccsLoaded", false);
    return new Promise((resolve, reject) => {
      MainAccountAPI.fetchNotesMainAccs(data)
        .then((res) => {
          commit("setIsMainAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMainAccsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
