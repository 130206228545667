import baseUrl from "@/constants/API";

export const DefaultAccsAPI = {
  fetchDefaultAccs() {
    return axios.get(baseUrl() + "/defaultacc");
  },

  fetchAccTypes() {
    return axios.get(baseUrl() + "/acctype");
  },

  fetchShowsIn() {
    return axios.get(baseUrl() + "/showsin");
  },
};
