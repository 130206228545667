import { ItemExpireAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  itemExpires: [],
  isDataLoaded: true,
  errors: [],
};

const getters = {
  getItemExpires(state) {
    return state.itemExpires;
  },
  getIsExpiresLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setItemExpires: (state, payload) => {
    state.itemExpires = payload;
  },
  setIsExpireLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchAvailableExpires: ({ commit }, data) => {
    commit("setIsExpireLoaded", false);
    return new Promise((resolve, reject) => {
      ItemExpireAPI.fetchAvailableExpires(data)
        .then((res) => {
          commit("setIsExpireLoaded", true);
          commit("setItemExpires", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsExpireLoaded", true);
          reject(error);
        });
    });
  },

  fetchAvailableExpiresRelated: ({ commit }, data) => {
    commit("setIsExpireLoaded", false);
    return new Promise((resolve, reject) => {
      ItemExpireAPI.fetchAvailableExpiresRelated(data)
        .then((res) => {
          commit("setIsExpireLoaded", true);
          commit("setItemExpires", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsExpireLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
