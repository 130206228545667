import * as APIs from "@/helpers/Apis/CleanningInvoice/CleanningClients";
const state = {
  data: [],
  errors: [],
  isReportLoaded: true,

  currentPage: null,
};

const getters = {
  isReportLoaded(state) {
    return state.isReportLoaded;
  },
  getReportData(state) {
    return state.data;
  },
  getReportErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsReportLoaded: (state, payload) => {
    state.isReportLoaded = payload;
  },
  setReportData: (state, payload) => {
    state.data = payload;
  },
  setReportErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload.value;
  },
};

const actions = {
  reportData: ({ commit }, data) => {
    commit("setIsReportLoaded", false);
    APIs.getCleanningEmployeesReport(data)
      .then((res) => {
        commit("setIsReportLoaded", true);
        commit("setReportData", res.data);
        commit("setReportErrors", "");
      })
      .catch((error) => {
        commit("setIsReportLoaded", true);
        let nameError = Object.values(error.response.data.errors).flat();
        commit("setReportErrors", nameError[0]);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
