import { CashierAPI } from "@/helpers/Apis/Invoices/Cashier/Cashier";
import i18n from "@/plugins/i18n/i18n";

const state = {
  data: [],
  isDataLoaded: true,

  selectedInvoice: {},
  errors: [],
  currentPage: null,
};

const getters = {
  isDataLoaded(state) {
    return state.isDataLoaded;
  },
  getData(state) {
    return state.data;
  },
  getErrors(state) {
    return state.errors;
  },
  getSelectedInvoice(state) {
    return state.selectedInvoice;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsDataLoaded: (state, payload) => {
    state.isDataLoaded = payload;
  },
  setData: (state, payload) => {
    state.data = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setInvoiceDetails(state, payload) {
    // let details = payload.details
    let invoice = state.data.data.find((o) => o.id === payload.invoice_id);
    invoice.invoice_details = payload.details;
    state.selectedInvoice = invoice;
  },

  removeInvoices(state, payload) {
    state.data.data = state.data.data.filter((item) => !payload.includes(item.id));
  },
  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload.value;
  },
};

const actions = {
  fetchDeletedInvoices: ({ commit }, data) => {
    commit("setIsDataLoaded", false);
    return new Promise((resolve, reject) => {
      CashierAPI.fetchDeletedInvoices(data)
        .then((res) => {
          resolve(res);
          commit("setIsDataLoaded", true);
          commit("setData", res.data);
        })
        .catch((error) => {
          commit("setIsDataLoaded", true);
          reject(error);
        });
    });
  },

  fetchDeletedInvoiceDetails: ({ commit }, id) => {
    commit("setIsDataLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CashierAPI.fetchDeletedInvoiceDetails(id)
        .then((res) => {
          resolve(res);
          commit("setIsDataLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setInvoiceDetails", { invoice_id: id, details: res.data.data });
        })
        .catch((error) => {
          commit("setIsDataLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  clearDeletedInvoices: ({ commit }, data) => {
    commit("setIsDataLoaded", false);
    return new Promise((resolve, reject) => {
      CashierAPI.clearDeletedInvoices(data)
        .then((res) => {
          commit("setIsDataLoaded", true);

          commit("removeInvoices", data.data.invoice_ids);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDataLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
