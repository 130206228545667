<template>
  <v-list-item class="pa-0" dense>
    <v-list-item-content class="pa-0">
      <v-list-item-title class="text-h6 text-center pa-1">
        <v-badge class="ma-0" :color="getOnlineStatus ? 'green' : 'red'" overlap dot>
          <v-menu bottom min-width="220px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <!-- tile -->
              <!-- <v-avatar v-on="on" size="70" class="pointer-cursor">
                <img :src="getCompanyData.logo" alt="CompanyLogo" />
              </v-avatar> -->
              <v-btn icon x-large v-on="on">
                <v-avatar color="brown" size="48">
                  <span class="white--text text-h6"> {{ initials }} </span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center py-2">
                <div class="mx-auto text-center">
                  <div class="pa-1">
                    {{ getUser.name }}
                  </div>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    to="/userprofile"
                    class="d-flex align-center justify-space-between px-2"
                  >
                    <v-icon>mdi-account-box-outline</v-icon>
                    {{ $t("userprofile") }}
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="reloadUser()"
                  >
                    <v-icon>mdi-reload</v-icon>
                    {{ $t("reloaduser") }}
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    to="/changedatabase"
                    class="d-flex align-center justify-space-between px-2"
                  >
                    <v-icon>mdi-database-sync-outline</v-icon>
                    {{ $t("changedatabase") }}
                  </v-btn>

                  <v-divider v-if="getUser && getUser.role.id == 1" class="my-1"></v-divider>
                  <v-btn
                    v-if="getUser && getUser.role.id == 1"
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="downloadDB()"
                    :loading="!isUserControlLoaded"
                    :disabled="!isUserControlLoaded"
                  >
                    <v-icon>mdi-download-box-outline</v-icon>
                    Download DB
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="accountLogout()"
                  >
                    <v-icon>mdi-logout</v-icon>
                    {{ $t("nav.logout") }}
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-badge>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["getOnlineStatus"],
  data: () => ({}),
  components: {},
  methods: {
    ...mapActions("UsersController", ["downloadDB"]),
    ...mapActions("User", ["reloadUser"]),

    accountLogout() {
      this.$emit("accountLogout");
    },
  },
  computed: {
    ...mapGetters("UsersController", ["isUserControlLoaded"]),
    initials() {
      return (
        this.getUser?.name
          .split(" ")
          .filter((name) => name.length > 0)
          .reduce(
            (initials, name, index) => initials + (index < 2 ? name[0].toUpperCase() : ""),
            ""
          ) ?? "Name"
      );
    },
  },
};
</script>

<style></style>
