import { StoreAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  stores: [],
  errors: [],
  isStoresLoaded: false,

  store: {},
  userStores: [],
};

const getters = {
  isStoresLoaded(state) {
    return state.isStoresLoaded;
  },
  getStores(state) {
    return state.stores;
  },
  getStore(state) {
    return state.store;
  },
  getStoreErrors(state) {
    return state.errors;
  },

  getUserStore(state) {
    return state.userStores;
  },
};

const mutations = {
  setIsStoresLoaded: (state, payload) => {
    state.isStoresLoaded = payload;
  },
  setStores: (state, payload) => {
    state.stores = payload;
  },
  setStore: (state, payload) => {
    state.store = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.stores.meta.current_page = payload;
  },

  setUserStores(state, payload) {
    state.userStores = payload;
  },

  setNewUpdateStore(state, payload) {
    let storeIndex = state.stores.findIndex((o) => o.id === payload.id);
    if (storeIndex !== -1) {
      state.stores.splice(storeIndex, 1, { ...payload });
    } else {
      state.stores.unshift(payload);
    }

    /////////
    let user = JSON.parse(localStorage.getItem("user"));
    const userBranchExists =
      user.branches.findIndex((branch) => branch.id === payload.branch_id) >= 0;
    const shouldRemoveEmployee = payload.hidden || !userBranchExists;

    const userStoreIndex = user.stores.findIndex((emp) => emp.id === payload.id);

    if (shouldRemoveEmployee) {
      if (userStoreIndex !== -1) {
        user.stores.splice(userStoreIndex, 1);
      }
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      const StoreObject = {
        id: payload.id,
        name: payload.name,
        branch_id: payload.branch_id,
      };
      if (userStoreIndex !== -1) {
        user.stores[userStoreIndex] = StoreObject;
      } else {
        user.stores.push(StoreObject);
      }
      localStorage.setItem("user", JSON.stringify(user)); // Update local storage
    }
  },
  deleteStore(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    let userStoreIndex = user.stores.findIndex((o) => o.id === payload);
    if (userStoreIndex !== -1) {
      user.stores.splice(userStoreIndex, 1); // Remove Employee
    }
    localStorage.setItem("user", JSON.stringify(user));

    ///
    let storeIndex = state.stores.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (storeIndex !== -1) {
      state.stores.splice(storeIndex, 1); // Remove drawer
    }
  },
};

const actions = {
  fetchStore: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchStore()
        .then((res) => {
          commit("setStore", res.data);
          commit("setIsStoresLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  fetchStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchStores()
        .then((res) => {
          commit("setStores", res.data.data);
          commit("setIsStoresLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createStore: ({ commit }, data) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.createStore(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoresLoaded", true);
          commit("setNewUpdateStore", res.data.data);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateStore: ({ commit }, data) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.updateStore(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoresLoaded", true);

          commit("setNewUpdateStore", res.data.data);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteStore: ({ commit }, id) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.deleteStore(id)
        .then((res) => {
          commit("setIsStoresLoaded", true);
          commit("setErrors", "");
          commit("deleteStore", id);
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },

  fetchUserStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchUserStores()
        .then((response) => {
          commit("setUserStores", response.data.data);
          commit("setIsStoresLoaded", true);
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchBasicStores()
        .then((res) => {
          commit("setStores", res.data);
          commit("setIsStoresLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
