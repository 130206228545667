import baseUrl from "@/constants/API";
// import store from "../../store/index.js";

export const UserControlAPI = {
  downloadDB() {
    return axios.get(baseUrl() + "/download-db");
  },

  fetchBasicUsers() {
    return axios.get(baseUrl() + "/basicusers");
  },

  fetchUsers() {
    return axios.get(baseUrl() + "/usercontrol/users");
  },
  registerUser(data) {
    return axios.post(baseUrl() + `/usercontrol/register`, data);
  },
  deleteUser(data) {
    return axios.delete(baseUrl() + `/usercontrol/delete/${data}`);
  },
  updateUserPassword(data) {
    return axios.put(baseUrl() + `/usercontrol/updateuserpass/${data.id}`, data.data);
  },
  updateUserName(data) {
    return axios.put(baseUrl() + `/usercontrol/updateusername/${data.id}`, data.data);
  },
  logOutUser(id) {
    return axios.post(baseUrl() + `/usercontrol/userlogout/${id}`);
  },

  addRemovePermission(data) {
    return axios.post(baseUrl() + `/usercontrol/a-r-permission`, data);
  },
  defaultUserPermissions(id) {
    return axios.put(baseUrl() + `/usercontrol/defaultpermissions/${id}`);
  },

  fetchRoles() {
    return axios.get(baseUrl() + "/usercontrol/roles");
  },
  fetchUserRoles() {
    return axios.get(baseUrl() + "/usercontrol/userroles");
  },

  updateRole(data) {
    return axios.post(baseUrl() + `/usercontrol/updaterole/${data.id}`, data.data);
  },

  fetchUserAssociates() {
    return axios.get(baseUrl() + `/usercontrol/userassociates`);
  },

  saveUserDefaults(data) {
    return axios.post(baseUrl() + "/usercontrol/defaults", data);
  },

  userClosedAccounts(id) {
    return axios.get(baseUrl() + `/usercontrol/closedaccounts/${id}`);
  },
  saveUserClosedAccounts(data) {
    return axios.post(baseUrl() + "/usercontrol/closedaccounts", data);
  },
  // suspendUser (data)  {
  //   return axios.put(baseUrl() + `/usercontrol/usersuspend/${data.user_id}`, data);
  // },
};
