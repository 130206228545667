import { ItemAPI } from "@/helpers/Apis/Item/Items";
import i18n from "@/plugins/i18n/i18n";

const state = {
  itemspricelist: [],
  errors: [],
  isItemsPriceListLoaded: true,

  currentPage: null,
};

const getters = {
  getItemsPriceList(state) {
    return state.itemspricelist;
  },
  isItemsPriceListLoaded(state) {
    return state.isItemsPriceListLoaded;
  },
  getItemErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsItemsPriceListLoaded: (state, payload) => {
    state.isItemsPriceListLoaded = payload;
  },
  setItemsPriceList: (state, payload) => {
    state.itemspricelist = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.itemspricelist.meta.current_page = payload.value;
  },
};

const actions = {
  ItemsPriceList: ({ commit }, data) => {
    commit("setIsItemsPriceListLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.ItemsPriceList(data)
        .then((res) => {
          commit("setItemsPriceList", res.data);
          commit("setIsItemsPriceListLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsItemsPriceListLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
