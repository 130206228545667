import { DatabaseDefaultAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  defaults: [],
  errors: [],
  isDBDefaultsLoaded: true,
};

const getters = {
  getDBDefaults(state) {
    return state.defaults;
  },
  isDBDefaultsLoaded(state) {
    return state.isDBDefaultsLoaded;
  },
  getDBDefaultErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setDBDefaults: (state, payload) => {
    state.defaults = payload;
  },
  setIsDBDefaultsLoaded: (state, payload) => {
    state.isDBDefaultsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  DBDefaults: ({ commit }) => {
    commit("setIsDBDefaultsLoaded", false);
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.getDBDefaults()
        .then((res) => {
          commit("setIsDBDefaultsLoaded", true);
          commit("setDBDefaults", res.data.data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDBDefaultsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  saveDBDefaults: ({ commit }, data) => {
    commit("setIsDBDefaultsLoaded", false);
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.saveDBDefaults(data)
        .then((res) => {
          commit("setIsDBDefaultsLoaded", true);
          commit("setDBDefaults", res.data.data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDBDefaultsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  resetDBDefaults: ({ commit }, data) => {
    commit("setIsDBDefaultsLoaded", false);
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.resetDBDefaults(data)
        .then((res) => {
          commit("setIsDBDefaultsLoaded", true);
          commit("setDBDefaults", res.data.data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDBDefaultsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchMainAccs: ({ commit }) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.fetchMainAccs()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  fetchSubAccs: ({ commit }) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.fetchSubAccs()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  getJobs: ({ commit }) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      DatabaseDefaultAPI.getJobs()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
