import { connection } from "@/helpers/IndexDB/jsstore_con";

export class ItemService {
  constructor() {
    this.tableName = "CashierItems";
  }

  getItems() {
    return connection.select({
      from: this.tableName,
    });
  }

  addItem(item) {
    return connection.insert({
      into: this.tableName,
      values: [item],
      return: true,
    });
  }

  setItems(items) {
    return connection.insert({
      into: this.tableName,
      values: items,
      // return: true,
    });
  }

  getItemById(id) {
    return connection.select({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  deleteItem(id) {
    return connection.remove({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  clearItems() {
    return connection.clear(this.tableName);
  }

  updateItemById(item) {
    return connection.update({
      in: this.tableName,
      set: {
        // paid: head.paid,
      },
      where: {
        id: item.id,
      },
    });
  }
}
