import { CustomerListAPI } from "@/helpers/Apis/index";

const state = {
  data: [],
  isCustomersLoaded: true,

  currentPage: null,
};

const getters = {
  getData(state) {
    return state.data;
  },
  isCustomersLoaded(state) {
    return state.isCustomersLoaded;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsCustomersLoaded: (state, payload) => {
    state.isCustomersLoaded = payload;
  },
  setData: (state, payload) => {
    state.data = payload;
  },

  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload.value;
  },
};

const actions = {
  fetchCustomerBalance: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchCustomerBalance(data)
        .then((res) => {
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchClientsList: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchClientsList(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchSuppliersList: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchSuppliersList(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchEmployeesList: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchEmployeesList(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchMaintenanceClientsList: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchMaintenanceClientsList(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchCleanningClientsList: ({ commit }, data) => {
    commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchCleanningClientsList(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsCustomersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchClient: ({ commit }, data) => {
    // commit("setIsCustomersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchClient(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchSupplier: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    // commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchSupplier(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchEmployee: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    // commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchEmployee(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },

  fetchMaintenance: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    // commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchMaintenance(data)
        .then((res) => {
          // commit("setIsCustomersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data.data);
        })
        .catch((error) => {
          // commit("setIsCustomersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchCleanning: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    // commit("setIsCustomersLoaded", false);
    return new Promise((resolve, reject) => {
      CustomerListAPI.fetchCleanning(data)
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          // commit("setIsCustomersLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
