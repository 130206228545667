import baseUrl from "@/constants/API";

export const ProportionProductions = {
  fetchProportionProduction(id) {
    return axios.get(baseUrl() + `/production/proportion/${id}`);
  },
  previousProportionProduction(id) {
    return axios.get(baseUrl() + `/production/proportion/${id}/previous`);
  },
  nextProportionProduction(id) {
    return axios.get(baseUrl() + `/production/proportion/${id}/next`);
  },

  getProportionProductions(data) {
    // return axios.get(baseUrl() + `/production/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/production/proportion/${invoice_id}?page=${page}`);
  },

  createProportionProduction(data) {
    return axios.post(baseUrl() + `/production/proportion/create`, data);
  },
  updateProportionProduction(data) {
    return axios.post(baseUrl() + `/production/proportion/update/${data.id}`, data.data);
  },
  createProductDetails(data) {
    return axios.post(baseUrl() + `/production/proportion/detail/create`, data);
  },

  deleteProduction(id) {
    return axios.delete(baseUrl() + `/production/delete/${id}`);
  },

  deleteProductionDetail(id) {
    return axios.delete(baseUrl() + `/production/proportion/detail/delete/${id}`);
  },

  // deleted STUFF
  // const fetchDeletedInvoices  (data)  {
  //   return axios.post(baseUrl() + `/production/deleted?page=${data.pageNumber}`, data.data);
  // },
  // const fetchDeletedInvoiceDetails  (invoice_id)  {
  //   return axios.post(baseUrl() + `/production/deleted/${invoice_id}`);
  // },
  // const clearDeletedInvoices  (data)  {
  //   return axios.delete(baseUrl() + `/production/deleted/clear`, data);
  // },
};
