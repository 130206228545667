import baseUrl from "@/constants/API";

export const RestaurantTableAPI = {
  fetchTables(data) {
    return axios.post(baseUrl() + `/restaurant/table/get?page=${data.pageNumber}`, data.data);
  },

  fetchBasicTables() {
    return axios.get(baseUrl() + `/restaurant/table/getbasic`);
  },

  fetchRestaurantTables(branchId) {
    return axios.get(baseUrl() + `/restaurant/table/getrestaurant/${branchId}`);
  },

  createTable(data) {
    return axios.post(baseUrl() + `/restaurant/table/create`, data);
  },
  updateTable(data) {
    return axios.post(baseUrl() + `/restaurant/table/update/${data.id}`, data.data);
  },

  deleteTable(id) {
    return axios.delete(baseUrl() + `/restaurant/table/delete/${id}`);
  },
  setTableStatus(data) {
    return axios.post(baseUrl() + `/restaurant/table/setstatus`, data);
  },

  fetchTableStatuses(data) {
    return axios.post(baseUrl() + `/restaurant/table/statuses`, data);
  },
};
