import axios from "axios";
import router from "@/router/index";
import store from "@/store/index";
import VueCookies from "vue-cookies";
import i18n from "@/plugins/i18n/i18n";

window.axios = axios;

/*
|--------------------------------------------------------------------------
| Request Interceptor
|--------------------------------------------------------------------------
*/
axios.interceptors.request.use(
  (request) => {
    // Send JWT token with every request if possible
    // const token = localStorage.getItem("token");
    const token = VueCookies.get("token");
    if (token) {
      request.headers["Authorization"] = "Bearer " + JSON.parse(token);
    }
    request.headers["Accept-Language"] = localStorage.getItem("lang")
      ? localStorage.getItem("lang").toLowerCase()
      : "ar";
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/*
|--------------------------------------------------------------------------
| Response Interceptor
|--------------------------------------------------------------------------
*/
axios.interceptors.response.use(
  (response) => {
    if (
      response.status == 200 &&
      response.data.message &&
      response.data.alert &&
      response.data.message.length > 0
    ) {
      iziToast.success({
        title: i18n.t("success"),
        message: response.data.message,
        timeout: 5000,
      });
    }
    return response;
  },
  (error) => {
    if (error.response.data && error.response.data.alert == false) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 409) {
      return Promise.reject(error);
    }

    if (error.response && (error.response.status === 403 || error.response.status === 500)) {
      iziToast.error({
        message: error.response?.data?.message || i18n.t("somethingwrong"),
        title: "", // i18n.t("errors.warning")
        timeout: 5000,
      });
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      iziToast.error({
        message: error.response?.data?.message || i18n.t("somethingwrong"),
        title: "",
        timeout: 5000,
      });
      // axios.defaults.headers.common["Authorization"];
      // store.dispatch("User/logout");
      store.commit("User/setLogout");
      return Promise.reject(error);
    }

    if (
      error.response &&
      (error.response.status === 404 ||
        error.response.status === 400 ||
        error.response.status === 422)
    ) {
      let nameErrors = error.response.data.errors
        ? Object.values(error.response.data.errors).flat()
        : error.response.data.message;
      nameErrors = Array.isArray(nameErrors) ? nameErrors : [nameErrors];
      if (Array.isArray(nameErrors)) {
        nameErrors.forEach((element) => {
          iziToast.error({
            message: element ?? i18n.t("somethingwrong"),
            title: "",
            timeout: 5000,
          });
        });
      } else {
        iziToast.error({
          message: nameErrors[0] || i18n.t("somethingwrong"),
          title: "",
          timeout: 5000,
        });
      }
    } else {
      iziToast.error({
        message: i18n.t("somethingwrong"),
        title: "",
        timeout: 5000,
      });
    }
    return Promise.reject(error);
  }
);
