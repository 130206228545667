import baseUrl from "@/constants/API";

export const StoreInDetailAPI = {
  deleteStoreInDetail(data) {
    return axios.delete(baseUrl() + `/storeindetails/delete/${data}`);
  },

  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/storeindetails/checkdelete`, data);
  },
};
