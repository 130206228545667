import baseUrl from "@/constants/API";

export const SaleReturnDetailAPI = {
  deleteInvoiceDetails(data) {
    return axios.delete(baseUrl() + `/salereturndetails/delete/${data}`);
  },

  checkDeleteDetail(data) {
    return axios.post(baseUrl() + `/salereturndetails/checkdelete`, data);
  },
};
