import baseUrl from "@/constants/API";

export const PurchaseAPI = {
  fetchPurchase(id) {
    return axios.get(baseUrl() + `/purchase/${id}`);
  },
  previousPurchase(id) {
    return axios.get(baseUrl() + `/purchase/${id}/previous`);
  },
  nextPurchase(id) {
    return axios.get(baseUrl() + `/purchase/${id}/next`);
  },
  createPurchase(data) {
    return axios.post(baseUrl() + `/purchase/create`, data);
  },
  updatePurchase(data) {
    return axios.post(baseUrl() + `/purchase/update/${data.id}`, data.data);
  },
  deletePurchase(id) {
    return axios.delete(baseUrl() + `/purchase/delete/${id}`);
  },

  purchaseDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchase/details/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/purchase/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchase/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/purchase/deleted/clear`, data);
  },
};
