import baseUrl from "@/constants/API";

export const RestaurantOrderAPI = {
  fetchRestaurantOrder(id) {
    return axios.get(baseUrl() + `/restaurant/orders/${id}`);
  },

  createRestaurantOrder(data) {
    return axios.post(baseUrl() + `/restaurant/orders/create`, data);
  },

  updateRestaurantOrder(data) {
    return axios.post(baseUrl() + `/restaurant/orders/update/${data.id}`, data.data);
  },
  deleteRestaurantOrder(id) {
    return axios.delete(baseUrl() + `/restaurant/orders/delete/${id}`);
  },
  setRestaurantOrderStatus(data) {
    return axios.post(baseUrl() + `/restaurant/orders/setstatus`, data);
  },
  // restaurantOrderDetails(order_id) {
  //   return axios.post(baseUrl() + `/restaurant/orders/details/${order_id}`);
  // },
  restaurantOrderPayment(data) {
    return axios.post(baseUrl() + `/restaurant/orders/${data.id}/payment`, data.data);
  },

  /// Custom Searches
  fetchRestaurantOrderDailySearch(dailyInvoiceNumber) {
    return axios.get(baseUrl() + `/restaurant/orders/daily-invoice-number/${dailyInvoiceNumber}`);
  },

  /// Soft Delete Stuff
  fetchDeletedRestaurantOrders(data) {
    return axios.post(baseUrl() + `/restaurant/orders/deleted?page=${data.pageNumber}`, data.data);
  },
  getDeletedRestaurantOrderDetails(invoice_id) {
    return axios.post(baseUrl() + `/restaurant/orders/deleted/${invoice_id}`);
  },
  clearDeletedRestaurantOrders(data) {
    return axios.delete(baseUrl() + `/restaurant/orders/deleted/clear`, data);
  },
};
