import * as APIs from "@/helpers/Apis/EndingBalance/EndingBalance";
import i18n from "@/plugins/i18n/i18n";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";

const state = {
  trade: [],
  profitLoss: [],
  balanceSheet: [],

  stockValue: 0,

  isEndBalanceLoaded: true,
  errors: [],
};

const getters = {
  leftTradeAccounts(state) {
    /// خصوم / ايرادات
    let Accounts = state.trade;
    let leftAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 4 || el.accounttype_id == 2;
    });
    leftAccounts.push({
      accounttype_id: 2,
      defaultacc_id: 7,
      name: i18n.t("stockvalue"),
      showsin_id: 1,
      accounts_debit: 0,
      accounts_credit: state.stockValue || 0,
      total_balance: -state.stockValue || 0,
    });
    return leftAccounts;
  },
  rightTradeAccounts(state) {
    /// اصول /  مصروفات
    let Accounts = state.trade;
    let rightAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 3 || el.accounttype_id == 1;
    });
    return rightAccounts;
  },

  isEndBalanceLoaded(state) {
    return state.isEndBalanceLoaded;
  },

  EndTradingProfits(state, getters) {
    /// خصوم / ايرادات
    let leftAccounts = getters.leftTradeAccounts;
    /// اصول /  مصروفات
    let rightAccounts = getters.rightTradeAccounts;
    let leftAccountsTotal = leftAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);

    let rightAccountsTotal = rightAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);
    return toFixedIfNecessary(leftAccountsTotal + rightAccountsTotal, 2) || 0;
  },

  // ProfitLossAccounts
  EndProfitLoss(state, getters) {
    /// خصوم / ايرادات
    let leftAccounts = getters.leftProfitLossAccounts;
    /// اصول /  مصروفات
    let rightAccounts = getters.rightProfitLossAccounts;
    let leftAccountsTotal = leftAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);

    let rightAccountsTotal = rightAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);
    return toFixedIfNecessary(leftAccountsTotal + rightAccountsTotal, 2) || 0;
  },
  leftProfitLossAccounts(state, getters) {
    /// خصوم / ايرادات
    let TradeResult = getters.EndTradingProfits;
    let Accounts = state.profitLoss;
    let leftAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 4 || el.accounttype_id == 2;
    });
    leftAccounts.push({
      accounttype_id: 2,
      name: i18n.t("traderesult"),
      accounts_debit: 0,
      accounts_credit: TradeResult,
      total_balance: TradeResult || 0,
    });

    return leftAccounts;
  },
  rightProfitLossAccounts(state) {
    /// اصول /  مصروفات
    let Accounts = state.profitLoss;
    let rightAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 3 || el.accounttype_id == 1;
    });
    return rightAccounts;
  },

  // BalanceSheet
  leftBalanceSheetAccounts(state, getters) {
    /// خصوم / ايرادات
    let TradeResult = getters.EndProfitLoss;
    let Accounts = state.balanceSheet;
    let leftAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 4 || el.accounttype_id == 2;
    });
    leftAccounts.push({
      accounttype_id: 2,
      name: i18n.t("profitloss"),
      accounts_debit: 0,
      accounts_credit: TradeResult || 0,
      total_balance: TradeResult || 0,
    });

    return leftAccounts;
  },
  rightBalanceSheetAccounts(state, getters) {
    /// اصول /  مصروفات
    let StockValue = getters.leftTradeAccounts.find((f) => {
      return (
        f.name === i18n.t("stockvalue") &&
        f.accounttype_id === 2 &&
        f.defaultacc_id === 7 &&
        f.showsin_id === 1
      );
    });

    let Accounts = state.balanceSheet;
    let rightAccounts = Accounts.filter(function (el) {
      return el.accounttype_id == 3 || el.accounttype_id == 1;
    });
    rightAccounts.push({
      accounttype_id: 2,
      defaultacc_id: 7,
      name: i18n.t("stockvalue"),
      showsin_id: 1,
      accounts_debit: StockValue.accounts_credit,
      accounts_credit: 0,
      total_balance: StockValue.accounts_credit,
    });
    return rightAccounts;
  },

  EndBalanceSheet(state, getters) {
    /// خصوم / ايرادات
    let leftAccounts = getters.leftBalanceSheetAccounts;
    /// اصول /  مصروفات
    let rightAccounts = getters.rightBalanceSheetAccounts;
    let leftAccountsTotal = leftAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);

    let rightAccountsTotal = rightAccounts.reduce((accumulator, object) => {
      return accumulator + object.total_balance;
    }, 0);
    return toFixedIfNecessary(leftAccountsTotal + rightAccountsTotal, 2) || 0;
  },
};

const mutations = {
  setEndBalance: (state, payload) => {
    let accounts = payload[0];
    state.stockValue = payload[1];
    let tradeAccounts = accounts.filter(function (el) {
      return el.showsin_id == 1;
    });
    let profitLossAccounts = accounts.filter(function (el) {
      return el.showsin_id == 2;
    });
    let balanceSheetAccounts = accounts.filter(function (el) {
      return el.showsin_id == 3;
    });
    state.trade = tradeAccounts;
    state.profitLoss = profitLossAccounts;
    state.balanceSheet = balanceSheetAccounts;
  },

  setIsEndBalanceLoaded: (state, payload) => {
    state.isEndBalanceLoaded = payload;
  },
};

const actions = {
  fetchFinalBalance: ({ commit }, data) => {
    commit("setIsEndBalanceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchFinalBalance(data)
        .then((res) => {
          commit("setIsEndBalanceLoaded", true);
          let data = res.data;
          commit("setEndBalance", data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEndBalanceLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
