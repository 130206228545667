import baseUrl from "@/constants/API";

export const EmployeeJobAPI = {
  fetchEmployeeJobs() {
    return axios.get(baseUrl() + "/employeejobs/all");
  },
  createEmployeeJob(data) {
    return axios.post(baseUrl() + `/employeejobs/create`, data);
  },
  getEmployeeJob(data) {
    return axios.get(baseUrl() + `/employeejobs/single/${data.id}`);
  },
  updateEmployeeJob(data) {
    return axios.post(baseUrl() + `/employeejobs/update/${data.id}`, data.data);
  },
  deleteEmployeeJob(data) {
    return axios.delete(baseUrl() + `/employeejobs/delete/${data}`);
  },
  searchEmployeeJob(data) {
    return axios.post(baseUrl() + "/employeejobs/search", data);
  },
};
