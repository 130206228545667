import { BranchAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  branches: [],
  errors: [],
  isBranchesLoaded: false,
};

const getters = {
  isBranchesLoaded(state) {
    return state.isBranchesLoaded;
  },
  getBranches(state) {
    return state.branches;
  },
  getBranchErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsBranchesLoaded: (state, payload) => {
    state.isBranchesLoaded = payload;
  },
  setBranches: (state, payload) => {
    state.branches = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setNewUpdateBranch(state, payload) {
    let branchIndex = state.branches.findIndex((o) => o.id === payload.id);
    if (branchIndex !== -1) {
      // state.branches[branchIndex] = payload;
      state.branches.splice(branchIndex, 1, { ...payload });
    } else {
      state.branches.unshift({ ...payload });
    }

    ///////////
    let user = JSON.parse(localStorage.getItem("user"));
    const userBranchIndex = user.branches.findIndex((o) => o.id === payload.id);

    if (payload.hidden == true) {
      if (userBranchIndex !== -1) {
        user.branches.splice(userBranchIndex, 1);
      }
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      const branchObject = {
        id: payload.id,
        name: payload.name,
      };
      if (userBranchIndex !== -1) {
        user.branches[userBranchIndex] = branchObject;
      } else {
        user.branches.push(branchObject);
      }
      localStorage.setItem("user", JSON.stringify(user));
    }
  },
  deleteBranch(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    let userBranchIndex = user.branches.findIndex((o) => o.id === payload);
    if (userBranchIndex !== -1) {
      user.branches.splice(userBranchIndex, 1); // Remove Employee
    }
    localStorage.setItem("user", JSON.stringify(user));

    ////
    let branchIndex = state.branches.findIndex((o) => o.id === payload);
    if (branchIndex !== -1) {
      state.branches.splice(branchIndex, 1);
    }
  },
};

const actions = {
  fetchBranches: ({ commit }) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.fetchBranches()
        .then((res) => {
          commit("setBranches", res.data.data);
          commit("setIsBranchesLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
        });
    });
  },

  createBranch: ({ commit }, data) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.createBranch(data)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateBranch", res.data.data);
          resolve(res.data);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateBranch: ({ commit }, data) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.updateBranch(data)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateBranch", res.data.data);
          resolve(res.data);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteBranch: ({ commit }, data) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.deleteBranch(data)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", "");

          commit("deleteBranch", data);
          commit("User/deleteAssociate", { data: data, type: "branches" }, { root: true });
          resolve(res);
          commit("User/refreshUserLocal", res.data.data, { root: true });
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  fetchBasicBranches: ({ commit }) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.fetchBasicBranches()
        .then((res) => {
          commit("setBranches", res.data);
          commit("setIsBranchesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
