import baseUrl from "@/constants/API";

export const NoteAccountAPI = {
  fetchAllReceivableAcc() {
    return axios.get(baseUrl() + "/noteaccounts/allreceivableaccs");
  },

  fetchAllPayableAcc() {
    return axios.get(baseUrl() + "/noteaccounts/allpayableaccs");
  },

  fetchCustomerMainAccs() {
    return axios.get(baseUrl() + "/noteaccounts/customermainaccs");
  },
  fetchBankAccs() {
    return axios.get(baseUrl() + "/noteaccounts/bankaccs");
  },
  fetchReceivableNoteAccs() {
    return axios.get(baseUrl() + "/noteaccounts/receivableaccs");
  },
  fetchPayableNoteAccounts() {
    return axios.get(baseUrl() + "/noteaccounts/payableaccs");
  },
};
