import baseUrl from "@/constants/API";

let companyLoginUrl =
  process.env.NODE_ENV == "production" ? process.env.VUE_APP_BASE_URL : "http://localhost:8000/api";

export const CompanyAPI = {
  companyLogin(data) {
    return axios.post(companyLoginUrl + "/companylogin", data);
  },
  fetchDatabases(data) {
    return axios.post(companyLoginUrl + "/getdatabases", data);
  },

  updateCompany(data) {
    return axios.post(baseUrl() + `/company/update`, data);
  },
  updatePassword(data) {
    return axios.post(baseUrl() + `/company/updatepass`, data);
  },
  updateLogo(data) {
    return axios.post(baseUrl() + `/company/updatelogo`, data);
  },
};
