import { RestaurantOrderReportAPI } from "@/helpers/Apis/index";

const state = {
  recipeTransactions: [],

  errors: [],
  isReportLoaded: true,
  currentPage: null,
};

const getters = {
  getRecipeTransactions(state) {
    return state.recipeTransactions;
  },

  isReportLoaded(state) {
    return state.isReportLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setRecipeTransactions: (state, payload) => {
    state.recipeTransactions = payload;
  },

  setIsReportLoaded: (state, payload) => {
    state.isReportLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },
};

const actions = {
  fetchRestaurantRecipeTransactions: ({ commit }, data) => {
    commit("setIsReportLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderReportAPI.fetchRestaurantRecipeTransactions(data)
        .then((res) => {
          commit("setRecipeTransactions", res.data);
          commit("setErrors", []);
          commit("setIsReportLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
