import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () =>
    import(/* webpackChunkName: "storeinout-transfer" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/storetransfers",
    name: "StoreTransfers",
    component: lazyLoad("StoreTransfers", "StoreTransfers"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storetransfers/:id(\\d+)",
    name: "SingleStoreTransfer",
    component: lazyLoad("StoreTransfers", "StoreTransfer"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storetransfers/create",
    name: "CreateStoreTransfer",
    component: lazyLoad("StoreTransfers", "StoreTransfer"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeins",
    name: "StoreIns",
    component: lazyLoad("Invoice/StoreIns", "StoreIns"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeins/:id(\\d+)",
    name: "SingleStoreIn",
    component: lazyLoad("Invoice/StoreIns", "StoreIn"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeins/create",
    name: "CreateStoreIn",
    component: lazyLoad("Invoice/StoreIns", "StoreIn"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeouts",
    name: "StoreOuts",
    component: lazyLoad("Invoice/StoreOuts", "StoreOuts"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeouts/:id(\\d+)",
    name: "SingleStoreOut",
    component: lazyLoad("Invoice/StoreOuts", "StoreOut"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/storeouts/create",
    name: "CreateStoreOut",
    component: lazyLoad("Invoice/StoreOuts", "StoreOut"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// StoreOuts Reports
  {
    path: "/storeouts/deleted",
    name: "StoreOutsDeleted",
    component: lazyLoad("Invoice", "StoreOuts/Reports/StoreOutsDeleted"),
    meta: {
      middleware: [auth],
    },
  },
  //////////// StoreInss Reports
  {
    path: "/storeins/deleted",
    name: "StoreInsDeleted",
    component: lazyLoad("Invoice", "StoreIns/Reports/StoreInsDeleted"),
    meta: {
      middleware: [auth],
    },
  },

  //////////// Transfers Reports
  {
    path: "/storetransfers/deleted",
    name: "StoreTransfersDeleted",
    component: lazyLoad("StoreTransfers", "Reports/StoreTransfersDeleted"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
