import { connection } from "@/helpers/IndexDB/jsstore_con";

export class DetailService {
  constructor() {
    this.tableName = "CashierDetails";
  }

  getDetails() {
    return connection.select({
      from: this.tableName,
    });
  }

  addDetail(detail) {
    return connection.insert({
      into: this.tableName,
      values: [detail],
      return: true,
    });
  }

  getDetailById(id) {
    return connection.select({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  getDetailByInvoiceId(id) {
    return connection.select({
      from: this.tableName,
      where: {
        invoice_id: id,
      },
    });
  }

  getDetailByInvoiceIdItem(data) {
    return connection.select({
      from: this.tableName,
      where: {
        invoice_id: data.invoice_id,
        item_id: data.item_id,
      },
    });
  }

  removeDetail(id) {
    return connection.remove({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }

  clearDetails() {
    return connection.clear(this.tableName);
  }

  updateDetailById(detail) {
    return connection.update({
      in: this.tableName,
      set: {
        item_id: detail.item_id,
        item_name: detail.item_name,
        howmanyinbig: detail.howmanyinbig,
        barcode: detail.barcode,
        unit: detail.unit,
        piece: detail.piece,
        price: detail.price,
        priceAfter: detail.priceAfter,
        og_discV: detail.og_discV,
        og_taxV: detail.og_taxV,
        discount_ratio: detail.discount_ratio,
        discount_value: detail.discount_value,
        tax_ratio: detail.tax_ratio,
        tax_value: detail.tax_value,
        total_before: detail.total_before,
        total: detail.total,
      },
      where: {
        id: detail.id,
      },
    });
  }
}
