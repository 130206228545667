import * as APIs from "@/helpers/Apis/Database/Database";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isDatabaseLoaded: true,
};

const getters = {
  isDatabaseLoaded(state) {
    return state.isDatabaseLoaded;
  },
  getSettingsErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDatabaseLoaded: (state, payload) => {
    state.isDatabaseLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  saleAgentsToOriginalClients: ({ commit }) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsDatabaseLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.saleAgentsToOriginalClients()
        .then((response) => {
          if (response.status == 200) {
            commit("setErrors", "");
          }
          resolve(response);
          commit("App/setAppLoader", false, { root: true });
          commit("setIsDatabaseLoaded", true);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsDatabaseLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);

          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
