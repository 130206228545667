import baseUrl from "@/constants/API";

export const ItemSerialAPI = {
  createSerial(data) {
    return axios.post(baseUrl() + `/items/serials/create`, data);
  },
  editSerial(data) {
    return axios.post(baseUrl() + `/items/serials/${data.id}/edit`, data.data);
  },
  deleteSerial(id) {
    return axios.delete(baseUrl() + `/items/serials/delete/${id}`);
  },
  deleteTransaction(id) {
    return axios.delete(baseUrl() + `/items/serials/delete/transaction/${id}`);
  },

  fetchSerialsFilter(data) {
    return axios.post(
      baseUrl() + `/items/serials/serialsfilter?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchTransactionsFilter(data) {
    return axios.post(
      baseUrl() + `/items/serials/transactionsfilter?page=${data.pageNumber}`,
      data.data
    );
  },

  ReviewSerials(data) {
    return axios.get(baseUrl() + `/items/serials/review`, data);
  },

  // Serials Fetch  Serials Fetch  Serials Fetch
  fetchSaleReturnSerials(data) {
    return axios.post(baseUrl() + `/items/serials/sreturnserials`, data);
  },
  fetchPurchaseReturnSerials(data) {
    return axios.post(baseUrl() + `/items/serials/preturnserials`, data);
  },
  fetchSerialsRelated(data) {
    return axios.post(baseUrl() + `/items/serials/serialsrelated`, data);
  },
  fetchSaleSerials(data) {
    return axios.post(baseUrl() + `/items/serials/saleserials`, data);
  },
  fetchTransferSerials(data) {
    return axios.post(baseUrl() + `/items/serials/transferserials`, data);
  },
};
