import { SaleDetailAPI } from "@/helpers/Apis/Invoices/Sales/SaleDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isSaleDetailsLoaded: true,
};

const getters = {
  isSaleDetailsLoaded(state) {
    return state.isSaleDetailsLoaded;
  },
  getSaleDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsSaleDetailsLoaded: (state, payload) => {
    state.isSaleDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  checkDeleteDetail: ({ commit }, data) => {
    commit("setIsSaleDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      SaleDetailAPI.checkDeleteDetail(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSaleDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteSaleDetail: ({ commit }, id) => {
    commit("setIsSaleDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      SaleDetailAPI.deleteSaleDetail(id)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
          }
          commit("setIsSaleDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleDetailsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
