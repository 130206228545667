<template>
  <v-autocomplete
    :items="data"
    :item-text="item_text ? item_text : 'name'"
    :item-value="item_value ? item_value : 'id'"
    autocomplete="off"
    multiple
    :loading="dataLoading"
    :search-input.sync="search"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    @input="handleInput"
    dense
    outlined
    filled
    hide-details="auto"
  >
    <template v-slot:append-item>
      <div v-if="lastPage <= itemsDataPage" class="pa-4 teal--text">
        <h4>All Items Loaded</h4>
      </div>
      <div v-else>
        <div v-intersect="onIntersect" class="pa-4 teal--text">
          <h4>Loading More Items...</h4>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: [
    "value",
    "data",
    "dataLoading",
    "item_text",
    "item_value",
    "searchAction",
    "InterSectAction",
  ],

  data() {
    return {
      itemsDataPage: 1,
      searchTimer: undefined,
      searchQuery: "",
      lastPage: null,
    };
  },

  computed: {
    search: {
      get() {
        return this.searchQuery;
      },

      set(searchInput) {
        this.searchQuery = searchInput;
        if (this.searchQuery && this.searchQuery.length != 0) {
          clearTimeout(this.searchTimer);
          this.searchTimer = setTimeout(() => {
            this.searchAction({ name: this.searchQuery }).then((res) => {
              // this.itemsData = [...res.data, ...this.itemsData];
              this.$emit("searchResult", res.data);
              // console.log(res);
            });
          }, 1000);
        }
      },
    },
  },

  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
    },

    onIntersect() {
      // console.log(this.lastPage, this.itemsDataPage);
      if (this.lastPage == null || this.lastPage > this.itemsDataPage) {
        // console.log("load more...");
        this.itemsDataPage = this.itemsDataPage + 1;
        this.InterSectAction(this.itemsDataPage).then((res) => {
          this.lastPage = res.data.meta.last_page;
          this.$emit("intersectData", res.data.data);
          // this.itemsData.push(res.data.data);
        });
      }
    },
  },
};
</script>

<style></style>
