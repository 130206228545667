import i18n from "@/plugins/i18n/i18n";

const state = {
  appLoader: false,
  languageChecker: localStorage.getItem("lang") == "EN" ? true : false,
  drawerState: false,
  onLine: true,
};

const getters = {
  getAppLoader(state) {
    return state.appLoader;
  },
  getLanguage(state) {
    return state.languageChecker;
  },
  getDrawerState(state) {
    return state.drawerState;
  },

  getOnlineStatus(state) {
    return state.onLine;
  },
};

const mutations = {
  setAppLoader: (state, payload) => {
    state.appLoader = payload;
  },
  setLanguage(state, payload) {
    // state.languageChecker = !state.languageChecker;
    localStorage.setItem("lang", payload);
    i18n.locale = payload;

    if (localStorage.getItem("lang") == "AR") {
      state.languageChecker = false;
    } else {
      state.languageChecker = true;
    }
  },
  toggleDrawerState(state) {
    state.drawerState = !state.drawerState;
  },

  updateOnlineStatus(state, payload) {
    const { type } = payload;
    state.onLine = type === "online";
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
