import i18n from "@/plugins/i18n/i18n";

const StatusesArray = [
  { id: 1, name: i18n.t("placed") },
  { id: 2, name: i18n.t("preparing") },
  { id: 3, name: i18n.t("finished") },
  { id: 4, name: i18n.t("completed") },
  { id: 5, name: i18n.t("canceled") },
];

const Statuses = {
  Placed: { id: 1, name: i18n.t("placed") },
  Preparing: { id: 2, name: i18n.t("preparing") },
  Finished: { id: 3, name: i18n.t("finished") },
  Completed: { id: 4, name: i18n.t("completed") },
  Canceled: { id: 5, name: i18n.t("canceled") },
};

const Types = [
  { id: 1, name: i18n.t("delivery") },
  { id: 2, name: i18n.t("takeaway") },
  { id: 3, name: i18n.t("dinein") },
];

const ReservationStatusesArray = [
  { id: 1, name: i18n.t("pending") },
  { id: 2, name: i18n.t("confirmed") },
  { id: 3, name: i18n.t("canceled") },
  { id: 4, name: i18n.t("completed") },
];

const ReservationStatuses = {
  Pending: { id: 1, name: i18n.t("pending") },
  Confirmed: { id: 2, name: i18n.t("confirmed") },
  Canceled: { id: 3, name: i18n.t("canceled") },
  Completed: { id: 4, name: i18n.t("completed") },
};

export { StatusesArray, Statuses, Types, ReservationStatusesArray, ReservationStatuses };
