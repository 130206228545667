import baseUrl from "@/constants/API";

const ProductionProportions = (data) => {
  return axios.post(baseUrl() + `/productionproportions/get?page=${data.pageNumber}`, data.data);
};

const getProportionsList = (data) => {
  return axios.get(baseUrl() + `/productionproportions/getlist`, data);
};
const getProportionList = (id) => {
  return axios.get(baseUrl() + `/productionproportions/getlist/${id}`);
};
const getProportionListBar = (barcode) => {
  return axios.get(baseUrl() + `/productionproportions/getlistbar/${barcode}`);
};

const createProductionProportion = (data) => {
  return axios.post(baseUrl() + `/productionproportions/create`, data);
};
const updateProductionProportion = (data) => {
  return axios.post(baseUrl() + `/productionproportions/update/${data.id}`, data.data);
};
const deleteProductionProportion = (id) => {
  return axios.delete(baseUrl() + `/productionproportions/delete/${id}`);
};

// ProductionRatio items
const deleteProductionProportionItem = (id) => {
  return axios.post(baseUrl() + `/productionproportionitems/delete/${id}`);
};

export {
  ProductionProportions,
  getProportionsList,
  getProportionList,
  getProportionListBar,
  createProductionProportion,
  updateProductionProportion,
  deleteProductionProportion,
  // ProductionRatio items
  deleteProductionProportionItem,
};
