<template>
  <v-badge
    :content="getNotifications.length"
    :value="getNotifications.length"
    color="red darken-2"
    :overlap="true"
    class="badge"
  >
    <v-menu
      bottom
      min-width="320px"
      max-width="320px"
      :close-on-content-click="false"
      rounded
      offset-y
    >
      <template v-slot:activator="{ on: menuOn }">
        <v-tooltip top color="toolTipBgColor">
          <template v-slot:activator="{ on: tooltipOn, attrs }">
            <!-- Merged the menu's and tooltip's event listeners for the icon -->
            <v-icon
              v-bind="attrs"
              v-on="{
                ...menuOn,
                ...tooltipOn,
              }"
            >
              mdi-bell
            </v-icon>
          </template>
          <span>{{ $t("notifications") }}</span>
        </v-tooltip>
      </template>

      <v-card class="text-end">
        <div>
          <v-tooltip
            v-if="getNotifications.length > 0"
            top
            color="toolTipBgColor"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="pa-1"
                @click="deleteNotifications()"
                v-bind="attrs"
                v-on="on"
                icon
                color="red darken-2 "
              >
                mdi-delete-alert
              </v-icon>
            </template>
            <span>{{ $t("deleteall") }}</span>
          </v-tooltip>

          <v-tooltip top color="toolTipBgColor">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="pa-1"
                @click="fetchNotifications()"
                v-bind="attrs"
                v-on="on"
                icon
                color="success "
              >
                mdi-reload
              </v-icon>
            </template>
            <span>{{ $t("reload") }}</span>
          </v-tooltip>
        </div>

        <v-card-text class="cardStyle pt-0">
          <v-list-item-content class="listStyle" v-if="isNotificationsLoaded">
            <div v-if="getNotifications.length == 0">{{ $t("nodata") }}</div>
            <div
              v-else
              class="text-center"
              v-for="notification in getNotifications"
              :key="notification.id"
            >
              <div class="d-flex justify-space-between align-center">
                <h3 class="bodyText">{{ notification.message }}</h3>
                <v-divider vertical class="mx-1"></v-divider>
                <h4 class="dateText">{{ notification.created_at }}</h4>

                <div class="d-flex">
                  <v-divider vertical class="mx-1"></v-divider>
                  <!-- <v-icon v-if="!notification.read" class="mx-1" color="green" fab>
                  mdi-checkbox-marked-circle-outline
                </v-icon> -->
                  <v-tooltip top color="toolTipBgColor">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="deleteNotification(notification.id)"
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                      >
                        mdi-alpha-x-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("delete") }}</span>
                  </v-tooltip>
                </div>
              </div>

              <v-divider class="mt-2"></v-divider>
            </div>
          </v-list-item-content>
          <div v-else class="h-100 d-flex align-center justify-center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-badge>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: [],
  components: {},
  computed: {
    ...mapGetters("Notifications", ["getNotifications"]),
    ...mapGetters("Notifications", ["isNotificationsLoaded"]),
  },
  methods: {
    ...mapActions("Notifications", ["fetchNotifications"]),
    ...mapActions("Notifications", ["deleteNotification"]),
    ...mapActions("Notifications", ["deleteNotifications"]),
    // accountLogout() {
    //   this.$emit("accountLogout");
    // },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  width: 30px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 2rem !important;
  }
}
.cardStyle {
  text-align: center;
  min-height: 100px;
  justify-content: center;
  display: flex;
  align-items: center;

  .listStyle {
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
    .bodyText {
      width: 170px;
    }
    .dateText {
      width: 69px;
    }
  }
}
::v-deep.theme--dark.v-icon:focus::after {
  opacity: 0;
}
</style>
