import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { TransferAPI } from "@/helpers/Apis/StoreTransfers/StoreTransfers";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  storetransfer: {},

  errors: [],
  StoreTransferDetailsErrors: [],
  isStoreTransfersLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getStoreTransfer(state) {
    return state.storetransfer;
  },

  isStoreTransfersLoaded(state) {
    return state.isStoreTransfersLoaded;
  },

  getStoreTransferErrors(state) {
    return state.errors;
  },

  getStoreTransferDetailsErrors(state) {
    return state.StoreTransferDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setStoreTransfer: (state, payload) => {
    state.storetransfer = payload;
  },

  setIsStoreTransfersLoaded: (state, payload) => {
    state.isStoreTransfersLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.storetransfer.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.storetransfer.invoice_entries_extra = [
      ...state.storetransfer.invoice_entries_extra,
      ...payload,
    ];
    let expenses = toFixedIfNecessary(
      state.storetransfer.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.storetransfer.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.storetransfer.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.storetransfer.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.storetransfer.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },

  addStoreInDetails(state, payload) {
    state.storetransfer.invoice_details = [...state.storetransfer.invoice_details, ...payload];
  },
  removeStoreInDetails(state, payload) {
    state.storetransfer.invoice_details = state.storetransfer.invoice_details.filter(
      (invoiceDetail) => invoiceDetail.store_in_id === null
    );
  },
};

const actions = {
  fetchStoreTransfer: ({ commit }, id) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.fetchStoreTransfer(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setStoreTransfer", res.data.data);
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreTransfersLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.previousStoreTransfer(id)
        .then((res) => {
          commit("setStoreTransfer", res.data.data);
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storetransfers/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreTransfersLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.nextStoreTransfer(id)
        .then((res) => {
          commit("setStoreTransfer", res.data.data);
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          router.push({
            path: `/storetransfers/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsStoreTransfersLoaded", true);
          reject(error);
        });
    });
  },

  createStoreTransfer: ({ commit }, data) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.createStoreTransfer(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setStoreTransfer", invoice);
          router.push({
            path: `/storetransfers/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : error.response.data.message;
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateStoreTransfer: ({ commit }, data) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.updateStoreTransfer(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setStoreTransfer", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : error.response.data.message;
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteStoreTransfer: ({ commit }, data) => {
    commit("setIsStoreTransfersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      TransferAPI.deleteStoreTransfer(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreTransfersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : error.response.data.message;
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createCustomEntryBoth: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.storetransfer;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntryBoth(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data.entries);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  storeTransferDeliver: ({ commit }, id) => {
    commit("setIsStoreTransfersLoaded", false);
    return new Promise((resolve, reject) => {
      TransferAPI.storeTransferDeliver(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreTransfersLoaded", true);
          commit("addStoreInDetails", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreTransfersLoaded", true);
          reject(error);
        });
    });
  },
  storeTransferUnDeliver: ({ commit }, id) => {
    commit("setIsStoreTransfersLoaded", false);
    return new Promise((resolve, reject) => {
      TransferAPI.storeTransferUnDeliver(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsStoreTransfersLoaded", true);
          commit("removeStoreInDetails");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreTransfersLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
