<template>
  <DialogComponent
    ref="dialogComponent"
    :persistent="true"
    :contentClass="'dialogSize-60'"
    :isDialog="true"
  >
    <template #header>
      <v-toolbar dark color="primaryBlue">
        <v-toolbar-title> {{ $t("newclient") }} </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          :loading="!isClientsLoaded"
          :disabled="!isClientsLoaded"
          type="submit"
          @click="saveNew"
        >
          {{ $t("save") }}
        </v-btn>
      </v-toolbar>
    </template>
    <template #content>
      <div class="mt-3" v-if="getClientErrors.length > 0">
        <v-input
          v-for="(error, i) in getClientErrors"
          :key="i"
          :error-messages="error"
          error
          class="errorMsgs"
        >
        </v-input>
      </div>

      <form @submit.prevent="saveNew">
        <v-row class="ma-0">
          <v-col cols="12" md="4">
            <GlobalAutoComplete
              v-if="getUser"
              :label="$t('branch')"
              :data="getUser.branches"
              v-model="branch_id"
              @change="
                employee && employee.branch_id != branch_id
                  ? (employee = null)
                  : ''
              "
              :dataLoading="!isUserLoaded"
              :clearable="true"
            />
          </v-col>

          <v-col cols="12" md="4">
            <GlobalAutoComplete
              :label="$t('salesagent')"
              :data="filteredSalesAgents"
              v-model="employee"
              return-object
              @input="$v.employee.$touch()"
              :error-messages="employeeIdErrors"
              :dataLoading="!isUserLoaded"
            />
          </v-col>

          <v-col cols="12" md="4">
            <VSelectBtn
              :label="$t('defaultprice')"
              :data="priceTypes"
              v-model="default_price"
              @input="$v.default_price.$touch()"
              :error-messages="defaultPriceErrors"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="name"
              :label="$t('name')"
              :error-messages="nameErrors"
              @input="$v.name.$touch()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="email"
              :label="$t('email')"
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="tax_registeration"
              :label="$t('tax_registeration')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="commercial_record"
              :label="$t('commercial_record')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField v-model="address" :label="$t('address')" />
          </v-col>

          <v-col cols="12" md="4">
            <BasicTextField
              v-model="phone"
              :label="$t('phone')"
              type="number"
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('classifications')"
              :data="getClassifications"
              v-model="classification_ids"
              multiple
              clearable
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('states')"
              :data="getStates"
              v-model="state"
              return-object
              clearable
              :dataLoading="!isStatesLoaded"
              @change="city = null"
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <GlobalAutoComplete
              :label="$t('cities')"
              :data="getCities"
              v-model="city"
              return-object
              clearable
              :dataLoading="!isStatesLoaded"
            />
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <v-checkbox
              v-model="iscash"
              outlined
              :label="$t('iscash')"
              hide-details="auto"
              dense
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="4" v-if="!fastCreate">
            <v-checkbox
              v-model="hidden"
              outlined
              :label="$t('hidden')"
              hide-details="auto"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-btn
          class="d-none"
          :loading="!isClientsLoaded"
          :disabled="!isClientsLoaded"
          type="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </form>
    </template>
  </DialogComponent>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
export default {
  props: [
    "isClientsLoaded",
    "getClientErrors",
    "getClassifications",
    "fastCreate",
    "getStates",
    "isStatesLoaded",
  ],
  validations() {
    return {
      default_price: { required },
      employee: { required },
      name: { required },
      email: { email },
    };
  },
  components: {},
  data() {
    return {
      default_price: 1,
      branch_id: null,
      employee: null,
      name: null,
      email: null,
      hidden: false,
      iscash: false,
      tax_registeration: null,
      commercial_record: null,
      address: null,
      phone: null,
      classification_ids: null,
      state: null,
      city: null,
    };
  },

  methods: {
    ...mapActions("Clients", ["createClient"]),
    clearErrors() {
      this.$v.$reset();
    },
    openDialog() {
      this.clearErrors();
      this.$refs.dialogComponent?.openDialog().then((res) => {
        if (this.getUser.branches.length == 1) {
          let branch = this.getUser.branches[0];
          this.branch_id = branch.id;
          if (this.filteredSalesAgents.length == 1) {
            this.employee = this.filteredSalesAgents[0];
          }
        } else {
          if (this.salesAgents.length == 1) {
            this.employee = this.salesAgents[0];
          }
        }
      });
    },
    closeDialog() {
      this.$refs.dialogComponent?.closeDialog();
    },

    saveNew() {
      if (this.isClientsLoaded) {
        this.$v.$touch();
        if (!this.$v.$error) {
          this.createClient({
            default_price: this.default_price,
            branch_id: this.branch_id,
            employee_id: this.employee.id,
            name: this.name,
            hidden: this.hidden,
            iscash: this.iscash,
            email: this.email,
            tax_registeration: this.tax_registeration,
            commercial_record: this.commercial_record,
            address: this.address,
            phone: this.phone,
            classification_ids: this.classification_ids,
            state_id: this.state?.id,
            city_id: this.city?.id,
            fastCreate: this.fastCreate,
          }).then((res) => {
            this.$emit("updateClient", res);
          });
        }
      }
    },
  },
  computed: {
    getCities() {
      return this.getStates.flatMap((state) =>
        state.id === this.state?.id ? state.cities : []
      );
    },

    filteredSalesAgents() {
      if (this.branch_id) {
        return this.salesAgents.filter((el) => {
          return el.branch_id == this.branch_id;
        });
      } else {
        return this.salesAgents;
      }
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("required.name"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("required.email"));
      return errors;
    },
    employeeIdErrors() {
      const errors = [];
      if (!this.$v.employee.$dirty) return errors;
      !this.$v.employee.required && errors.push(this.$t("required.field"));
      return errors;
    },
    defaultPriceErrors() {
      const errors = [];
      if (!this.$v.default_price.$dirty) return errors;
      !this.$v.default_price.required && errors.push(this.$t("required.field"));
      return errors;
    },
  },
};
</script>

<style></style>
